import { create } from 'zustand';

type SelectedToken = {
  id: string;
  contractAddress: string;
  kind: number;
  quantity: number;
};

type State = {
  selectedTokens: SelectedToken[];
  isSelecting: boolean;
};

type Actions = {
  setSelecting: (isSelecting: boolean) => void;
  selectToken: (token: SelectedToken) => void;
  removeToken: (token: Pick<SelectedToken, 'id' | 'contractAddress'>) => void;
  reset: () => void;
};

const initialState: State = {
  selectedTokens: [],
  isSelecting: false,
};

export const useTokenSelection = create<State & Actions>(set => ({
  ...initialState,
  setSelecting: isSelecting => {
    set({ isSelecting, selectedTokens: [] });
  },
  selectToken: token => {
    set(state => {
      const index = state.selectedTokens.findIndex(t => t.id === token.id);

      if (index === -1) {
        return { selectedTokens: [...state.selectedTokens, token] };
      }

      const newTokens = [...state.selectedTokens];
      newTokens[index] = token;

      return { selectedTokens: newTokens };
    });
  },
  removeToken: ({ id, contractAddress }) => {
    set(state => ({
      selectedTokens: state.selectedTokens.filter(
        token => token.id !== id || token.contractAddress !== contractAddress,
      ),
    }));
  },
  reset: () => set(initialState),
}));
