import { rem } from 'polished';

import { keyboardFocussed } from '../../tools/keyboard-focussed';

export const ctaSecondary = {
  height: 'auto',
  px: 'space.32',

  backgroundColor: 'gray.2',
  color: 'white',

  fontWeight: 'medium',
  textAlign: 'center',
  lineHeight: rem(44),

  borderColor: 'border',
  borderWidth: '2px',
  borderRadius: 'radius.8',

  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  transition: 'background-color 233ms',

  ...keyboardFocussed({
    boxShadow: 'outline',
  }),

  _hover: {
    backgroundColor: 'gray.4',
  },

  _active: {
    backgroundColor: 'gray.2',
    transitionDuration: '0s',
  },
};
