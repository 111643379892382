import { useMounted } from '@/hooks/use-mounted';
import { chains as reservoirChains } from '@/utils/chains';
import { Link, Text, type BoxProps } from '@chakra-ui/react';
import { MotionBox } from '@sphere/ui';
import { AnimatePresence } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';
import { Fragment } from 'react';
import { useAccount, useSwitchChain } from 'wagmi';

type Props = BoxProps;

export const UnsupportedChainWarning = ({ ...boxProps }: Props) => {
  const { t } = useTranslation('common');
  const { chain, connector } = useAccount();
  const isMounted = useMounted();
  const { chains, switchChain } = useSwitchChain();

  const isChainSupported = reservoirChains
    .find(c => c.id === chain?.id)
    ?.supportedConnectors?.includes(connector?.id ?? '');

  // This component relies on wagmi config to determine if the chain is supported
  const shouldRender = isMounted && chain && !isChainSupported;

  return (
    <AnimatePresence>
      {shouldRender && (
        <MotionBox
          textAlign="center"
          p="space.16"
          background="primary"
          color="darkPurple"
          initial={{ y: 200 }}
          animate={{ y: 0 }}
          exit={{ y: 200 }}
          transition="easeInOut"
          {...boxProps}
        >
          {t('error.unsupported-chain.prefix')}{' '}
          {chains.map((chain, i) => (
            <Fragment key={chain.id}>
              {i === chains.length - 1 &&
                chains.length > 1 &&
                ` ${t('error.unsupported-chain.or')} `}
              {
                // Not every wallet supports switching networks, so we only render a link
                // if the switchChain function is available
                !!switchChain ? (
                  <Link onClick={() => switchChain({ chainId: chain.id })} fontWeight="bold">
                    {chain.name}
                  </Link>
                ) : (
                  <Text fontWeight="bold" display="inline">
                    {chain.name}
                  </Text>
                )
              }
              {i < chains.length - 2 && ', '}
            </Fragment>
          ))}
          {'.'}
        </MotionBox>
      )}
    </AnimatePresence>
  );
};
