import { Flex, FlexProps, ImageProps } from '@chakra-ui/react';
import { ImageUploadSingleIcon } from '@sphere/icons';
import { rem } from 'polished';
import { nextImageProps } from './utils';

// TODO: revert this to the previous version when the image loading issue has been solved
export const ImageFallback = (props: Partial<ImageProps>) => {
  const filteredProps: Partial<FlexProps> = Object.fromEntries(
    Object.entries(props).filter(([key]) => !(nextImageProps as string[]).includes(key)),
  );

  return (
    <Flex alignItems="center" justify="center" {...filteredProps} bg="gray.4">
      <ImageUploadSingleIcon w={rem(18)} opacity={0.6} />
    </Flex>
  );
};
