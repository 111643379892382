import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';

/**
 * Framer-motion + chakra box
 * Ready to go Box-based component that accepts both the chakra BoxProps as well as the motion props from framer
 */
export const MotionBox = motion(Box, {
  forwardMotionProps: false,
});
