import { chakra } from '@chakra-ui/react';

const SVGIcon = ({ ...props }) => (
  <svg viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_18677_284817)">
      <path
        d="M400 800C620.914 800 800 620.914 800 400C800 179.086 620.914 0 400 0C179.086 0 0 179.086 0 400C0 620.914 179.086 800 400 800Z"
        fill="#0052FF"
      />
      <path
        d="M403.359 282.68C450.453 282.68 487.812 311.727 502 354.922H596.875C579.688 262.57 503.492 200 404.117 200C291.266 200 203.125 285.664 203.125 400.383C203.125 515.102 289.063 600 404.117 600C501.266 600 578.977 537.43 596.18 444.312H501.992C488.539 487.516 451.211 517.32 404.086 517.32C339.062 517.32 293.492 467.406 293.492 400.383C293.523 332.594 338.359 282.68 403.359 282.68Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_18677_284817">
        <rect width="800" height="800" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Coinbase = chakra(SVGIcon);
export default Coinbase;
