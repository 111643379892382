import { forwardRef, Icon, IconProps } from '@chakra-ui/react';
export default forwardRef<IconProps, 'svg'>((props, svgRef) => (
  <Icon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" ref={svgRef} {...props}>
    <g clipPath="url(#trash_svg__trash_svg__a)">
      <path
        fill="currentColor"
        d="M5 15.833c0 .917.75 1.667 1.667 1.667h6.666c.917 0 1.667-.75 1.667-1.667v-10H5v10zm10.833-12.5h-2.916l-.834-.833H7.917l-.834.833H4.167V5h11.666V3.333z"
      />
    </g>
    <defs>
      <clipPath id="trash_svg__trash_svg__a">
        <path fill="currentColor" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </Icon>
));
