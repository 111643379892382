import { ChakraTheme } from '@chakra-ui/react';

export const breakpoints: ChakraTheme['breakpoints'] = {
  /**
  Extends the default Chakra UI breakpoints:
    sm: '30em', // 480px
    md: '48em', // 768px
    lg: '62em', // 992px
    xl: '80em', // 1280px
  */
  '2xl': '90em', // 1440px
  '3xl': '112em', // 1792px
};
