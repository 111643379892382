import { Sticky } from '@/config/sticky-layout';
import { memo } from 'react';
import { Header } from '../Header';
import { useHeaderSettings } from '../useHeaderSettings';

export const StickyNavigation = memo(() => {
  const hasBackground = useHeaderSettings(state => state.hasBackground);

  return (
    <Sticky
      element="header"
      zIndex="sticky-layout"
      offset={50}
      {...(hasBackground && {
        stuck: {
          animate: {
            boxShadow: '0 1px 0 rgba(0, 0, 0, 0.07)',
          },
        },
      })}
    >
      <Header hasBackground={hasBackground} />
    </Sticky>
  );
});
