import { create } from 'zustand';

export enum MenuScreen {
  Main = 'menu',
  User = 'user',
  Notifications = 'notifications',
}

export enum SubmenuScreen {
  Resources = 'resources',
  Notifications = 'notifications',
}

type Store = {
  menu?: MenuScreen;
  submenu?: SubmenuScreen;
  setMenu: (menu?: MenuScreen) => void;
  setSubmenu: (submenu?: SubmenuScreen) => void;
};

export const useMenu = create<Store>(set => ({
  menu: undefined,
  submenu: undefined,
  setMenu: menu =>
    set(state => ({ menu: menu === state.menu ? undefined : menu, submenu: undefined })),
  setSubmenu: submenu => set({ submenu }),
}));
