import { forwardRef, Icon, IconProps } from '@chakra-ui/react';
export default forwardRef<IconProps, 'svg'>((props, svgRef) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    ref={svgRef}
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.75 0h-7.5A6.25 6.25 0 000 6.25v7.5A6.25 6.25 0 006.25 20h7.5A6.25 6.25 0 0020 13.75v-7.5A6.25 6.25 0 0013.75 0zm4.375 13.75a4.38 4.38 0 01-4.375 4.375h-7.5a4.38 4.38 0 01-4.375-4.375v-7.5A4.38 4.38 0 016.25 1.875h7.5a4.38 4.38 0 014.375 4.375v7.5z"
    />
    <path
      fill="currentColor"
      d="M10 5a5 5 0 100 10 5 5 0 000-10zm0 8.125A3.13 3.13 0 016.875 10 3.129 3.129 0 0110 6.875 3.129 3.129 0 0113.125 10 3.13 3.13 0 0110 13.125zm5.375-7.834a.666.666 0 100-1.332.666.666 0 000 1.332z"
    />
  </Icon>
));
