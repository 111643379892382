import { routes } from '@/utils/routes';
import useTranslation from 'next-translate/useTranslation';

const LINKS = [
  { text: 'footer.email', href: 'footer.email' },
  {
    text: 'footer.privacy-policy',
    href: routes.article.privacyPolicy,
  },
  { text: 'footer.terms-and-conditions', href: routes.article.termsAndConditions },
];

export function useFooterBottomLinksTranslation() {
  const { t } = useTranslation('common');

  const translatedBottomFooterLinks = LINKS.map(({ text, href }) => ({
    text: t(text),
    href: href.includes('email') ? `mailto:${t(href)}` : href,
  }));

  return { translatedBottomFooterLinks };
}
