import { env } from '@/env.mjs';
import { getSupportedChainById } from '@/utils/getChain';
import { Button, Flex, FlexProps, Heading, Input, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { toast } from 'sonner';
import { useDevCompanion } from '../DevCompanion.store';

type Props = FlexProps;

const RevalidateCollection = ({ ...props }: Props) => {
  const router = useRouter();
  const chainId = useDevCompanion(state => state.chainId);

  const [contractAddress, setContractAddress] = useState<string>(
    typeof router.query.contract === 'string' ? router.query.contract : '',
  );

  const [isLoading, setLoading] = useState<boolean>(false);

  const handleSubmit = useCallback(async () => {
    const chain = getSupportedChainById(chainId);
    if (!chain || !contractAddress) return;
    setLoading(true);

    try {
      await fetch(`${env.NEXT_PUBLIC_HOST_URL}/api/revalidateCollection`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ chain, contractAddress }),
      });

      toast.success('Collection revalidated');
    } catch (e) {
      toast.error('Error revalidating collection');
    } finally {
      setLoading(false);
    }
  }, [chainId, contractAddress]);

  return (
    <Flex direction="column" {...props}>
      <Heading fontSize="md" fontWeight="bold">
        {'Revalidate Collection'}
      </Heading>
      <Text fontSize="sm" color="muted" mt="space.4">
        {
          'Revalidate a collection by its contract address. This will prune the cache, forcing a re-render.'
        }
      </Text>
      <Flex gap="space.8" my="space.8">
        <Input
          value={contractAddress}
          onChange={e => setContractAddress(e.target.value)}
          onFocus={() => setContractAddress('')}
          placeholder={'Set contract address'}
          size="sm"
        />

        <Button
          onClick={handleSubmit}
          isLoading={isLoading}
          isDisabled={isLoading}
          variant="clear"
          fontWeight="bold"
          size="sm"
          w="fit-content"
        >
          {'Revalidate'}
        </Button>
      </Flex>
    </Flex>
  );
};

export default RevalidateCollection;
