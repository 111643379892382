import { OrganizationJsonLd } from 'next-seo';

export const SeoOrganization = () => {
  return (
    <OrganizationJsonLd
      type="Organization"
      name="Merit Circle DAO"
      logo={`${process.env.NEXT_PUBLIC_HOST_URL}/images/merit-circle-logo.png`}
      contactPoint={[
        {
          contactType: 'technical support',
          email: 'build@onbeam.com',
          availableLanguage: ['English'],
        },
      ]}
      sameAs={[
        'https://twitter.com/MeritCircle_IO',
        'https://twitter.com/BuildOnBeam',
        'https://medium.com/@meritcircle',
      ]}
      url="https://meritcircle.io"
    />
  );
};
