import { forwardRef, Icon, IconProps } from '@chakra-ui/react';
export default forwardRef<IconProps, 'svg'>((props, svgRef) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    ref={svgRef}
    {...props}
  >
    <path
      fill="currentColor"
      d="M17.77 7.74c-.63 0-1.187.266-1.596.68-1.504-1.057-3.532-1.737-5.78-1.81l1.168-5.337 3.717.846c0 .924.742 1.678 1.652 1.678.927 0 1.673-.774 1.673-1.699 0-.924-.74-1.698-1.672-1.698-.65 0-1.21.398-1.489.94L11.338.419c-.207-.056-.41.094-.465.304L9.592 6.607c-2.23.094-4.237.773-5.746 1.83a2.227 2.227 0 00-1.619-.697c-2.343 0-3.11 3.19-.965 4.282-.075.337-.11.697-.11 1.056 0 3.585 3.98 6.489 8.866 6.489 4.906 0 8.885-2.904 8.885-6.489a4.13 4.13 0 00-.13-1.074c2.103-1.095 1.329-4.262-1.002-4.264zM4.668 12.248c0-.94.742-1.698 1.674-1.698.91 0 1.652.752 1.652 1.698 0 .925-.741 1.678-1.652 1.678a1.672 1.672 0 01-1.674-1.678zm9.033 3.999c-1.534 1.556-5.863 1.556-7.398 0-.17-.15-.17-.415 0-.586a.4.4 0 01.556 0c1.172 1.219 5.059 1.24 6.281 0a.4.4 0 01.557 0c.172.171.172.437.004.586zm-.034-2.318c-.91 0-1.652-.752-1.652-1.676 0-.94.742-1.698 1.652-1.698.927 0 1.673.752 1.673 1.698a1.68 1.68 0 01-1.673 1.676z"
    />
  </Icon>
));
