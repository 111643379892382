module.exports = {
  locales: ['en'],
  defaultLocale: 'en',
  pages: {
    '*': ['common', 'error', 'companion', 'search', 'articles'],
    '/': ['home', 'insights'],
    'rgx:^/search': ['home'],
    'rgx:^/account': ['account', 'sales', 'nft', 'activity'],
    'rgx:^/account/collections': ['editor', 'collection-editor'],
    'rgx:^/account/games': ['editor', 'collection-editor', 'game-editor', 'collection-overview'],
    'rgx:^/\\[chain\\]': ['home', 'collection-overview', 'collection', 'insights'],
    'rgx:^/\\[chain\\]/nft': ['nft', 'activity'],
    'rgx:^/\\[chain\\]/collection': ['activity'],
    'rgx:^/\\[chain\\]/insights': ['insights'],
    'rgx:^/\\[chain\\]/game': ['game'],
    'rgx:^/support': ['support'],
  },
};
