import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { SiteLinksSearchBoxJsonLd } from 'next-seo';

export const SeoSearchBox = () => {
  const chain = useMarketplaceChain();

  if (!process.env.NEXT_PUBLIC_HOST_URL) return null;

  return (
    <SiteLinksSearchBoxJsonLd
      url={process.env.NEXT_PUBLIC_HOST_URL}
      potentialActions={[
        {
          target: `${process.env.NEXT_PUBLIC_HOST_URL}/search?chain=${chain.routePrefix}&q`,
          queryInput: 'search_term_string',
        },
      ]}
    />
  );
};
