import { rem } from 'polished';

import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys,
);

const round = definePartsStyle({
  field: {
    fontSize: 'md',
    color: 'white',
    borderWidth: '1px',
    borderColor: 'border',
    borderRadius: 'full',
    paddingLeft: { base: 'space.20', md: 'space.32' },
    paddingRight: 'space.10',
    height: rem(52),
    background: 'gray.2',

    _placeholder: {
      color: 'gray.5',
      textOverflow: 'ellipsis',
      fontFamily: 'placeholder',
    },

    _placeholderShown: {
      ':focus': {
        borderColor: 'gray.5',
      },
    },

    _hover: {
      borderColor: 'gray.4',
    },

    _focus: {
      borderColor: 'currentColor',
    },

    _invalid: {
      boxShadow: 'none', // Rendering box shadow results in a thick 2px border, which is not conform design spec

      '&, &:focus': {
        borderColor: 'red',
      },
    },
  },
});

/**
 * Size overrides for default inputs, add more as needed
 */
const size = {
  md: {
    h: rem(56),
    px: rem(24),
    borderRadius: 'lg',
  },
};

const sizes = {
  md: definePartsStyle({
    field: size.md,
    addon: size.md,
  }),
};

export const input = defineMultiStyleConfig({
  sizes,
  variants: {
    outline: definePartsStyle({
      field: {
        borderColor: 'gray.4',
        _hover: {
          borderColor: 'gray.5',
        },
        _focus: {
          borderColor: 'gray.5',
        },
        _invalid: {
          borderColor: 'red',
          // Rendering box shadow results in a thick 2px border, which is not conform design spec
          boxShadow: 'none',
        },
        _placeholder: {
          color: 'gray.5',
        },
      },
    }),

    round,

    'round-translucent': definePartsStyle({
      ...round,
      field: {
        ...round.field,
        textColor: 'inherit',
        borderWidth: '1px',
        borderColor: 'transparent',
        background: 'rgba(255, 255, 255, 0.1)',
        backdropFilter: 'blur(12px)',

        _placeholder: {
          ...round.field._placeholder,
          color: 'gray.5',
          opacity: 1,
        },
      },
    }),

    // Variant used in search facets. Smaller, less rounded and with background
    facet: definePartsStyle({
      field: {
        bg: 'gray.2',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'border',
        borderRadius: rem(4),
        px: 'space.12',
        fontSize: 'sm',
        height: rem(44),

        _placeholder: {
          color: 'gray.5',
        },

        _hover: {
          borderColor: 'gray.5',
        },
        _focus: {
          borderColor: 'gray.5',
        },
        _disabled: {
          opacity: 0.5,
          _hover: {
            borderColor: 'gray.4',
          },
        },
      },
    }),
  },
});

// Used by ./textarea.ts to match the default input sizes
export { size };
