import { forwardRef, IconButton, IconButtonProps } from '@chakra-ui/react';
import { rem } from 'polished';
import { ReactElement } from 'react';

export const getIndicatorStyles = (count: number, color = 'darkPurple') => ({
  minWidth: rem(14),
  display: 'inline-block',
  background: 'primary',
  fontSize: 'xs',
  lineHeight: 1,
  fontWeight: 'medium',
  borderWidth: '1px',
  borderColor: 'currentColor',
  rounded: 'full',
  py: 'space.4',
  px: 'space.4',
  content: `"${count}"`,
  position: 'absolute',
  right: 'space.-4',
  top: 'space.-2',
  color: color,
  transition: 'transform 266ms',
  transformOrigin: 'bottom left',
  textAlign: 'center',
});

type Props = IconButtonProps & {
  icon: ReactElement;
  count?: number;
  indicatorColor?: string;
};

export const HeaderActionButton = forwardRef<Props, 'button'>(
  ({ count, icon, indicatorColor, ...rest }, ref) => (
    <IconButton
      ref={ref}
      color="inherit"
      variant="icon"
      icon={icon}
      rounded="full"
      boxSize={rem(40)}
      _hover={{ bg: 'gray.4' }}
      _after={count ? getIndicatorStyles(count, indicatorColor) : undefined}
      {...rest}
    />
  ),
);
