import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { useAuthenticationModal } from '@/hooks/useAuthenticationModal';
import { useDebouncedValue } from '@/hooks/useDebouncedValue';
import { wagmiConfig } from '@/lib/wagmi';
import { routes } from '@/utils/routes';
import { sendGTMEvent } from '@onbeam/utils';
import { watchAccount } from '@wagmi/core';
import { isEqual } from 'date-fns';
import { type User } from 'next-auth';
import { getCsrfToken, getSession, signIn, signOut, useSession } from 'next-auth/react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  type PropsWithChildren,
} from 'react';
import { SiweMessage } from 'siwe';
import { toast } from 'sonner';
import { Hex } from 'viem';
import {
  UseConnectReturnType,
  useAccount,
  useConnect,
  useDisconnect,
  useSignMessage,
  type Connector,
} from 'wagmi';

const protectedRoutes = ['/account/[address]', '/account/collections'];

type AuthenticationContextState = Pick<UseConnectReturnType, 'connect' | 'connectors'> & {
  user?: User;
  userAddress?: string;

  /** The connector being connected to */
  pendingConnector?: Connector;

  /** The currently active connector */
  activeConnector?: Connector;

  status:
    | 'idle'
    | 'connecting'
    | 'connected'
    | 'verifying'
    | 'authenticated'
    | 'disconnecting'
    | 'error';

  error?: string;

  isAuthenticating: boolean;
  isAuthenticated: boolean;

  authenticate: () => Promise<void>;
  verify: (address: Hex, provider: string) => Promise<void>;
  disconnect: () => Promise<void>;
};

export const AuthenticationContext = createContext<AuthenticationContextState>({
  status: 'idle' as const,
  connectors: [],
  isAuthenticating: false,
  isAuthenticated: false,

  authenticate: () => {
    throw new Error('wrap application in AuthenticationProvider');
  },
  connect: () => {
    throw new Error('wrap application in AuthenticationProvider');
  },
  disconnect: () => {
    throw new Error('wrap application in AuthenticationProvider');
  },
  verify: () => {
    throw new Error('wrap application in AuthenticationProvider');
  },
});

export const AuthenticationProvider = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation('common');
  const { push, pathname, reload } = useRouter();
  const marketplaceChain = useMarketplaceChain();
  const { status: sessionStatus, data: session } = useSession();
  const [error, setError] = useState<string | undefined>();
  const [pendingConnector, setPendingConnector] = useState<Connector | undefined>();
  const {
    chain,
    address,
    connector: activeConnector,
    isConnecting,
    isConnected,
    isDisconnected,
    isReconnecting,
  } = useAccount();
  const { signMessageAsync } = useSignMessage();
  const openAuthenticationModal = useAuthenticationModal(state => state.setOpen);
  const [isVerifying, setIsVerifying] = useState(false);

  const { connect, connectors } = useConnect({
    mutation: {
      onMutate(data) {
        setPendingConnector(
          data.connector.hasOwnProperty('id') ? (data.connector as Connector) : undefined,
        );
        setError(undefined);
      },
      onError() {
        setError(t('authentication.connect-error'));
      },
      onSettled() {
        setPendingConnector(undefined);
        setIsVerifying(false);
      },
    },
  });

  const { disconnectAsync, isPending: isDisconnecting } = useDisconnect({
    mutation: {
      onSuccess() {
        const isCurrentRouteProtected = protectedRoutes.some(protectedRoute =>
          pathname.startsWith(protectedRoute),
        );

        if (!isCurrentRouteProtected) return;

        void push(routes.home(marketplaceChain.routePrefix));
      },
    },
  });

  /**
   * The current status of the authentication context.
   */
  const status = useMemo<AuthenticationContextState['status']>(() => {
    if (error) return 'error';

    if (isConnected) {
      if (sessionStatus === 'loading' || isVerifying) return 'verifying';
      if (sessionStatus === 'authenticated') return 'authenticated';
      return 'connected';
    }

    if (isConnecting || isReconnecting) return 'connecting';
    if (isDisconnecting) return 'disconnecting';

    return 'idle';
  }, [
    error,
    isConnected,
    isConnecting,
    isReconnecting,
    isVerifying,
    isDisconnecting,
    sessionStatus,
  ]);

  /**
   * Verifies the connected account against the server.
   * @param address The address of the connected account
   * @param provider The connected provider id (e.g. 'io.metamask')
   */
  const verify = useCallback(
    async (address: Hex, provider: string) => {
      setIsVerifying(true);
      setError(undefined);

      try {
        const message = new SiweMessage({
          domain: window.location.host,
          address,
          statement: t('authentication.wallet-sign-in'),
          uri: window.location.origin,
          version: '1',
          chainId: chain?.id,
          nonce: await getCsrfToken(),
        });

        const signature = await signMessageAsync({ message: message.prepareMessage() });

        const response = await signIn('credentials', {
          message: JSON.stringify(message),
          signature,
          provider,
          redirect: false,
        });

        if (response?.ok) {
          const session = await getSession();

          setIsVerifying(false);
          openAuthenticationModal(false);

          if (
            session?.user &&
            isEqual(new Date(session.user.createdAt), new Date(session.user.updatedAt))
          ) {
            sendGTMEvent({ event: 'signup' });
          }

          return;
        }

        if (response?.error) throw new Error(response.error);
      } catch (error: unknown) {
        setError(t('authentication.verify-error'));
      }
    },
    [chain, signMessageAsync, openAuthenticationModal, t],
  );

  /**
   * Disconnect the users wallet and clear their session.
   */
  const disconnect = useCallback(async () => {
    Promise.all([
      disconnectAsync(),
      signOut({
        redirect: false,
      }),
    ])
      .catch(() => setError(t('authentication.disconnect-error')))
      .finally(() => {
        setIsVerifying(false);
        setError(undefined);
      });
  }, [disconnectAsync, t]);

  /**
   * Instantiate the authentication flow by opening the authentication modal.
   */
  const authenticate = useCallback(async () => {
    setError(undefined);
    openAuthenticationModal(true);
  }, [openAuthenticationModal]);

  /**
   * Show error toast when an error occurs during the connection process
   */
  useEffect(() => {
    if (error) toast.error(error);
  }, [error]);

  /**
   * When the current window is not in focus we reload the page instead since this could resolve
   * the connection state if the user signed in within a different tab.
   */
  useEffect(() => {
    if (
      document.visibilityState !== 'visible' && // When the current window is not in focus
      isDisconnected && // And the user their wallet is disconnected
      sessionStatus === 'authenticated' && // But they still have an active session
      !isConnecting && // And are not currently connecting
      !isReconnecting // And are not currently reconnecting
    ) {
      reload(); // We reload the page to try and resolve the wallet connection state
    }
  }, [isConnecting, isDisconnected, isReconnecting, reload, sessionStatus]);

  /**
   * Verifies the user on the server when the account changes (e.g. when the user switches wallets)
   */
  useEffect(() => {
    const unwatch = watchAccount(wagmiConfig, {
      async onChange(data) {
        if (address && data.address && address !== data.address) {
          await verify(data.address, data.connector?.id || '');
        }
      },
    });

    return () => unwatch();
  }, [address, verify]);

  // Uses debounced value to prevent the authentication modal from flashing when the user is connected but not yet verified
  const [isAuthenticating] = useDebouncedValue(['connecting', 'verifying'].includes(status), 100);
  const isAuthenticated = status === 'authenticated';

  return (
    <AuthenticationContext.Provider
      value={{
        status,
        error,
        user: session?.user,
        userAddress: session?.user.address,
        connect,
        connectors,
        pendingConnector,
        activeConnector,
        isAuthenticating,
        isAuthenticated,
        authenticate,
        disconnect,
        verify,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
