import { api } from '@/utils/api';
import { getSupportedChainById } from '@/utils/getChain';
import { Button, Checkbox, Flex, FlexProps, Heading, Input, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { toast } from 'sonner';
import { useDevCompanion } from '../DevCompanion.store';

type Props = FlexProps;

const SetCollectionSpamStatus = ({ ...props }: Props) => {
  const router = useRouter();
  const [contractAddress, setContractAddress] = useState<string>(
    typeof router.query.contract === 'string' ? router.query.contract : '',
  );
  const [spam, setSpam] = useState<boolean>(true);
  const [isLoading, setLoading] = useState<boolean>(false);
  const chainId = useDevCompanion(state => state.chainId);

  const setCollectionSpamStatus = api.admin.setCollectionSpamStatus.useMutation();

  const handleSubmit = useCallback(async () => {
    const chain = getSupportedChainById(chainId);

    if (!chain || !contractAddress) return;
    setLoading(true);

    try {
      await setCollectionSpamStatus.mutateAsync({
        chainId,
        address: contractAddress,
        isSpam: spam,
      });
      toast.success('Collection spam status updated');
    } catch (error) {
      console.error(error);
      toast.error('Failed to update collection spam status');
    } finally {
      setContractAddress('');
      setLoading(false);
    }
  }, [chainId, contractAddress, setCollectionSpamStatus, spam]);

  return (
    <Flex direction="column" {...props}>
      <Heading fontSize="md" fontWeight="bold">
        {'Set Collection Spam Status'}
      </Heading>
      <Text fontSize="sm" color="muted" mt="space.4">
        {'Update the spam status of a collection, excluding it from search results and listings'}
      </Text>

      <Flex gap="space.8" my="space.8">
        <Input
          value={contractAddress}
          onChange={e => setContractAddress(e.target.value)}
          onFocus={() => setContractAddress('')}
          placeholder={'Collection Address'}
          size="sm"
        />
        <Flex shrink={0}>
          <Checkbox
            isChecked={spam}
            onChange={e => setSpam(e.target.checked)}
            size="sm"
            fontWeight="bold"
          >
            {'Mark as Spam'}
          </Checkbox>
        </Flex>

        <Button
          onClick={handleSubmit}
          variant="clear"
          fontWeight="bold"
          size="sm"
          isDisabled={isLoading}
        >
          {'Set'}
        </Button>
      </Flex>
    </Flex>
  );
};

export default SetCollectionSpamStatus;
