import { CSS } from '@stitches/react';
import { FC } from 'react';

type Props = {
  chainId?: number;
  height?: number;
  css?: CSS;
};

const ChainIcon: FC<Props> = ({ chainId, css = {}, height = 14 }) => null;

export default ChainIcon;
