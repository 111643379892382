import { ChakraComponent, IconProps } from '@chakra-ui/react';
import { CheckIcon, CloseCircleIcon, InfoOutlineIcon, WarningIcon } from '@sphere/icons';
import { rem } from 'polished';

export enum Status {
  Success = 'Success',
  Info = 'Info',
  Warning = 'Warning',
  Error = 'Error',
}

export const statusIconMap: {
  [key in Status]: ChakraComponent<'svg', IconProps>;
} = {
  [Status.Success]: CheckIcon,
  [Status.Info]: InfoOutlineIcon,
  [Status.Warning]: WarningIcon,
  [Status.Error]: CloseCircleIcon,
};

export const statusColorMap: {
  [key in Status]: { color: string; bg: string };
} = {
  [Status.Success]: { color: 'green', bg: 'darkGreen' },
  [Status.Info]: { color: 'blue', bg: 'darkBlue' },
  [Status.Warning]: { color: 'orange', bg: 'darkOrange' },
  [Status.Error]: { color: 'red', bg: 'darkRed' },
};

export const StatusIcon = ({ status }: { status: Status }) => {
  const Icon = statusIconMap[status];
  return <Icon boxSize={{ base: rem(16), md: rem(20) }} color={statusColorMap[status].color} />;
};
