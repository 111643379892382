import { rem } from 'polished';
import { primary } from './primary';

/**
 * Variant on 'primary', but smaller and wider
 */
export const facet = {
  ...primary,
  fontSize: 'sm',
  height: rem(40),
  px: 'space.32',
};
