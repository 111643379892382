import { ComponentStyleConfig } from '@chakra-ui/react';

const sizes = {
  lg: {
    px: 'space.8',
    py: 'space.4',
  },
  md: {
    px: 'space.8',
    py: 'space.2',
  },
};

const variants = {
  dark: {
    color: 'white',
    textDecoration: 'none',
    textTransform: 'none',
    rounded: 'md',
    bg: 'black',
  },
  green: {
    color: 'green',
    bg: 'darkGreen',
    textTransform: 'none',
    fontWeight: 'medium',
    rounded: 'radius.4',
  },
  red: {
    color: 'red',
    bg: 'darkRed',
    textTransform: 'none',
    fontWeight: 'medium',
    rounded: 'radius.4',
  },
  orange: {
    color: 'orange',
    bg: 'lightOrange',
    textTransform: 'none',
    fontWeight: 'medium',
    rounded: 'radius.4',
  },
};

export const badge: ComponentStyleConfig = {
  defaultProps: {
    size: 'md',
  },
  sizes,
  variants,
};
