import { forwardRef, Icon, IconProps } from '@chakra-ui/react';
export default forwardRef<IconProps, 'svg'>((props, svgRef) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    viewBox="0 0 32 32"
    ref={svgRef}
    {...props}
  >
    <g fill="currentColor" clipPath="url(#flat-avatar_svg__flat-avatar_svg__clip0_5_2)">
      <path d="M16 21.167c-6.776 0-12.34 4.32-12.946 9.834-.06.55.394.999.946.999h24c.552 0 1.006-.45.945-.998-.604-5.515-6.17-9.835-12.945-9.835z" />
      <circle cx={16} cy={12.5} r={6.5} />
    </g>
    <defs>
      <clipPath id="flat-avatar_svg__flat-avatar_svg__clip0_5_2">
        <path fill="currentColor" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </Icon>
));
