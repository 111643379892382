import { cssVar, defineStyleConfig } from '@chakra-ui/react';

/**
 * The Chakra docs don't explain this, but the following cssVar util selector will help target the Tooltip arrow in the variant styling
 * https://github.com/chakra-ui/chakra-ui/issues/4695#issuecomment-991023319
 */
const arrowBgSelector = cssVar('popper-arrow-bg');

const baseStyle = {
  rounded: 'radius.8',
  fontWeight: 'normal',
  px: 'space.14',
  py: 'space.12',
  fontSize: 'xs',
};

const variants = {
  light: {
    bg: 'white',
    color: 'black',
    [arrowBgSelector.variable]: 'white',
    boxShadow: 'tooltip',
  },
  dark: {
    bg: 'gray.2',
    color: 'white',
    [arrowBgSelector.variable]: 'colors.gray.2',
  },
};

const defaultProps = {
  variant: 'dark' as keyof typeof variants,
};

export const tooltip = defineStyleConfig({ baseStyle, variants, defaultProps });
