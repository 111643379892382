import { type ChakraComponent, type IconProps } from '@chakra-ui/react';
import {
  BeamIcon,
  CoinbaseIcon,
  ImmutableIcon,
  MetaMaskIcon,
  RabbyIcon,
  WalletConnectIcon,
} from '@sphere/icons';

const iconMap: Record<string, ChakraComponent<'svg', IconProps>> = {
  beam: BeamIcon,
  metaMask: MetaMaskIcon,
  coinbaseWalletSDK: CoinbaseIcon,
  walletConnect: WalletConnectIcon,
  immutable: ImmutableIcon,
  injected: RabbyIcon,
};

export const WalletIcon = ({ connectorId, ...iconProps }: { connectorId: string } & IconProps) => {
  const Icon = iconMap[connectorId];
  return Icon ? <Icon {...iconProps} /> : null;
};
