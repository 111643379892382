import { type SearchBarProps } from '@/components/features/Search/SearchBar';
import { MotionBox } from '@sphere/ui';
import { type MotionProps } from 'framer-motion';
import { type PropsWithChildren } from 'react';

export const SearchBackDrop = ({
  children,
  ...motionProps
}: PropsWithChildren<Pick<SearchBarProps, 'variant'> & MotionProps>) => (
  <MotionBox
    position="fixed"
    inset={0}
    background="rgba(0, 0, 0, 0.5)"
    backdropFilter="blur(7px)"
    zIndex="autocomplete"
    cursor="pointer"
    {...motionProps}
  >
    {children}
  </MotionBox>
);
