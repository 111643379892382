import { ButtonProps, forwardRef } from '@chakra-ui/react';
import { ChevronRightIcon } from '@sphere/icons';
import { NavLink, NavLinkItem } from '@sphere/ui';
import { rem } from 'polished';
import { ComponentPropsWithoutRef, HTMLAttributeAnchorTarget, ReactNode } from 'react';

type HrefProps = Pick<ComponentPropsWithoutRef<typeof NavLink>, 'href'>;

type EventHandlerProps =
  | {
      href?: undefined;
      onClick?: () => void;
    }
  | {
      href: undefined;
      onMouseOver?: () => void;
    };

type Props = Omit<ButtonProps, 'href' | 'onClick'> &
  (HrefProps | EventHandlerProps) & {
    arrow?: boolean;
    children?: ReactNode | null;
    activeLinkColor?: string;
    target?: HTMLAttributeAnchorTarget;
    useActiveClassName?: boolean;
    hoverLinkColor?: string;
    underlineOffset?: string;
  };

type MenuLinkContentProps = {
  children?: ReactNode | null;
  arrow?: boolean;
};

const MenuLinkContent = ({ children, arrow }: MenuLinkContentProps) => (
  <>
    {children}
    {arrow && (
      <ChevronRightIcon
        position="absolute"
        top="50%"
        right="0"
        boxSize={rem(10)}
        display={{ base: 'inline-block', md: 'none' }}
        transform="translate(-50%, -50%)"
      />
    )}
  </>
);

export const MenuLink = forwardRef<Props, 'button'>(
  ({ href, arrow, children, useActiveClassName = true, ...props }: Partial<Props>, ref) =>
    href ? (
      <NavLink
        href={href}
        passHref
        exact
        fontWeight="500"
        useActiveClassName={useActiveClassName}
        hoverLinkColor={props.hoverLinkColor || props.activeLinkColor}
        {...props}
      >
        <MenuLinkContent arrow={arrow}>{children}</MenuLinkContent>
      </NavLink>
    ) : (
      <NavLinkItem
        ref={ref}
        fontWeight="500"
        hoverLinkColor={props.hoverLinkColor || props.activeLinkColor}
        {...props}
      >
        <MenuLinkContent arrow={arrow}>{children}</MenuLinkContent>
      </NavLinkItem>
    ),
);
