import { Image } from '@/components/shared/Image';
import { useAuthentication } from '@/hooks/useAuthentication';
import { Box } from '@chakra-ui/react';
import { FlatAvatarIcon } from '@sphere/icons';
import useTranslation from 'next-translate/useTranslation';
import { rem } from 'polished';

const defaultProfileImage = '/images/default-profile-image.png';
const defaultConnectingImage = '/images/default-connecting-logo.svg';

export const ProfileIcon = () => {
  const { isAuthenticating, user, isAuthenticated } = useAuthentication();
  const { t } = useTranslation('common');

  if (isAuthenticated || isAuthenticating) {
    return (
      <Image
        src={isAuthenticating ? defaultConnectingImage : user?.image || defaultProfileImage}
        fallback={defaultProfileImage}
        fallbackStrategy="onError"
        alt={t('header.your-avatar')}
        rounded="full"
        aria-hidden={!user?.address}
        objectFit="cover"
        width={32}
        height={32}
        boxSize={rem(32)}
      />
    );
  }

  return (
    <Box bg={{ base: 'black', lg: 'blackBg' }} color="white" rounded="full" overflow="hidden">
      <FlatAvatarIcon boxSize={rem(32)} />
    </Box>
  );
};
