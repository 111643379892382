import { forwardRef, Icon, IconProps } from '@chakra-ui/react';
export default forwardRef<IconProps, 'svg'>((props, svgRef) => (
  <Icon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 12" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.907.365c.48 0 .87.39.87.87l-.001 3.99 3.992.001a.87.87 0 010 1.74H6.776v3.991a.87.87 0 01-1.74 0V6.965h-3.99a.87.87 0 010-1.739h3.99V1.235c0-.48.39-.87.87-.87z"
      clipRule="evenodd"
    />
  </Icon>
));
