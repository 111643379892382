import { SocialMediaType } from '@/config/social-media';
import { IconButton, ListItem, Text, type ListItemProps } from '@chakra-ui/react';

import {
  DiscordIcon,
  InstagramIcon,
  MediumIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  YoutubeIcon,
} from '@sphere/icons/components';

const socialMediaIconMap = {
  [SocialMediaType.Twitter]: TwitterIcon,
  [SocialMediaType.Medium]: MediumIcon,
  [SocialMediaType.Instagram]: InstagramIcon,
  [SocialMediaType.Discord]: DiscordIcon,
  [SocialMediaType.Youtube]: YoutubeIcon,
  [SocialMediaType.Reddit]: RedditIcon,
  [SocialMediaType.Telegram]: TelegramIcon,
};

const getIcon = (type: Props['type']) => {
  if (type === SocialMediaType.Email) {
    return (
      <Text fontSize="md" lineHeight={1} fontWeight="bold">
        {'@'}
      </Text>
    );
  }

  const Icon = socialMediaIconMap[type];

  if (Icon) return <Icon />;

  return (
    <Text fontSize="md" lineHeight={1}>
      {'🔮'}
    </Text>
  );
};

type Props = ListItemProps & {
  type: SocialMediaType;
  name: string;
  url: string;
};

export const SocialMediaLinkItem = ({ type, name, url, ...listItemProps }: Props) => (
  <ListItem {...listItemProps}>
    <IconButton
      as="a"
      variant="icon-primary"
      icon={getIcon(type)}
      title={name}
      aria-label={name}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    />
  </ListItem>
);
