import { Text, type TextProps } from '@chakra-ui/react';
import { useEffect, useState, type FC } from 'react';

type Props = TextProps & {
  amount: string | number | null | undefined;
  currency?: Intl.NumberFormatOptions['currency'];
  maximumFractionDigits?: number;
};

const FormatCurrency: FC<Props> = ({
  amount,
  maximumFractionDigits = 2,
  currency = 'USD',
  ...props
}) => {
  const [formattedValue, setFormattedValue] = useState('-');

  useEffect(() => {
    if (amount) {
      const formatted = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currency,
      }).format(+amount);
      setFormattedValue(formatted);
    } else {
      setFormattedValue('-');
    }
  }, [amount, maximumFractionDigits, currency]);

  return <Text {...props}>{formattedValue}</Text>;
};

export default FormatCurrency;
