import { useNFTRefreshStore } from '@/components/features/NFT/useNFTRefresh';
import { useAuthentication } from '@/hooks/useAuthentication';
import { DefaultChain } from '@/utils/chains';
import { getSupportedChainById } from '@/utils/getChain';
import { routes } from '@/utils/routes';
import { CartIcon } from '@sphere/icons';
import { CartPopover, useCart } from '@sphere/reservoir-kit-ui';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';
import { HeaderActionButton } from '../../Components/ActionButton';

const CartButton: FC = () => {
  const { t } = useTranslation('common');
  const { data } = useCart(cart => cart);
  const { authenticate } = useAuthentication();
  const onRefresh = useNFTRefreshStore(state => state.refresh);

  const chain = getSupportedChainById(Number(data?.chain?.id)) ?? DefaultChain;

  return (
    <CartPopover
      onConnectWallet={() => {
        authenticate();
      }}
      onPurchaseComplete={onRefresh}
      tokenUrl={routes.nft.raw(chain.routePrefix)}
      trigger={
        <HeaderActionButton
          count={data.items.length}
          icon={<CartIcon />}
          aria-label={t('cart.open-button.aria-label')}
          title={t('cart.open-button.title', { count: data.items?.length || 0 })}
          data-testid="nav-cart-button"
        />
      }
    />
  );
};

export { CartButton };
