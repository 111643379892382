import { forwardRef, Icon, IconProps } from '@chakra-ui/react';
export default forwardRef<IconProps, 'svg'>((props, svgRef) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width={6}
    height={10}
    fill="none"
    viewBox="0 0 6 10"
    ref={svgRef}
    {...props}
  >
    <path
      fill="currentColor"
      d="M.532 9.504a.555.555 0 01-.053-.772l.053-.055L4.483 5 .532 1.323A.555.555 0 01.479.55L.532.496A.663.663 0 011.36.448l.059.048 4.396 4.09a.556.556 0 01.052.772l-.052.055-4.396 4.09a.662.662 0 01-.888 0z"
    />
  </Icon>
));
