import supportedChains from '@/utils/chains';
import { Box, BoxProps, Image } from '@chakra-ui/react';
import { MinimalSelect } from '@sphere/ui';
import { rem } from 'polished';
import { useDevCompanion } from '../DevCompanion.store';

type Props = BoxProps;

export const SetChainId = ({ ...props }: Props) => {
  const chainId = useDevCompanion(state => state.chainId);
  const setChainId = useDevCompanion(state => state.setChainId);

  return (
    <Box {...props}>
      <MinimalSelect
        onChange={value => setChainId(+value)}
        displayMobile
        value={String(chainId)}
        options={supportedChains.map(chain => ({
          label: chain.name,
          value: String(chain.id),
          icon: <Image src={chain.darkIconUrl} boxSize={rem(20)} flexShrink={0} />,
        }))}
      />
    </Box>
  );
};
