import { ChakraTheme } from '@chakra-ui/react';

export const errorTextStyles: ChakraTheme['textStyles'] = {
  error: {
    fontSize: {
      base: 'xs',
      md: 'sm',
    },
    lineHeight: 1.6,
    color: 'red',
  },
};
