import { env } from '@/env.mjs';
import { type passport } from '@imtbl/sdk';

const baseConfig = {
  environment: (env.NEXT_PUBLIC_HOST_URL === 'https://sphere.market'
    ? 'production'
    : 'sandbox') as any,
  publishableKey: env.NEXT_PUBLIC_IMMUTABLE_PUBLIC_KEY,
};

const config: passport.PassportModuleConfiguration = {
  baseConfig,
  clientId: env.NEXT_PUBLIC_IMMUTABLE_CLIENT_ID,
  redirectUri: `${env.NEXT_PUBLIC_HOST_URL}/auth/immutable/redirect`,
  logoutRedirectUri: `${env.NEXT_PUBLIC_HOST_URL}/`,
  forceScwDeployBeforeMessageSignature: true, // required to enable signature verification for new accounts
  logoutMode: 'silent', // prevent redirect to logoutRedirectUri
  audience: 'platform_api',
  scope: 'openid offline_access email transact',
};

export { baseConfig, config };
