//
// ChakraUI-port of Indrek Paas’s SASS-mixin,
// as edited by Adrian Klingen.
//
// ChakraUI-port by Ruben Swieringa.
//

import { useTheme } from '@chakra-ui/react';
import { remToPx } from 'polished';
import { useMemo } from 'react';

const pixelsWithoutUnit = (value: string) =>
  parseFloat(value.slice(-3) === 'rem' ? remToPx(value) : value);

export const useFluidSize = (
  min: number | string,
  max: number | string,
  breakpointMin = 'md',
  breakpointMax = 'xl',
  sizes?: Record<string, string | number>,
) => {
  const theme = useTheme();

  return useMemo(() => {
    const sizeLookup = sizes || theme.fontSizes;

    const minSizeWithUnit = typeof min === 'number' ? `${min}px` : sizeLookup[min];
    const minSizeWithoutUnit = typeof min === 'number' ? min : pixelsWithoutUnit(minSizeWithUnit);

    const maxWithUnit = typeof max === 'number' ? `${max}px` : sizeLookup[max];
    const maxWithoutUnit = typeof max === 'number' ? max : pixelsWithoutUnit(maxWithUnit);

    const breakpointMinWithUnit = theme.sizes.container[breakpointMin];
    const breakpointMinWithoutUnit = pixelsWithoutUnit(breakpointMinWithUnit);

    const breakpointMaxWithUnit = theme.sizes.container[breakpointMax];
    const breakpointMaxWithoutUnit = pixelsWithoutUnit(breakpointMaxWithUnit);

    return {
      base: minSizeWithUnit,
      [breakpointMin]: `calc(${minSizeWithUnit} + ((${maxWithoutUnit} - ${minSizeWithoutUnit})) * (100vw - ${breakpointMinWithUnit}) / (${breakpointMaxWithoutUnit} - ${breakpointMinWithoutUnit}))`,
      [breakpointMax]: maxWithUnit,
    };
  }, [theme, min, max, breakpointMin, breakpointMax, sizes]);
};
