import { Button, ButtonProps } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@sphere/icons';
import useTranslation from 'next-translate/useTranslation';
import { rem } from 'polished';
import { PropsWithChildren } from 'react';

export const BackButton = ({ children, ...buttonProps }: PropsWithChildren<ButtonProps>) => {
  const { t } = useTranslation('common');

  return (
    <Button
      variant="link"
      fontSize="md"
      lineHeight={1.5}
      fontWeight="bold"
      aria-label={t('header.nav.back')}
      {...buttonProps}
    >
      <ChevronLeftIcon boxSize={rem(10)} display="inline-block" mb="space.2" mr="space.6" />
      {children}
    </Button>
  );
};
