import { primary } from './primary';

export const light = {
  ...primary,

  backgroundColor: 'white',
  textColor: 'black',
  borderColor: 'gray.2',
  backdropFilter: 'blur(4px)',

  _hover: {
    ...primary._hover,
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
  },

  _active: {
    ...primary._active,
    backgroundColor: 'white',
  },
};
