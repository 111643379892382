import { SystemStyleObject } from '@chakra-ui/react';
import { HamburgerStyleProps } from './styles';

const noOutlineStyles: SystemStyleObject = {
  borderWidth: '1px',
  borderColor: 'transparent',
};

const outlineStyles: SystemStyleObject = {
  borderWidth: '1px',
  borderColor: 'gray.2',
};

export const getOutlineStyles = ({ active }: HamburgerStyleProps) =>
  active ? outlineStyles : noOutlineStyles;
