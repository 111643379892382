import { MittEmitter } from 'next/dist/shared/lib/mitt';
import { RouterEvent, useRouter } from 'next/router';
import { useEffect } from 'react';

const ROUTE_CHANGE_START = 'routeChangeStart';

type Event = Parameters<MittEmitter<RouterEvent>['on']>[0];
type Callback = () => unknown;

export const useRouteChange = (eventOrCallback: Event | Callback, callback?: Callback) => {
  const router = useRouter();

  useEffect(() => {
    const fn = typeof eventOrCallback === 'function' ? (eventOrCallback as Callback) : callback!;
    const event =
      typeof eventOrCallback === 'string' ? (eventOrCallback as Event) : ROUTE_CHANGE_START;

    router.events.on(event, fn);
    return () => router.events.off(event, fn);
  }, [router.events, eventOrCallback, callback]);
};
