import { useEffect, useState } from 'react';

export const useHasScroll = <E extends Element = Element>() => {
  const [element, ref] = useState<E | null>(null);
  const [hasScroll, setHasScroll] = useState(false);

  useEffect(() => {
    if (!element) return;

    const checkScroll = () => {
      if (!element) return;
      const hasScrollBar = element.scrollHeight > element.clientHeight;
      setHasScroll(hasScrollBar);
    };

    checkScroll();

    const observer = new MutationObserver(checkScroll);
    observer.observe(element, { childList: true, subtree: true });

    window.addEventListener('resize', checkScroll);

    return () => {
      observer.disconnect();
      window.removeEventListener('resize', checkScroll);
    };
  }, [element]);

  return [ref, hasScroll] as const;
};
