import { darkTheme as reservoirDarkTheme } from '@sphere/reservoir-kit-ui';
import { FONT, colors } from '@sphere/ui';

export const darkTheme = reservoirDarkTheme({
  headlineFont: FONT,
  font: FONT,
  primaryColor: colors.primary,
  primaryHoverColor: colors.primaryLight,
  headerBackground: colors.blackBg,
  contentBackground: colors.blackBg,
  footerBackground: colors.blackBg,
  popoverBackground: colors.blackBg,
  borderColor: colors.blackBg,
  buttonTextColor: colors.darkPurple,
  buttonTextHoverColor: colors.darkPurple,
  borderRadius: '24px',
  ethIcon: 'gray',
  chainIcon: 'light',
});
