import { ComponentStyleConfig } from '@chakra-ui/react';

export const tag: ComponentStyleConfig = {
  baseStyle: {
    container: {
      rounded: 'radius.4',
      py: 'space.4',
      px: 'space.8',
      color: 'white',
    },
  },
  variants: {
    dark: {
      container: {
        fontSize: 'xs',
        border: '1px solid',
        borderColor: 'gray.2',
        bg: 'rgba(19, 19, 19, 0.7)',
      },
    },
    black: {
      container: {
        fontSize: 'xs',
        bg: 'black',
      },
    },
  },
};
