import { Link, ListItem } from '@chakra-ui/react';
import NextLink from 'next/link';

export type FooterLinkItemProps = {
  text: string;
  href?: string;
  onClick?: () => void;
};

export const FooterLinkItem = ({ text, href, onClick }: FooterLinkItemProps) => (
  <ListItem display={{ md: 'inline-block' }}>
    <Link
      href={href}
      as={onClick ? 'button' : href?.includes('mailto') ? 'a' : NextLink}
      onClick={onClick}
      textStyle="footer-link-small"
      textDecoration="none"
      fontSize="xs"
      _hover={{
        opacity: 0.6,
      }}
    >
      {text}
    </Link>
  </ListItem>
);
