import { create } from 'zustand';

type Store = {
  isVisible: boolean;
  height: number;
  set: (store: Partial<Store>) => void;
};

export const useStatusBanner = create<Store>(set => ({
  isVisible: false,
  height: 0,
  set: store => set(store),
}));
