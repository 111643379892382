import { ERC721_ABI } from '@/constants/abis/ERC721';
import { getChainExplorerUrl } from '@/utils/chainExplorers';
import { Button, Checkbox, Flex, FlexProps, Heading, Input, Text } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import { toast } from 'sonner';
import { Address } from 'viem';
import { useSimulateContract, useWriteContract } from 'wagmi';
import { useDevCompanion } from '../../DevCompanion.store';
import { getSeaportAddress } from './seaportAddresses';

type Props = FlexProps;

export const SetApproval = ({ ...props }: Props) => {
  const { t } = useTranslation('companion');

  const [contractAddress, setContractAddress] = useState<string>();
  const [approve, setApprove] = useState(true);
  const chainId = useDevCompanion(state => state.chainId);

  const { data } = useSimulateContract({
    address: contractAddress as Address,
    abi: ERC721_ABI,
    functionName: 'setApprovalForAll',
    args: [getSeaportAddress(chainId), approve],
    chainId,
  });

  const { writeContract, isPending } = useWriteContract({
    mutation: {
      onSuccess: hash =>
        toast.success(t('set-approval.success-toast'), {
          action: {
            label: 'View transaction',
            onClick: () =>
              window.open(getChainExplorerUrl(chainId, hash, 'tx'), '_blank', 'noreferrer'),
          },
        }),
      onError: () => toast.error(t('set-approval.error-toast')),
    },
  });

  return (
    <Flex direction="column" {...props}>
      <Heading fontSize="md" fontWeight="bold">
        {t('set-approval.title')}
      </Heading>
      <Text fontSize="sm" color="muted" mt="space.4">
        {'Call setApproveForAll on a contract for the current connected wallet'}
      </Text>
      <Flex gap="space.8" my="space.8">
        <Input
          value={contractAddress}
          onChange={e => setContractAddress(e.target.value)}
          placeholder={t('set-approval.contract-address-placeholder')}
          size="sm"
        />
        <Checkbox
          isChecked={approve}
          onChange={e => setApprove(e.target.checked)}
          size="sm"
          fontWeight="bold"
        >
          {t('set-approval.approve')}
        </Checkbox>
        <Button
          onClick={() => writeContract(data!.request)}
          variant="clear"
          fontWeight="bold"
          size="sm"
          isDisabled={isPending || !writeContract}
        >
          {t('set-approval.submit')}
        </Button>
      </Flex>
    </Flex>
  );
};
