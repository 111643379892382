export const RESERVOIR_ID_REGEX = /^0x[a-fA-F0-9]{40}(:[a-zA-Z0-9-]+(\d+:\d+)*)*$/;

export const USERNAME_REGEX = /[^/]+(?=\/$|$)/;

export const URL_REGEX =
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

// Retrieves the `yyyy-MM-dd HH:mm:ss` date from a string
export const DATE_REGEX = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/;

export const NUMBER_REGEX = /^[0-9]*$/;
