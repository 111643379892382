const CROSS_CHAIN_SEAPORT_V1_5_ADDRESS = '0x00000000000000ADc04C56Bf30aC9d3c0aAF14dC';

export const seaportAddressMap: Record<number, string> = {
  13371: '0x6c12aD6F0bD274191075Eb2E78D7dA5ba6453424', // Immutable zkEVM
  13473: '0x7d117aA8BD6D31c4fa91722f246388f38ab1942c', // Immutable testnet
  4337: CROSS_CHAIN_SEAPORT_V1_5_ADDRESS, // Beam
  13337: CROSS_CHAIN_SEAPORT_V1_5_ADDRESS, // Beam testnet
};

export const getSeaportAddress = (chainId: number) =>
  seaportAddressMap[chainId] || CROSS_CHAIN_SEAPORT_V1_5_ADDRESS;
