import { useStatusBanner } from '@/components/features/layout/StatusBanner/useStatusBanner';
import { Box } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { rem } from 'polished';
import { type FC, type PropsWithChildren } from 'react';
import { Header } from './Header/Header';

type Props = PropsWithChildren & {
  hasBottomSpacing?: boolean;
  renderWithoutLayout?: boolean;
};

const MainWrapper: FC<Props> = ({ hasBottomSpacing, renderWithoutLayout, children }) => {
  const { pathname } = useRouter();
  const { height: statusBannerHeight } = useStatusBanner();

  return (
    <Box
      as="main"
      minH={`calc(100vh - ${rem(statusBannerHeight)} - ${rem(Header.height)})`}
      bg="blackBg"
      {...(!renderWithoutLayout && {
        zIndex: 'default',
        // There's not really a reason to have the bottom padding equal to the header height, it's just to keep the
        // top and bottom padding values equal to eachother
        pb: hasBottomSpacing ? { base: rem(Header.height), md: rem(200) } : undefined,
        flex: '1 0 auto',
        display: 'flex',
        flexDir: 'column',
        ...(pathname === '/[chain]' && {
          borderRadius: { base: 'radius.24', md: 'radius.32' },
          boxShadow: 'content',
        }),
      })}
    >
      {children}
    </Box>
  );
};

export default MainWrapper;
