import { Box, InputProps, Kbd } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { useEventListener } from 'usehooks-ts';
import { isMac } from './utils';

const ACTION_KEY_DEFAULT = ['Ctrl', 'Control'];
const ACTION_KEY_APPLE = ['⌘', 'Command'];
const SHORTCUT_KEY = 'k';

type Props = {
  onTrigger: () => void;
  variant: InputProps['variant'];
};

const SearchShortcut: FC<Props> = ({ onTrigger, variant }) => {
  const [actionKey, setActionKey] = useState<string[]>(ACTION_KEY_APPLE);

  useEffect(() => {
    if (typeof navigator === 'undefined') return;
    if (!isMac()) {
      setActionKey(ACTION_KEY_DEFAULT);
    }
  }, []);

  useEventListener('keydown', event => {
    const hotkey = isMac() ? 'metaKey' : 'ctrlKey';
    if (event?.key?.toLowerCase() === SHORTCUT_KEY && event[hotkey]) {
      event.preventDefault();
      onTrigger();
    }
  });

  const kbdVariant = variant === 'round-translucent' ? 'translucent' : undefined;

  return (
    <Box
      display={{ base: 'none', md: 'flex' }}
      _groupFocusWithin={{ display: 'none' }}
      gap="space.4"
      pr="space.16"
      pointerEvents="none"
    >
      <Kbd variant={kbdVariant}>{actionKey[0]}</Kbd>
      <Kbd variant={kbdVariant}>{SHORTCUT_KEY.toUpperCase()}</Kbd>
    </Box>
  );
};

export default SearchShortcut;
