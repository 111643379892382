import { api } from '@/utils/api';
import { getSupportedChainById } from '@/utils/getChain';
import { Button, Flex, FlexProps, Heading, Input, Text } from '@chakra-ui/react';
import { UserRole } from '@prisma/client';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { toast } from 'sonner';
import { useDevCompanion } from '../DevCompanion.store';

type Props = FlexProps;

const SetCollectionCreator = ({ ...props }: Props) => {
  const { t } = useTranslation('companion');
  const router = useRouter();
  const setUserRoleByAddress = api.admin.setUserRoleByAddress.useMutation();
  const setCollectionCreator = api.admin.setCollectionCreator.useMutation();
  const chainId = useDevCompanion(state => state.chainId);

  const [contractAddress, setContractAddress] = useState<string>(
    typeof router.query.contract === 'string' ? router.query.contract : '',
  );
  const [creatorAddress, setCreatorAddress] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleSet = useCallback(async () => {
    const chain = getSupportedChainById(chainId);
    if (!chain || !contractAddress || !creatorAddress) return;
    setLoading(true);

    try {
      await setUserRoleByAddress.mutateAsync({ address: creatorAddress, role: UserRole.CREATOR });
      toast.success(t('set-collection-creator.user-success-toast'));
    } catch {
      toast.error(t('set-collection-creator.user-error-toast'));
    }

    try {
      await setCollectionCreator.mutateAsync({
        chainId,
        address: contractAddress,
        creator: creatorAddress,
      });
      toast.success(t('set-collection-creator.success-toast'));
    } catch (error) {
      console.error(error);
      toast.error(t('set-collection-creator.error-toast'));
    } finally {
      setContractAddress('');
      setCreatorAddress('');
      setLoading(false);
    }
  }, [chainId, contractAddress, creatorAddress, setUserRoleByAddress, setCollectionCreator, t]);

  return (
    <Flex direction="column" {...props}>
      <Heading fontSize="md" fontWeight="bold">
        {'Set Collection Creator'}
      </Heading>
      <Text fontSize="sm" color="muted" mt="space.4">
        {
          'Override the creator of a collection, allowing them to manage it and its assets. This will also set the creator as a creator user role.'
        }
      </Text>
      <Flex gap="space.8" my="space.8">
        <Input
          value={contractAddress}
          onChange={e => setContractAddress(e.target.value)}
          onFocus={() => setContractAddress('')}
          placeholder={t('set-collection-creator.collection-address-placeholder')}
          size="sm"
        />
        <Input
          value={creatorAddress}
          onChange={e => setCreatorAddress(e.target.value)}
          placeholder={t('set-collection-creator.creator-address-placeholder')}
          size="sm"
        />
        <Button
          onClick={handleSet}
          isLoading={isLoading}
          isDisabled={isLoading}
          variant="clear"
          fontWeight="bold"
          size="sm"
          w="fit-content"
        >
          {t('set')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default SetCollectionCreator;
