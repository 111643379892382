import { create } from 'zustand';

type State = {
  hasBackground: boolean;
};

type Methods = {
  setHasBackground: (hasBackground: boolean) => void;
};

export const useHeaderSettings = create<State & Methods>(set => ({
  hasBackground: true,

  setHasBackground: hasBackground => set({ hasBackground }),
}));
