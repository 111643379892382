import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  menuAnatomy.keys,
);

const baseStyle = definePartsStyle({
  list: {
    p: 'space.8',
    borderRadius: 'radius.8',
    border: 'none',
    bg: 'gray.2',
    color: 'white',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.08)',
  },
  item: {
    bg: 'transparent',
    fontWeight: 'medium',
    borderRadius: 'radius.4',
    _hover: {
      bg: 'gray.4',
    },
    _focus: {
      bg: 'gray.4',
    },
  },
});

export const menu = defineMultiStyleConfig({ baseStyle });
