import { Box, BoxProps, forwardRef } from '@chakra-ui/react';
import { Children, PropsWithChildren, createContext, useContext } from 'react';

const WIDTH_PRECISION = 0.1; // -> 33.3%

const percentage = (value: number) =>
  `${Math.round((value * 100) / WIDTH_PRECISION) * WIDTH_PRECISION}%`;

const Context = createContext<{ numScreens: number }>({
  numScreens: 0,
});

type Props = BoxProps & {
  current?: number;
};

const Component = ({
  current: unsafeCurrent,
  children: screens,
  ...boxProps
}: PropsWithChildren<Props>) => {
  const numScreens = Children.toArray(screens).length || 1;
  const current = unsafeCurrent && unsafeCurrent > 0 ? Math.min(numScreens - 1, unsafeCurrent) : 0;

  return (
    <Box width="full" overflow="hidden" {...boxProps}>
      <Box
        display="flex"
        flexDirection="row"
        width={percentage(numScreens)}
        transform={`translate3d(${percentage((-1 / numScreens) * current)}, 0, 0)`}
        transitionProperty="transform"
        transitionDuration={boxProps.transitionDuration || '500ms'}
      >
        <Context.Provider value={{ numScreens }}>{screens}</Context.Provider>
      </Box>
    </Box>
  );
};

Component.Slot = forwardRef<BoxProps, 'div'>(({ children, ...boxProps }, ref) => {
  const { numScreens } = useContext(Context); // eslint-disable-line react-hooks/rules-of-hooks
  return (
    <Box ref={ref} as="div" width={percentage(1 / numScreens)} {...boxProps}>
      {children}
    </Box>
  );
});

export const ScreenSlider = Component;
