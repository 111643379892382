export const gradients = {
  sunnySky:
    'linear-gradient(90deg, #0D7EC4 0%, #139EDD 16.67%, #BBDBFF 31.77%, #FEA514 48.44%, #FF4539 58.33%, #FF5544 68.75%, #FF6A9A 81.25%, #BBDBFF 89.58%, #0D7EC4 100%)',
  retroArcade:
    'linear-gradient(90deg, #37429C 0%, #7AE1E8 16.67%, #F7F1F1 31.77%, #F1E869 48.44%, #E3A262 58.33%, #C85F63 68.75%, #534CC0 81.25%, #B9F7EA 89.58%, #37429C 100%)',
  heading:
    'linear-gradient(85.72deg, #89DCE4 -83.11%, #FAF1F2 -46.12%, #E9E76E -11.32%, #D36F58 14.38%, #4F4DB0 52.48%, #74EEF9 87.05%);',
  text: 'linear-gradient(84.79deg, #89DCE4 -92.88%, #FAF1F2 -47.79%, #E9E76E -5.37%, #D36F58 25.96%, #4F4DB0 72.42%, #74EEF9 114.55%);',
  prominentBorder:
    'conic-gradient(from 180deg at 50% 50%, #0D7EC4 0deg, #139EDD 60deg, #BBDBFF 114.38deg, #FEA514 174.38deg, #FF4539 210deg, #FF5544 247.5deg, #FF6A9A 292.5deg, #BBDBFF 322.5deg, #0D7EC4 360deg)',
  linearProminentBorder:
    'linear-gradient(to right, #222E90, #74EEF9, #4F4DB0, #D36F58, #E9E76E, #E9E76E, #D36F58, #4F4DB0, #74EEF9, #222E90)',
  selection:
    'linear(to-r, #0D7EC4, #139EDD, #BBDBFF, #FEA514, #FF4539, #FF5544, #FF6A9A, #BBDBFF, #0D7EC4)',
  sunnySkyConic:
    'conic-gradient(from 180deg at 50% 50%, #0D7EC4 0deg, #139EDD 60deg, #BBDBFF 114.38deg, #FEA514 174.38deg, #FF4539 210deg, #FF5544 247.5deg, #FF6A9A 292.5deg, #BBDBFF 322.5deg, #0D7EC4 360deg)',
};

// TODO: clean up color names and gray shades
export const colors = {
  black: '#000000',
  blackBg: '#131313',
  white: '#ffffff',
  primary: '#EBBCFF',
  primaryLight: '#F0CCFF',
  primaryDark: '#E5A9FF',
  darkPurple: '#4D1366',
  border: 'rgba(239, 239, 239, 0.25)',
  transparent: 'transparent',
  gray: {
    1: '#F5F5F7',
    2: '#222222',
    3: '#323232',
    4: '#444444',
    5: '#858585',
    6: '#b7b7b7',
  },
  green: '#3fb950',
  darkGreen: '#253325',
  orange: '#ffae35',
  darkOrange: '#533600',
  red: '#f85149',
  darkRed: '#481510',
  blue: '#0D7EC4',
  darkBlue: '#152841',
  sky: '#1680FF',

  // Borders
  // border: '#212121',
  borderLight: '#383838',
  // color used for focus ring
  outlineBlue: 'rgba(66, 153, 225, 0.6)',

  // Tints & Shades
  muted: '#A0A2AC', // Melting Snow

  // Named
  arcticBlue: '#1680FF',
  dimmedArcticBlue: '#E4F0FF',
  princetonOrange: '#EC8741',
  mightyRed: '#FF5A54',
};
