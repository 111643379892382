import { useDevCompanion } from '@/components/features/DevCompanion/DevCompanion.store';
import { api } from '@/utils/api';
import { getSupportedChainById } from '@/utils/getChain';
import { Button, Checkbox, Flex, FlexProps, Heading, Input, Text } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useState } from 'react';
import { toast } from 'sonner';

const CreateGame = (props: FlexProps) => {
  const { t } = useTranslation('companion');
  const createGame = api.admin.createGame.useMutation();
  const chainId = useDevCompanion(state => state.chainId);

  const [slug, setSlug] = useState<string>('');
  const [creatorAddress, setCreatorAddress] = useState<string>('');
  const [isWhiteLabelGame, setIsWhiteLabelGame] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleSet = useCallback(async () => {
    const chain = getSupportedChainById(chainId);
    if (!chain || !slug || !creatorAddress) return;
    setLoading(true);

    try {
      await createGame.mutateAsync({
        chainId,
        creatorAddress,
        isWhiteLabelGame,
        slug,
      });
      toast.success(t('create-game.success-toast'));
    } catch (error) {
      console.error(error);
      toast.error(error instanceof Error ? error.message : t('create-game.error-toast'));
    } finally {
      setSlug('');
      setCreatorAddress('');
      setIsWhiteLabelGame(false);
      setLoading(false);
    }
  }, [chainId, createGame, creatorAddress, slug, t, isWhiteLabelGame]);

  return (
    <Flex direction="column" {...props}>
      <Heading fontSize="md" fontWeight="bold">
        {'Create game'}
      </Heading>
      <Text fontSize="sm" color="muted" mt="space.4">
        {'Create a game overview page.'}
      </Text>
      <Flex gap="space.8" my="space.8" direction="column">
        <Input
          value={slug}
          onChange={e => setSlug(e.target.value)}
          onFocus={() => setSlug('')}
          placeholder={t('create-game.slug-placeholder')}
          size="sm"
        />
        <Input
          value={creatorAddress}
          onChange={e => setCreatorAddress(e.target.value)}
          placeholder={t('create-game.creator-address-placeholder')}
          size="sm"
        />
        <Checkbox
          checked={isWhiteLabelGame}
          onChange={e => setIsWhiteLabelGame(e.target.checked)}
          size="sm"
        >
          {'Has white label deployment'}
        </Checkbox>
        <Button
          onClick={handleSet}
          isLoading={isLoading}
          isDisabled={isLoading}
          variant="clear"
          fontWeight="bold"
          size="sm"
          w="fit-content"
        >
          {t('add')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default CreateGame;
