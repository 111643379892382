import { extendTheme, ThemeOverride } from '@chakra-ui/react';

import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { components } from './components';
import { globalStyle as global } from './global-style';
import { radii } from './radii';
import { shadows } from './shadows';
import { sizes, space } from './sizes';
import { textStyles } from './textStyles';
import { fonts, fontSizes, fontWeights, letterSpacings, lineHeights } from './typography';
import { zIndices } from './zIndices';

export const theme: ThemeOverride = extendTheme({
  breakpoints,
  components,
  colors,
  fonts,
  sizes,
  space,
  fontWeights,
  fontSizes,
  letterSpacings,
  lineHeights,
  radii,
  shadows,
  zIndices,
  textStyles,
  styles: {
    global,
  },
});
