import { rem } from 'polished';

import { colors } from '../../colors';

import { primary } from './primary';

export const secondaryGray = {
  ...primary,

  backgroundColor: 'gray.1',
  textColor: colors.black,
  mt: 'space.12',
  fontSize: rem(16),
  lineHeight: rem(36),

  _hover: {
    ...primary._hover,
    backgroundColor: 'gray.2',
  },

  _active: {
    ...primary._active,
    backgroundColor: 'gray.2',
  },
};
