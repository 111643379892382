import { checkboxAnatomy as parts } from '@chakra-ui/anatomy';
import type { MultiStyleConfig, PartsStyleObject, SystemStyleObject } from '@chakra-ui/react';
import { rem } from 'polished';

const control: SystemStyleObject = {
  border: '1px solid',
  bg: 'white',
  transition: 'all .2s ease',
  _hover: {
    borderColor: 'black',
  },
  _groupHover: {
    borderColor: 'black',
  },
  _checked: {
    bg: 'black',
    p: '3px',
    borderColor: 'black',
    color: 'white',
  },
};

const radioSize = {
  control: { w: rem(24), h: rem(24) },
  icon: { fontSize: '2xs' },
};

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  sm: {
    ...radioSize,
    label: { fontSize: 'sm', fontWeight: 'medium' },
  },
  md: {
    ...radioSize,
    label: { fontSize: 'md', fontWeight: 'medium' },
  },
};

export const radio: Omit<MultiStyleConfig, 'parts'> = {
  baseStyle: {
    control,
  },
  variants: {
    fullWidth: {
      label: {
        w: 'full',
      },
    },
  },
  sizes,
};
