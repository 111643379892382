import { ChakraTheme } from '@chakra-ui/react';

import { countdownTextStyles } from './countdown';
import { errorTextStyles } from './error';
import { footerTextStyles } from './footer';
import { homeTextStyles } from './home';

export const textStyles: ChakraTheme['textStyles'] = {
  ...homeTextStyles,
  ...footerTextStyles,
  ...countdownTextStyles,
  ...errorTextStyles,
};
