import { ComponentStyleConfig } from '@chakra-ui/react';

export const heading: ComponentStyleConfig = {
  baseStyle: {
    fontSize: '2xl',
    fontWeight: 'light',
    lineHeight: 1.2,
    whiteSpace: 'pre-line',
  },
  sizes: {
    '2xl': {
      fontSize: ['2xl', null, '4xl'],
    },
    xl: {
      fontSize: ['2xl', null, '3xl'],
    },
    lg: {
      fontSize: ['2xl', null, '4xl'],
      fontWeight: 500,
    },
    md: {
      fontSize: ['1.25rem', null, 'xl'],
      fontWeight: '300',
    },
    sm: {
      fontSize: ['lg', null, 'xl'],
    },
    // NOTE: not in use
    xs: {
      fontSize: ['lg', null],
    },
  },
};
