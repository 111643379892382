import { rem } from 'polished';

import { input } from './input';

import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  selectAnatomy.keys,
);

export const select = defineMultiStyleConfig({
  sizes: input.sizes,
  variants: {
    outline: input.variants?.outline ?? {},
    facet: definePartsStyle({
      field: {
        bg: 'white',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'gray.2',
        borderRadius: rem(4),
        px: 'space.12',
        fontSize: 'sm',
        height: rem(44),
        _hover: {
          borderColor: 'gray.5',
        },
        _focus: {
          borderColor: 'gray.5',
        },
      },
    }),
  },
});
