import { cssVar, defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

const $startColor = cssVar('skeleton-start-color');
const $endColor = cssVar('skeleton-end-color');

const baseStyle = defineStyle({
  [$startColor.variable]: 'colors.gray.2',
  [$endColor.variable]: 'colors.gray.4',
  _dark: {
    [$startColor.variable]: 'colors.gray.800',
    [$endColor.variable]: 'colors.gray.300',
  },
  background: $startColor.reference,
  borderColor: $endColor.reference,
  opacity: 1,
  borderRadius: 'md',
});

export const skeleton = defineStyleConfig({
  baseStyle,
});
