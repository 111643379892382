import { rem } from 'polished';
import { keyboardFocussed } from '../../tools/keyboard-focussed';
import { primary } from './primary';
import { secondary } from './secondary';

export const icon = {
  background: 'transparent',
  width: '32px',
  height: '32px',
  minWidth: '0px',
  p: '0',
  ...keyboardFocussed({
    backgroundColor: 'secondary',
  }),
  svg: {
    width: '20px',
    height: '20px',
    m: '0 auto',
  },
  path: {
    fill: 'currentColor',
  },
};

export const iconSecondary = {
  ...icon,
  ...secondary,

  borderRadius: 'full',
  width: rem(36),
  height: rem(36),
  transitionDuration: '130ms',
  _hover: {
    ...secondary._hover,
    svg: {
      transform: 'scale(1.05)',
    },
  },
};

export const iconPrimary = {
  ...icon,
  ...primary,

  borderRadius: 'full',
  width: rem(36),
  height: rem(36),
  transitionDuration: '130ms',
  _hover: {
    ...primary._hover,
    svg: {
      transform: 'scale(1.05)',
    },
  },
};
