import { Flex, Text, TextProps, type FlexProps } from '@chakra-ui/react';
import { formatTokenAmount } from '@onbeam/utils';
import React, { forwardRef } from 'react';

type Props = FlexProps & {
  amount: string | number | bigint | null | undefined;
  maximumFractionDigits?: number;
  decimals?: number;
  children?: React.ReactNode;
  textProps?: TextProps;
};

const FormatCrypto = forwardRef<HTMLDivElement, Props>(
  (
    { amount, maximumFractionDigits = 2, decimals = 18, children, textProps = {}, ...props },
    ref,
  ) => {
    const value = formatTokenAmount(amount || undefined, {
      maximumFractionDigits,
      decimals,
      fallback: '-',
    });

    return (
      <Flex align="center" gap="1" minWidth="max-content" {...props} ref={ref}>
        {value !== '-' ? children : null}
        <Text as="p" {...textProps}>
          {value}
        </Text>
      </Flex>
    );
  },
);

export default FormatCrypto;
