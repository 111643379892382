import { type DefaultSeoProps } from 'next-seo';

const config: DefaultSeoProps = {
  titleTemplate: '%s - Sphere',
  defaultTitle: 'Sphere',
  openGraph: {
    siteName: 'Sphere',
    images: [
      { url: `${process.env.NEXT_PUBLIC_HOST_URL}/sphere-og.png`, width: 2400, height: 1260 },
    ],
  },
  twitter: {
    site: '@GetOnSphere',
    cardType: 'summary_large_image',
  },
};

export default config;
