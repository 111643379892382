import { ComponentStyleConfig } from '@chakra-ui/react';

export const _switch: ComponentStyleConfig = {
  baseStyle: {
    track: {
      _checked: {
        bg: 'darkPurple',
      },
    },
    thumb: {
      _checked: {
        bg: 'white',
      },
    },
  },
};
