import { ContextMenu } from '@/components/shared/ContextMenu';
import { ChainContext } from '@/context/ChainContextProvider';
import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { useAuthentication } from '@/hooks/useAuthentication';
import supportedChains, { type ReservoirChain } from '@/utils/chains';
import { routes } from '@/utils/routes';
import {
  Flex,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { rem } from 'polished';
import { useCallback, useContext, type FC } from 'react';
import { useSwitchChain } from 'wagmi';
import { HeaderActionButton } from '../../Components/ActionButton';
import { MenuLink } from '../../Components/MenuLink';

// Routes that support to have [chain] switched for a different value
const chainSwitchSupportedRoutes: string[] = [
  '/[chain]',
  '/[chain]/collections',
  '/[chain]/insights',
];

const SwitchChainButton: FC = () => {
  const router = useRouter();
  const { t } = useTranslation('common');
  const { switchCurrentChain } = useContext(ChainContext);
  const currentChain = useMarketplaceChain();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { switchChain } = useSwitchChain();
  const { activeConnector } = useAuthentication();

  const handleSwitchChain = useCallback(
    async (chainOption: ReservoirChain) => {
      switchCurrentChain(chainOption.id);
      switchChain?.({ chainId: chainOption.id });
      onClose();

      // If the user is on the account page, change the chain query param instead
      if (router.pathname === '/account/[address]') {
        router.replace({ query: { ...router.query, chain: chainOption.routePrefix } });
        return;
      }

      // If on a route that supports chain switching, switch chain and keep route
      if (chainSwitchSupportedRoutes.includes(router.pathname)) {
        const route = router.pathname.replace('[chain]', chainOption.routePrefix);
        router.push(route);
        return;
      }

      // Otherwise, switch chain and go to the default route for the chain
      const route = routes.home(chainOption.routePrefix);
      await router.push(route);
    },
    [router, switchCurrentChain, switchChain, onClose],
  );

  if (supportedChains.length === 1) return null;

  return (
    <Popover
      closeOnBlur
      closeOnEsc
      placement="bottom"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <PopoverTrigger>
        <HeaderActionButton
          icon={<Image src={currentChain.darkIconUrl} boxSize={rem(20)} flexShrink={0} />}
          aria-label={t('header.chain.switch-button.aria-label')}
          title={t('header.chain.switch-button.title', { chain: currentChain.name })}
        />
      </PopoverTrigger>
      <PopoverContent width={rem(250)} border="none">
        <ContextMenu zIndex="header.inFront">
          <Stack direction="column" alignItems="flex-start" spacing={4}>
            {supportedChains.map(chain => {
              const isUnsupportedConnector =
                activeConnector && !chain.supportedConnectors.includes(activeConnector.id);

              return (
                <Tooltip
                  key={chain.id}
                  label={t('header.chain.switch-button.unsupported-connector', {
                    chain: chain.name,
                    connector: activeConnector?.name,
                  })}
                  placement="top"
                  hasArrow
                  arrowSize={8}
                  isDisabled={!isUnsupportedConnector}
                >
                  <MenuLink
                    onClick={() => handleSwitchChain(chain)}
                    activeLinkColor="white"
                    underlineOffset="space.24"
                  >
                    <Flex alignItems="center" justifyContent="center" gap="space.8" color="inherit">
                      <Image src={chain.darkIconUrl} boxSize={rem(20)} flexShrink={0} />
                      <span>{chain.name}</span>
                    </Flex>
                  </MenuLink>
                </Tooltip>
              );
            })}
          </Stack>
        </ContextMenu>
      </PopoverContent>
    </Popover>
  );
};

export default SwitchChainButton;
