import { socialMediaProfiles } from '@/config/social-media';
import { List } from '@chakra-ui/react';
import { SocialMediaLinkItem as Item } from './SocialMediaLinkItem';

type Props = {
  variant?: 'primary' | 'footer';
};

export const SocialMediaLinksList = ({ variant = 'primary' }: Props) => {
  const isPrimary = variant === 'primary';

  return (
    <List display="flex" justifyContent="center">
      {socialMediaProfiles.map(({ type, name, url }) => (
        <Item
          key={type}
          type={type}
          name={name}
          url={url}
          ml={{ base: 'space.12', md: isPrimary ? 'space.24' : 'space.12' }}
          _first={{ ml: 0 }}
        />
      ))}
    </List>
  );
};
