import { env } from '@/env.mjs';
import supportedChains from '@/utils/chains';
import { Chain, Transport } from 'viem';
import { createConfig, http } from 'wagmi';
import { coinbaseWallet, injected, walletConnect } from 'wagmi/connectors';

const chains = supportedChains as unknown as [Chain, ...Chain[]];

type Transports = Record<(typeof chains)[number]['id'], Transport>;

const transports = chains.reduce((acc: Transports, chain) => {
  const key = chain.id as keyof Transports;
  acc[key] = http() as Transports[keyof Transports];
  return acc;
}, {} as Transports);

const connectors = [
  injected(),
  coinbaseWallet(),
  walletConnect({ projectId: env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID }),
];

const wagmiConfig = createConfig({
  chains,
  transports,
  connectors,
  ssr: true,
});

export { chains, wagmiConfig };
