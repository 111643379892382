import { ChakraTheme } from '@chakra-ui/react';

const FONT_FALLBACK = 'Helvetica, Arial, Lucida Grande, sans-serif';
export const FONT = `"SuisseIntl", ${FONT_FALLBACK}`;

export const fonts: ChakraTheme['fonts'] = {
  heading: FONT,
  body: FONT,
  mono: FONT,
  // Didn't see another use for this yet within the design
  placeholder: `"Inter", ${FONT_FALLBACK}`,
};

export const fontSizes: ChakraTheme['fontSizes'] = {
  '3xs': '0.5rem', // 8px
  '2xs': '0.625rem', // 10px
  xs: '0.75rem', // 12px
  sm: '0.875rem', // 14px
  md: '1rem', // 16px
  lg: '1.125rem', // 18px
  xl: '1.5rem', // 24px
  '2xl': '1.875rem', // 30px
  '3xl': '2.25rem', // 36px
  '4xl': '3rem', // 48px
  '5xl': '4rem', // 64px
  '6xl': '4.75rem', // 76px
};

export const fontWeights: ChakraTheme['fontWeights'] = {
  light: 300,
  normal: 400,
  medium: 500,
  bold: 700,
};

export const letterSpacings: ChakraTheme['letterSpacings'] = {
  title: '-0.02em',
  subtitle: '-0.01em',
};

export const lineHeights: ChakraTheme['lineHeights'] = {
  xs: '115%',
  sm: '120%',
  md: '130%',
  lg: '140%',
  xl: '150%',
  '2xl': '160%',
  '3xl': '170%',
};
