import { SystemStyleObject, theme } from '@chakra-ui/react';

import { link as linkStyles } from '../link';

export const link: SystemStyleObject = {
  // Imports next base styles
  ...theme.components.Link.baseStyle,
  // Overries with our own changes
  ...linkStyles.baseStyle,
  // Add custom textDecoration behavior
  textDecoration: 'none',
  _hover: {
    opacity: 0.4,
    textDecoration: 'none',
  },
};
