import { Children, cloneElement, PropsWithChildren, ReactElement } from 'react';
import { useSphereTheme } from '../../context';

declare global {
  type LinkConnectorProps = Record<string, unknown>;
}
/**
 * The LinkConnector component provides a way to communicate with the router of the framework you are using this library in
 */
const LinkConnector = ({ children, ...linkProps }: PropsWithChildren<LinkConnectorProps>) => {
  const { linkWrapper } = useSphereTheme();
  const child = Children.only(children) as ReactElement;

  return linkWrapper(cloneElement(child, linkProps));
};

export { LinkConnector };
