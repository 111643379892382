import { forwardRef, Icon, IconProps } from '@chakra-ui/react';
export default forwardRef<IconProps, 'svg'>((props, svgRef) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    viewBox="0 0 21 20"
    ref={svgRef}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.286 17.68c0-1.006.807-1.822 1.802-1.822s1.802.816 1.802 1.821A1.812 1.812 0 018.088 19.5a1.811 1.811 0 01-1.802-1.82zm8.799 0c0-1.006.806-1.822 1.801-1.822.995 0 1.802.816 1.802 1.821a1.812 1.812 0 01-1.802 1.821 1.811 1.811 0 01-1.801-1.82zM.688 1.512c0-.559.447-1.012 1-1.012h3.2c.477 0 .888.34.982.814l.64 3.228h12.775c.298 0 .581.134.772.367.19.232.268.538.212.835l-1.281 6.79a2.634 2.634 0 01-.92 1.535 2.584 2.584 0 01-1.671.581H8.64a2.584 2.584 0 01-1.67-.58 2.634 2.634 0 01-.92-1.536L4.715 5.793a1.015 1.015 0 01-.016-.078l-.632-3.19H1.69A1.007 1.007 0 01.687 1.512zM6.91 6.567l1.104 5.572a.594.594 0 00.598.486h7.813a.605.605 0 00.597-.484l1.052-5.574H6.91z"
      clipRule="evenodd"
    />
  </Icon>
));
