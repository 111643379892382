import { chains } from '@/utils/chains';
import { compareAddresses } from '@onbeam/utils';
import { zeroAddress } from 'viem';

type Props = {
  chainId: number;
  contract?: string;
  includeAll?: boolean;
};

export const getChainCurrencies = ({ chainId, contract, includeAll }: Props) => {
  const chain = chains.find(c => c.id === chainId);
  if (!chain) return [];

  if (includeAll) return [...(chain.defaultCurrencies || []), ...(chain.customCurrencies || [])];

  const customCurrencies =
    chain.customCurrencies?.filter(currency =>
      currency.collections?.some(collection => compareAddresses(collection, contract)),
    ) || [];

  return [...customCurrencies, ...(chain.defaultCurrencies || [])];
};

export const getBidCurrencies = (chainId: number, contract?: string) =>
  getChainCurrencies({ chainId, contract }).filter(currency => currency.contract !== zeroAddress);
