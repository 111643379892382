import { forwardRef, Icon, IconProps } from '@chakra-ui/react';
export default forwardRef<IconProps, 'svg'>((props, svgRef) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={16}
    fill="none"
    viewBox="0 0 18 16"
    ref={svgRef}
    {...props}
  >
    <g clipPath="url(#warning_svg__warning_svg__clip0_5_70)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.377 16h13.246c1.815 0 2.959-1.893 2.069-3.426L11.069 1.172c-.908-1.563-3.23-1.563-4.138 0L.308 12.574C-.582 14.107.562 16 2.378 16zM9.8 12.5a.5.5 0 01-.5.5h-.6a.5.5 0 01-.5-.5v-.6a.5.5 0 01.5-.5h.6a.5.5 0 01.5.5v.6zm-.5-2.1a.5.5 0 00.5-.5V6.1a.5.5 0 00-.5-.5h-.6a.5.5 0 00-.5.5v3.8a.5.5 0 00.5.5h.6z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="warning_svg__warning_svg__clip0_5_70">
        <path fill="currentColor" d="M0 0h18v16H0z" />
      </clipPath>
    </defs>
  </Icon>
));
