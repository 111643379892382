const chainExplorers: Record<number, { name: string; url: string }> = {
  1: { name: 'Etherscan', url: 'https://etherscan.io' },
  11155111: { name: 'Sepolia Etherscan', url: 'https://sepolia.etherscan.io' },
  137: { name: 'Polygonscan', url: 'https://polygonscan.com' },
  80001: { name: 'Mumbai Polygonscan', url: 'https://mumbai.polygonscan.com' },
  43114: { name: 'Snowtrace', url: 'https://snowtrace.io' },
  43113: { name: 'Snowtrace', url: 'https://testnet.snowtrace.io' },
  4337: { name: 'Beam Explorer', url: 'https://subnets.avax.network/beam' },
  13337: { name: 'Beam Testnet Explorer', url: 'https://subnets-test.avax.network/beam' },
  13371: { name: 'Immutable Explorer', url: 'https://explorer.immutable.com' },
  13473: { name: 'Immutable Testnet Explorer', url: 'https://explorer.testnet.immutable.com' },
  531050104: { name: 'Sophon Testnet Explorer', url: 'https://explorer.testnet.sophon.xyz' },
};

const getChainExplorer = (chainId: number) => {
  const explorer = chainExplorers[chainId];
  if (!explorer) throw new Error('Unsupported or invalid chain id');
  return explorer;
};

export const getChainExplorerName = (chainId: number) => getChainExplorer(chainId).name;

export const getChainExplorerUrl = (chainId: number, address: string, type: 'address' | 'tx') =>
  `${getChainExplorer(chainId).url}/${type}/${address}`;
