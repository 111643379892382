import { baseConfig, config } from '@/lib/immutable/config';
import { getWalletProviderNameFromConnector, isImmutableChain } from '@/utils/immutable';
import { type checkout } from '@imtbl/sdk';
import * as Sentry from '@sentry/nextjs';
import { createContext, useCallback, useState, type FC } from 'react';
import { useAccount } from 'wagmi';

type Context = {
  connect(): Promise<{
    checkoutSDK: checkout.Checkout;
    provider: checkout.CreateProviderResult['provider'];
  }>;
  isConnecting: boolean;
};

export const IMXCheckoutContext = createContext<Context>({
  connect: () => {
    throw new Error('wrap application in IMXCheckoutProvider');
  },
  isConnecting: false,
});

const IMXCheckoutProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { connector, chain } = useAccount();
  const [isConnecting, setIsConnecting] = useState(false);

  const connect = useCallback(async () => {
    try {
      setIsConnecting(true);

      // Note: it takes a couple of seconds for the connector to be set when using Passport. It is advised
      // to wait for the connector to be set before calling connect
      if (!connector) throw new Error('No active connector');

      if (!isImmutableChain(chain?.id)) throw new Error('Not connected to an IMX chain');

      const { checkout, passport } = await import('@imtbl/sdk');

      const passportInstance = new passport.Passport(config);

      const checkoutSDK = new checkout.Checkout({
        baseConfig,
        bridge: { enable: true },
        onRamp: { enable: true },
        swap: { enable: true },
        passport: passportInstance,
      });

      const walletProviderName = getWalletProviderNameFromConnector(connector);
      const { provider } = await checkoutSDK.createProvider({ walletProviderName });

      if (!checkout.Checkout.isWeb3Provider(provider)) {
        throw new Error('Provider is not a web3 provider');
      }

      const result = await checkoutSDK.connect({ provider });

      return {
        checkoutSDK,
        // Always return the result provider as that one holds the connection
        provider: result.provider,
      };
    } catch (err) {
      Sentry.captureException(err);
      throw err;
    } finally {
      setIsConnecting(false);
    }
  }, [chain?.id, connector]);

  return (
    <IMXCheckoutContext.Provider value={{ connect, isConnecting }}>
      {children}
    </IMXCheckoutContext.Provider>
  );
};

export default IMXCheckoutProvider;
