import { useMenuItems } from '@/components/features/layout/Header/Menu/menuItems';
import { useAuthentication } from '@/hooks/useAuthentication';
import {
  Box,
  Divider,
  Stack,
  forwardRef,
  useBreakpointValue,
  type BoxProps,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { ScreenSlider } from '@sphere/ui';
import { rem } from 'polished';
import { Fragment, useMemo, type ReactNode } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { MenuLink } from '../../Components/MenuLink';
import { MenuScreen, SubmenuScreen, useMenu } from '../../useHeaderSettings/useMenu';
import { UserMenu } from '../UserMenu';
import { BackButton } from './BackButton';

const TRANSITION_DURATION = 500; // milliseconds
const SHADOW_CSS_VAR_NAME = 'compact-menu-header-shadow';

type Props = BoxProps;

// We have to use a styled-component in order to use fallback css as Chakra does not support this
const FullDeviceBox = styled(Box)`
  height: 100vh;
  height: 100dvh;
`;

const FullScreenSlider = styled(ScreenSlider)`
  height: calc(100vh - var(--offset));
  height: calc(100dvh - var(--offset));
`;

const FullScreenSliderSlot = styled(ScreenSlider.Slot)`
  height: calc(100vh - var(--offset));
  height: calc(100dvh - var(--offset));
`;

export const MobileMenu = (boxProps: Props) => {
  const { userAddress } = useAuthentication();
  const offset = 0;

  const { menu, submenu, setSubmenu } = useMenu();
  const menuItems = useMenuItems();
  const isMobile = useBreakpointValue({ base: true, md: false }, 'md');

  const filteredItems = Object.values(menuItems).filter(
    ({ isCreator, isAdmin }) => !isCreator && !isAdmin,
  );

  const isOpen = useMemo(() => isMobile && !!menu, [isMobile, menu]);

  return (
    <RemoveScroll enabled={isOpen}>
      <FullDeviceBox
        position="fixed"
        top={offset}
        left={0}
        width="full"
        overflow="hidden"
        as="nav"
        pointerEvents={isOpen !== false ? 'auto' : 'none'}
        _after={{
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 0,
          left: 0,
          width: 'full',
          height: rem(80),
          background: 'blackBg',
          boxShadow: `var(--${SHADOW_CSS_VAR_NAME})`,
          transitionProperty: 'transform',
          transitionDuration: `${TRANSITION_DURATION}ms`,
          transitionTimingFunction: 'ease-in-out',
          transform: `translate3d(${isOpen ? 0 : 100}%,0,0)`,
        }}
        {...boxProps}
      >
        <FullScreenSlider
          current={+!!submenu}
          width="full"
          sx={{ '--offset': `${rem(offset)}` }}
          background="blackBg"
          transitionProperty="transform, box-shadow"
          transitionDuration={`${TRANSITION_DURATION}ms`}
          transitionTimingFunction="ease-in-out"
          transform={`translate3d(${isOpen ? 0 : 100}%,0,0)`}
          boxShadow={isOpen ? '-20px 0 88px rgba(0,0,0,0.5)' : undefined}
        >
          <Screen offset={offset}>
            {menu === MenuScreen.Main && (
              <Stack width="100%" direction="column" spacing="space.16" {...boxProps}>
                {filteredItems.map(({ title, slug, href, submenuScreen }) => (
                  <Fragment key={`main-menu-item-${slug}`}>
                    {href ? (
                      <MenuLink href={href}>{title}</MenuLink>
                    ) : (
                      <MenuLink width="full" arrow onClick={() => setSubmenu(submenuScreen)}>
                        {title}
                      </MenuLink>
                    )}
                    <Divider m={0} color="gray.1" />
                  </Fragment>
                ))}
              </Stack>
            )}

            {menu === MenuScreen.User && userAddress && <UserMenu />}
          </Screen>

          <Screen offset={offset}>
            {submenu === SubmenuScreen.Resources && (
              <>
                <BackButton onClick={() => setSubmenu(undefined)} mb="space.24">
                  {menuItems.resources.title}
                </BackButton>

                <Stack direction="column" width="100%" spacing="space.16">
                  {menuItems.resources?.links?.map(({ title, slug, href }) => (
                    <Fragment key={`main-menu-item-${slug}`}>
                      <MenuLink href={href}>{title}</MenuLink>
                      <Divider m={0} color="gray.1" />
                    </Fragment>
                  ))}
                </Stack>
              </>
            )}
          </Screen>
        </FullScreenSlider>
      </FullDeviceBox>
    </RemoveScroll>
  );
};

const Screen = forwardRef<{ offset: number; children: ReactNode }, 'div'>(
  ({ offset, children }, ref) => (
    <FullScreenSliderSlot
      ref={ref}
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyItems="start"
      sx={{ '--offset': `${rem(offset)}` }}
      px="space.28"
      pt="space.88"
      pb="space.40"
      overflow="auto"
    >
      {children}
    </FullScreenSliderSlot>
  ),
);
