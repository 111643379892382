import { ComponentStyleConfig } from '@chakra-ui/react';

export const table: ComponentStyleConfig = {
  baseStyle: {
    tr: {
      bg: 'gray.2',
      td: {
        transition: 'background 120ms ease-in-out',
      },
      _hover: {
        td: {
          bg: 'gray.3',
        },
      },
      'td:first-of-type': {
        borderTopLeftRadius: 'md',
        borderBottomLeftRadius: 'md',
      },
      'td:last-of-type': {
        borderTopRightRadius: 'md',
        borderBottomRightRadius: 'md',
      },
    },
    th: {
      color: 'gray.1',
      letterSpacing: 'normal',
    },
  },
};
