import { useTokenSelection } from '@/components/features/Account/AccountNFTList/useTokenSelection';
import supportedChains, { DefaultChain } from '@/utils/chains';
import { getSupportedChainById } from '@/utils/getChain';
import { isBrowser } from '@onbeam/utils';
import { useRouter } from 'next/router';
import { createContext, useCallback, useEffect, useState, type FC } from 'react';

const supportedChainsMap = supportedChains.reduce((map, chain) => {
  map[chain.id] = chain;
  return map;
}, {} as Record<string, (typeof supportedChains)[0]>);

export const ChainContext = createContext<{
  chain: typeof DefaultChain;
  switchCurrentChain: (chainId: string | number) => void;
}>({
  chain: DefaultChain,
  switchCurrentChain: () => {
    /* noop */
  },
});

export const CHAIN_STORAGE_KEY = 'reservoir.chainId';

const ChainContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [globalChainId, setGlobalChainId] = useState(DefaultChain.id);
  const router = useRouter();

  useEffect(() => {
    const savedChainId = Number(localStorage.getItem(CHAIN_STORAGE_KEY) ?? DefaultChain.id);
    let selectedChain: (typeof supportedChains)[0] | undefined;

    if (router.query.chain) {
      selectedChain = supportedChains.find(chain => chain.routePrefix === router.query.chain);
    }
    if (!selectedChain) {
      selectedChain = getSupportedChainById(+savedChainId);
    }
    const id = selectedChain?.id ?? DefaultChain.id;
    setGlobalChainId(id);
    localStorage.setItem(CHAIN_STORAGE_KEY, `${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const switchCurrentChain = useCallback(
    (chainId: string | number) => {
      if (chainId === globalChainId) {
        return;
      }

      setGlobalChainId(+chainId);

      if (isBrowser) {
        localStorage.setItem(CHAIN_STORAGE_KEY, `${chainId}`);
        useTokenSelection.getState().reset();
      }
    },
    [globalChainId, setGlobalChainId],
  );

  let currentChain = DefaultChain;
  if (globalChainId && supportedChainsMap[globalChainId]) {
    currentChain = supportedChainsMap[globalChainId]!;
  }

  return (
    <ChainContext.Provider value={{ chain: currentChain, switchCurrentChain }}>
      {children}
    </ChainContext.Provider>
  );
};

export default ChainContextProvider;
