export const extractAttributeKeyFromQuery = (inputString: string) => {
  const regex = /attributes\[(.*?)\]/;
  const match = inputString.match(regex);

  if (match && match.length >= 2) {
    return match[1];
  }

  return undefined;
};

export const isMac = () => {
  if (typeof navigator === 'undefined' || !navigator) return false;
  return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
};
