import { ChakraTheme } from '@chakra-ui/react';
import { rem } from 'polished';

// This creates an  "n"=nPx  spacing token system (where "space.16" means 16px):
//
//   "space.4"  = 4px
//   "space.8"  = 8px
//   "space.16" = 16px
//     (etc.)
//
// Several advantages over  "lg"=nPx  and "x"=yPx  approaches:
//
// • Easy to standardize non-standard values.
//     When the design-team starts using a non-standard value (this happened
//     with ←28px→ ), then we don’t have to invent a value between "xl" and
//     "2xl" (1.5xl?). Neither do we have to shift all "2xl" to "3xl" everywhere
//     in our code to make space. Also we don’t have to argue with the design-
//     team and impose our OCD onto them.
//
// • Easier to read/use
//     When implementing a design with 16px spacing, you don’t have to look up
//     whether that means "md" or "lg". Neither do you have to reverse-calculate
//     to deduce that 24px corresponds to "spacing-6".
//
// You are encouraged to use the "n"=nPx spacing values as much as possible, as
// they will ensure consistent spacing throughout the application

// ADD PIXEL-VALUES HERE:
const spacingValues = [
  2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 24, 28, 30, 32, 34, 36, 40, 48, 56, 64, 80, 88, 96, 104, 112,
  120, 128, 132, 160,
];

const spacingMap: ChakraTheme['space'] = spacingValues.reduce(
  (map, px) => ({ ...map, [`space.${px}`]: rem(px), [`space.-${px}`]: rem(-px) }),
  {},
);

export const space: ChakraTheme['space'] = {
  ...spacingMap,

  gutter: spacingMap['space.24'],
  padding: spacingMap['space.16'],

  // Deprecated but here for backward-compatability:
  '2xs': spacingMap['space.4'],
  xs: spacingMap['space.8'],
  sm: spacingMap['space.12'],
  md: spacingMap['space.16'],
  lg: spacingMap['space.20'],
  xl: spacingMap['space.24'],
  '2xl': spacingMap['space.32'],
  '3xl': spacingMap['space.40'],
  '4xl': spacingMap['space.80'],
  '5xl': spacingMap['space.88'],
  '6xl': spacingMap['space.104'],
};

export const sizes: ChakraTheme['sizes'] = {
  container: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1440px',
    '3xl': '1920px',
  },

  // Minor adjustment to the base '2xl' size, which is used by the buy and sell modals
  '2xl': '43rem',
};
