import { useMenuItems } from '@/components/features/layout/Header/Menu/menuItems';
import { ContextMenu } from '@/components/shared/ContextMenu';
import { useAuthentication } from '@/hooks/useAuthentication';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  type BoxProps,
  type ButtonProps,
} from '@chakra-ui/react';
import { UserRole } from '@prisma/client';
import { rem } from 'polished';
import { MenuLink } from '../../Components/MenuLink';

type Props = BoxProps & {
  linkProps?: ButtonProps & {
    activeLinkColor?: string;
  };
};

export const MainNavigation = ({ linkProps, ...boxProps }: Props) => {
  const { user } = useAuthentication();
  const menuItems = useMenuItems();

  const filteredItems = Object.values(menuItems).filter(
    ({ isCreator, isAdmin }) =>
      (!isCreator && !isAdmin) ||
      (isCreator && user?.role === UserRole.CREATOR) ||
      (isAdmin && user?.role === UserRole.ADMIN),
  );

  return (
    <Stack
      width={{ base: '100%', md: 'auto' }}
      direction={{ base: 'column', md: 'row' }}
      spacing={{ md: 'space.24' }}
      display="block"
      {...boxProps}
    >
      {filteredItems.map(item =>
        item.href ? (
          <MenuLink
            {...linkProps}
            key={`main-menu-item-${item.title}`}
            arrow
            href={item.href}
            data-testid={`nav-${item.slug.toLocaleLowerCase()}-button`}
          >
            {item.title}
          </MenuLink>
        ) : (
          <Popover key={`main-menu-item-${item.title}`} closeOnBlur closeOnEsc placement="bottom">
            <PopoverTrigger>
              <MenuLink
                activeLinkColor={linkProps?.activeLinkColor}
                arrow
                data-testid={`nav-${item.slug.toLocaleLowerCase()}-button`}
              >
                {item.title}
              </MenuLink>
            </PopoverTrigger>

            {Array.isArray(item.links) && (
              <PopoverContent width={rem(200)} border="none">
                <ContextMenu zIndex="header.inFront">
                  <Stack direction="column" alignItems="flex-start" spacing={4}>
                    {item.links.map(({ title, href, target }) => (
                      <MenuLink
                        activeLinkColor="white"
                        key={`${title}-${href}`}
                        href={href}
                        target={target}
                      >
                        {title}
                      </MenuLink>
                    ))}
                  </Stack>
                </ContextMenu>
              </PopoverContent>
            )}
          </Popover>
        ),
      )}
    </Stack>
  );
};
