import { FooterLinkColumn } from '@/components/features/layout/Footer/useFooterLinksListTranslation';
import { Heading, List, ListItem } from '@chakra-ui/react';
import { NavLink } from '@sphere/ui/components/Link';
import { ReactNode } from 'react';

const FooterHeading = ({ children }: { children: ReactNode }) => (
  <Heading
    as="h4"
    fontSize={{
      base: 'sm',
      md: 'md',
    }}
    fontWeight="medium"
    mb="xs"
    color="white"
    lineHeight={2}
  >
    {children}
  </Heading>
);

export function FooterLinksList({ title, links }: FooterLinkColumn) {
  return (
    <>
      <FooterHeading>{title}</FooterHeading>
      <List
        spacing={{ base: 'space.8', md: 'space.12' }}
        marginTop={{ base: 'space.8', md: 'space.24' }}
      >
        {links.map(({ text, href, target }) => (
          <ListItem key={href}>
            <NavLink
              href={href}
              passHref
              target={target}
              fontSize="sm"
              prefetch={false}
              width={{ base: 'full', md: 'max-content' }}
              hoverLinkColor="gray.5"
            >
              <>{text}</>
            </NavLink>
          </ListItem>
        ))}
      </List>
    </>
  );
}
