import { ChakraComponent, HTMLChakraProps, ThemeTypings, chakra } from '@chakra-ui/react';
import { theme } from '@sphere/ui';
import NextImage, { ImageProps as NextImageProps } from 'next/image';

export type CombinedImageProps = Omit<NextImageProps, 'objectFit'> &
  Omit<HTMLChakraProps<'img'>, keyof NextImageProps>;

export type ObjectFit = 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';

export const nextImageProps: (keyof NextImageProps)[] = [
  'src',
  'alt',
  'sizes',
  'width',
  'height',
  'fill',
  'loader',
  'quality',
  'priority',
  'loading',
  'placeholder',
  'blurDataURL',
  'unoptimized',
  'onLoadingComplete',
  'alt',
  'crossOrigin',
  'decoding',
  'loading',
  'referrerPolicy',
  'sizes',
  'src',
  'useMap',
];

export const ChakraNextImage: ChakraComponent<'img', NextImageProps> = chakra(NextImage, {
  shouldForwardProp: prop => (nextImageProps as string[]).includes(prop),
});

const breakpoints = theme.breakpoints;

/**
 * Converts Chakra's breakpoints into a value for the html `srcset` property
 * @param sizes For example `{ base: '100vw', md: '50vw' }`
 */
export const getImageSrcSet = (
  sizes: { [key in ThemeTypings['breakpoints']]?: string } & { base: string },
) => {
  if (!breakpoints) return '';
  return Object.keys(breakpoints).reduce((fullQuery, breakpoint) => {
    if (breakpoint !== 'base' && breakpoint in sizes) {
      fullQuery = `(min-width: ${breakpoints[breakpoint]}) ${sizes[breakpoint]}, ${fullQuery}`;
    }
    return fullQuery;
  }, sizes.base);
};
