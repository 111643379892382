import { Image } from '@/components/shared/Image';
import { useENSResolver } from '@/hooks/useENSResolver';
import { type SearchGameData } from '@/pages/api/globalSearch';
import { routes } from '@/utils/routes';
import { Flex, Text } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { rem } from 'polished';
import { SearchHitBase } from './SearchHitBase';

type Props = {
  game: SearchGameData;
  focusIndex: number;
};

export const SearchHitGame = ({ game, focusIndex }: Props) => {
  const { t } = useTranslation('search');
  const { displayName } = useENSResolver(game.user.address);

  return (
    <SearchHitBase
      focusIndex={focusIndex}
      href={routes.games.detail(game.slug, game.chainRoutePrefix)}
      leftRender={
        <Image
          src={game.avatar?.src}
          alt={`${game.name} logo`}
          width={56}
          height={56}
          boxSize={rem(56)}
          objectFit="cover"
          rounded="full"
        />
      }
      rightRender={
        <Flex justifyContent="space-between" alignItems="center" gap="space.4">
          <div>
            <Text fontSize="md" noOfLines={1}>
              {game.name}
            </Text>
            <Text fontSize="sm" color="gray.5" noOfLines={1}>
              {displayName}
            </Text>
          </div>
          <Flex flexDir="column" alignItems="flex-end" gap="space.2">
            <Text fontSize="xs" color="gray.5" textAlign="right">
              {t('results.games.labels.collections')}
            </Text>
            {game.contracts.length}
          </Flex>
        </Flex>
      }
    />
  );
};
