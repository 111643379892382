const sm = {
  px: 'space.16',
  py: 'space.6',

  fontSize: 'sm',
};

const md = {
  px: 'space.32',

  fontSize: { base: 'sm', md: 'md' },
};

export const sizes = { sm, md };
