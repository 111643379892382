import { paths, setParams } from '@sphere/reservoir-sdk';
import { useMemo } from 'react';
import { SWRInfiniteConfiguration } from 'swr/infinite';
import { useInfiniteApi, useReservoirClient } from './';

type TokenDetailsResponse = paths['/tokens/v6']['get']['responses']['200']['schema'];

export type TokensQuery = paths['/tokens/v6']['get']['parameters']['query'];

export default function (
  options?: TokensQuery | false,
  swrOptions: SWRInfiniteConfiguration = {},
  chainId?: number,
) {
  const client = useReservoirClient();
  const chain =
    chainId !== undefined
      ? client?.chains.find(chain => chain.id === chainId)
      : client?.currentChain();

  const response = useInfiniteApi<TokenDetailsResponse>(
    (pageIndex, previousPageData) => {
      if (!options) {
        return null;
      }

      const url = new URL(`${chain?.baseApiUrl}/tokens/v6`);
      let query: TokensQuery = { ...options };

      if (previousPageData && !previousPageData.continuation) {
        return null;
      } else if (previousPageData && pageIndex > 0) {
        query.continuation = previousPageData.continuation;
      }

      if (query.normalizeRoyalties === undefined && client?.normalizeRoyalties !== undefined) {
        query.normalizeRoyalties = client.normalizeRoyalties;
      }

      setParams(url, query);
      return [url.href, client?.apiKey, client?.version];
    },
    {
      revalidateOnMount: true,
      revalidateFirstPage: false,
      ...swrOptions,
    },
  );

  const tokens = useMemo(
    () => response.data?.flatMap(page => page.tokens || []) ?? [],
    [response.data],
  );

  return {
    ...response,
    data: tokens,
  };
}
