import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuList,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@sphere/icons';
import { rem } from 'polished';
import { ReactElement } from 'react';

type Option = {
  label: string | JSX.Element;
  value: string;
  icon?: ReactElement;
};

type Props = Omit<MenuButtonProps, 'onChange'> & {
  label?: string;
  options: Option[];
  testId?: string;
  value?: string;
  onChange: (value: string) => void;
  displayMobile?: boolean;
};

export const MinimalSelect = ({
  onChange,
  label: prefix,
  options,
  disabled,
  testId,
  value,
  displayMobile,
  ...props
}: Props) => {
  const theme = useTheme();
  if (!options.length) return null;

  const selectedOption = options.find(item => item.value === value) || options?.[0];

  return (
    <HStack
      justifyContent="flex-end"
      alignItems="center"
      width="fit-content"
      display={{ base: displayMobile ? 'flex' : 'none', md: 'flex' }}
      data-testid={`${testId}-container`}
      fontSize="md"
      spacing="space.12"
      position="relative"
    >
      {prefix && (
        <Text
          as="span"
          color={disabled ? 'gray.5' : 'white'}
          lineHeight={1}
          data-testid={`${testId}-label`}
        >
          {prefix}
        </Text>
      )}

      <Menu computePositionOnMount isLazy id="minimalSelect">
        <MenuButton
          padding={0}
          as={Button}
          variant="clear"
          rightIcon={<ChevronDownIcon boxSize={rem(14)} />}
          isDisabled={disabled}
          fontWeight="500"
          fontSize="md"
          leftIcon={selectedOption.icon}
          _hover={{ color: 'gray.5' }}
          {...props}
        >
          {selectedOption.label}
        </MenuButton>
        <MenuList bg="gray.2" zIndex={theme.zIndices.menu}>
          {options.map(item => (
            <MenuItem
              key={`item-${item.label}`}
              bg={item.value === value ? 'gray.3' : 'gray.2'}
              color="white"
              icon={item.icon}
              onClick={() => onChange(item.value)}
              _hover={{ bg: 'gray.4' }}
              _focus={{ bg: 'gray.4' }}
            >
              {item.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </HStack>
  );
};
