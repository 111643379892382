import { ChakraTheme } from '@chakra-ui/react';

export const homeTextStyles: ChakraTheme['textStyles'] = {
  'home-about-heading': {
    fontSize: {
      base: 28,
      md: '4xl',
    },
    lineHeight: 1,
    fontWeight: 'normal',
    mb: {
      base: 'space.16',
      md: 'space.20',
    },
  },
  'home-about-body': {
    fontSize: {
      base: 'lg',
      md: 'xl',
    },
    lineHeight: 1.2,
    color: 'gray.5',
  },
};
