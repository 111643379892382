import { Flex, FlexProps } from '@chakra-ui/react';
import { UIEvent } from 'react';
import { colors } from '../../theme/colors';
import { useHasScroll } from './useHasScroll';

export const scrollStyles = {
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: colors.gray[4],
    borderRadius: '20px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: colors.gray[5],
  },
};

export const paddedScrollStyles = {
  '&::-webkit-scrollbar': {
    width: '14px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    border: '4px solid transparent',
    backgroundColor: colors.gray[4],
    borderRadius: '20px',
    backgroundClip: 'padding-box',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: colors.gray[5],
  },
};

type Props = {
  onBottomReached?(): void;
} & FlexProps;

export const ScrollContainer = ({ onBottomReached, children, ...props }: Props) => {
  const [ref, hasScroll] = useHasScroll();

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const isAtBottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop <=
      e.currentTarget.clientHeight * 1.5;

    if (!isAtBottom) return;

    onBottomReached?.();
  };

  return (
    <Flex
      ref={ref}
      flexDir="column"
      overflowY="auto"
      onScroll={e => {
        if (!onBottomReached) return;
        handleScroll(e);
      }}
      css={scrollStyles}
      {...(hasScroll && { pr: { md: 'space.6' } })}
      {...props}
    >
      {children}
    </Flex>
  );
};
