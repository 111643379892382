import { useStatusBanner } from '@/components/features/layout/StatusBanner/useStatusBanner';
import { useMounted } from '@/hooks/use-mounted';
import { Box, Container, IconButton } from '@chakra-ui/react';
import { CloseNormalIcon } from '@sphere/icons';
import { rem } from 'polished';
import { ReactNode, useEffect } from 'react';
import { useLocalStorage, useMeasure } from 'react-use';
import { Status, StatusIcon, statusColorMap } from './StatusBanner.utils';

type Config = {
  status: Status;
  publishedAt: string;
  render?: () => ReactNode;
};

const config: Config = {
  status: Status.Info, // Change this to show a different style of status bar
  publishedAt: '2024-11-19T00:00:00.000Z', // Change this so people can click the status bar away
  render: undefined, // Change this property to the function you see below to render the status bar
  // render: () => {
  //   return (
  //     // eslint-disable-next-line i18next/no-literal-string
  //     <Text fontSize={{ base: 'sm', md: 'md' }} lineHeight="md">
  //       Some text
  //     </Text>
  //   );
  // },
};

export const StatusBanner = () => {
  const [containerRef, { height }] = useMeasure<HTMLDivElement>();
  const [setBanner] = useStatusBanner(state => [state.set]);
  const isMounted = useMounted();

  const [dismissedTimestamp, setDismissedTimestamp] = useLocalStorage(
    'status-banner-dismissed-timestamp',
  );

  const updatedAt = new Date(config.publishedAt).getTime().toString();

  useEffect(() => {
    if (!isMounted) return;
    setBanner({ isVisible: Boolean(config.status && config.render), height });
  }, [setBanner, isMounted, height]);

  if (!isMounted || !config.status || !config.render || dismissedTimestamp === updatedAt) {
    return null;
  }

  const status = (config.status in Status ? config.status : Status.Error) as Status;

  const onDismiss = () => setDismissedTimestamp(updatedAt);

  return (
    <Box
      ref={containerRef}
      {...statusColorMap[status]}
      zIndex="header.menu"
      suppressHydrationWarning
    >
      <Container
        display="flex"
        py="space.16"
        alignItems="center"
        gap={{ base: 'space.10', md: 'space.16' }}
        variant="breakout"
      >
        <StatusIcon status={status} />
        {config.render?.()}
        <IconButton
          ml="auto"
          aria-label="Dismiss"
          onClick={onDismiss}
          color="inherit"
          variant="clear"
          icon={<CloseNormalIcon boxSize={rem(14)} />}
          boxSize={rem(32)}
          minW={rem(32)}
          _hover={{ bg: 'gray.4' }}
          isRound
        />
      </Container>
    </Box>
  );
};
