import SearchBar from '@/components/features/Search/SearchBar';
import { Box, BoxProps } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import {
  useCallback,
  type ChangeEvent,
  type FC,
  type FormEvent,
  type KeyboardEvent,
  type RefObject,
} from 'react';
import { useFocus } from './useFocus';

export type SearchBarFormProps = BoxProps & {
  inputRef: RefObject<HTMLInputElement>;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: FormEvent) => void;
  onReset: () => void;
  value: string;
  variant?: 'opaque' | 'translucent';
  withShortcut?: boolean;
};

const SearchBarForm: FC<SearchBarFormProps> = ({
  inputRef,
  onChange,
  onReset,
  onSubmit,
  value,
  variant,
  withShortcut,
  ...boxProps
}) => {
  const { t } = useTranslation('common');
  const { totalItems, setFocusedIndex } = useFocus();

  /** Submit handling */
  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      event.stopPropagation();

      if (onSubmit) {
        onSubmit(event);
      }

      if (inputRef.current) {
        inputRef.current.blur();
      }
    },
    [inputRef, onSubmit],
  );

  /** Reset handler */
  const handleReset = useCallback(
    (event?: FormEvent) => {
      event?.preventDefault();
      event?.stopPropagation();

      onReset();

      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
    [inputRef, onReset],
  );

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setFocusedIndex(() => 1);
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      setFocusedIndex(() => totalItems);
    }
  };

  return (
    <Box
      data-testid="autocomplete-form"
      as="form"
      pos="relative"
      zIndex="modal"
      noValidate
      onSubmit={handleSubmit}
      onReset={handleReset}
      {...boxProps}
    >
      <SearchBar
        ref={inputRef}
        testId="autocomplete"
        onChange={onChange}
        placeholder={t('autocomplete.input.placeholder')}
        value={value}
        onReset={handleReset}
        variant={variant === 'translucent' ? 'round-translucent' : 'round'}
        onKeyDown={handleKeyDown}
        withShortcut={withShortcut}
        borderColor="transparent"
      />
    </Box>
  );
};

export default SearchBarForm;
