import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { routes } from '@/utils/routes';
import useTranslation from 'next-translate/useTranslation';
import { HTMLAttributeAnchorTarget } from 'react';

export type FooterLinkColumn = {
  title: string;
  links: {
    text: string;
    href: string;
    target?: HTMLAttributeAnchorTarget;
    rel?: string;
  }[];
};

const getFooterLinks = (routePrefix: string): FooterLinkColumn[] => [
  {
    title: 'footer.marketplace',
    links: [
      {
        text: 'footer.search',
        href: `/search?chain=${routePrefix}`,
      },
      {
        text: 'footer.collections',
        href: routes.collections.overview(routePrefix),
      },
      {
        text: 'footer.insights',
        href: routes.insights(routePrefix),
      },
    ],
  },
  {
    title: 'footer.support',
    links: [
      {
        text: 'zendesk.categories.docs.title',
        href: routes.external.docs,
        target: '_blank',
        rel: 'noopener noreferrer',
      },
      {
        text: 'zendesk.categories.help-center.title',
        href: routes.external.helpCenter,
        target: '_blank',
        rel: 'noopener noreferrer',
      },
      {
        text: 'zendesk.categories.more.title',
        href: routes.support,
      },
    ],
  },
  {
    title: 'footer.company',
    links: [
      {
        text: 'footer.about-sphere',
        href: routes.article.about,
      },
      {
        text: 'footer.transparency',
        href: routes.article.fees,
      },
    ],
  },
];

export function useFooterLinksListTranslation() {
  const { t } = useTranslation('common');
  const marketplaceChain = useMarketplaceChain();

  const translatedFooterLinks = getFooterLinks(marketplaceChain.routePrefix).map(footerLink => ({
    title: t(footerLink.title),
    links: footerLink.links.map(link => ({
      text: t(link.text),
      href: link.href.includes('zendesk') ? t(link.href) : link.href,
      target: link.target,
    })),
  }));

  return { translatedFooterLinks };
}
