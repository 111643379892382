import { ChakraTheme } from '@chakra-ui/react';

export const shadows: ChakraTheme['shadows'] = {
  header: `0 20px 44px rgba(0, 0, 0, 0.1)`,
  autocomplete: '0px 20px 44px rgba(0, 0, 0, 0.1)',
  tooltip: '0px 4px 20px rgba(0, 0, 0, 0.08)',
  card: '0px 10px 40px rgba(0, 0, 0, 0.1)',
  content: '-33px 33px 40px 10px rgba(0, 0, 0, 0.8)',
  outline: '0 0 0 3px rgba(66, 153, 225, 0.6)',
  'outline-inset': 'inset 0 0 0 3px rgba(66, 153, 225, 0.6)',
  sidebar: '32px 0 32px 0 rgba(0, 0, 0, 0.05)',
  sidebarLarge: '0 0 64px 0 rgba(0, 0, 0, 0.05)',

  sticky: {
    'table-header': '0 8px 6px -6px rgba(0, 0, 0, 0.07)',
    'action-bar': '0px 10px 10px rgba(0, 0, 0, 0.07)',
  },

  /** Sweeping/selection components */
  'selection-footer': '0px -3px 4px rgba(0, 0, 0, 0.05)',
  'selection-slider': '0px 4px 8px rgba(0, 0, 0, 0.16)',
  'selection-item': '0px 0px 20px rgba(0, 0, 0, 0.14)',
};
