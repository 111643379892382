import { useCollectionActivity } from '@/hooks/use-collection-activity';
import { useUserActivity } from '@/hooks/use-user-activity';
import { GetUsersActivityV6TypesItem } from '@/lib/reservoir/reservoir.generated';
import { kebab } from 'case';
import { Translate } from 'next-translate';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';

export type CollectionActivityResponse = ReturnType<typeof useCollectionActivity>;
export type CollectionActivity = CollectionActivityResponse['data'][number];

export type UsersActivityResponse = ReturnType<typeof useUserActivity>;
export type UsersActivity = UsersActivityResponse['data'][number];

export type ActivityResponse = CollectionActivityResponse | UsersActivityResponse;
export type ActivityData = CollectionActivity | UsersActivity;

type EventColor = 'white' | 'red' | 'green' | 'gray.5';

export const Activity = GetUsersActivityV6TypesItem;
export type ActivityType = keyof typeof Activity;

export type ActivityTypeFilterType = Exclude<ActivityType, 'bid_cancel' | 'ask_cancel'>;
export const ActivityTypeFilters: { [key in ActivityTypeFilterType]: ActivityTypeFilterType } = {
  sale: 'sale',
  ask: 'ask',
  transfer: 'transfer',
  mint: 'mint',
  bid: 'bid',
} as const;

const colorMap: {
  [key in ActivityType]: EventColor;
} = {
  [Activity.ask]: 'white',
  [Activity.mint]: 'white',
  [Activity.bid]: 'white',
  [Activity.bid_cancel]: 'gray.5',
  [Activity.sale]: 'white',
  [Activity.ask_cancel]: 'gray.5',
  [Activity.transfer]: 'white',
};

export const getActivityEventColor = (event: ActivityType) => colorMap[event];

export const getActivityEventTitle = (event: ActivityType, t: Translate) =>
  t(`event-titles.${kebab(event)}`);

export const formatSelectedFilters = (selected: ActivityTypeFilterType[]): ActivityType[] => {
  const filters = selected.flatMap(t => {
    switch (t) {
      case ActivityTypeFilters.ask:
        return [Activity.ask, Activity.ask_cancel];
      case ActivityTypeFilters.bid:
        return [Activity.bid, Activity.bid_cancel];
      default:
        return t;
    }
  });

  return filters;
};

export const allowedFilterType = (type: string): type is ActivityTypeFilterType =>
  Object.values(ActivityTypeFilters).includes(type as ActivityTypeFilterType);

export const useActivityFilters = () => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const selectedFilters = searchParams.getAll('activityFilters').filter(allowedFilterType) || [];

  const toggleFilter = (type: ActivityTypeFilterType) => {
    const newSelectedFilters = selectedFilters.includes(type)
      ? selectedFilters.filter(t => t !== type)
      : [...selectedFilters, type];

    router.replace({ query: { ...router.query, activityFilters: newSelectedFilters } }, undefined, {
      shallow: true,
    });
  };

  return { selectedFilters, toggleFilter };
};
