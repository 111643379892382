import { List } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { FooterLinkItem, FooterLinkItemProps } from './FooterLinkItem';

type Props = PropsWithChildren<{
  links: FooterLinkItemProps[];
}>;

export const FooterBottomLinks = ({ links, children }: Props) => {
  return (
    <List
      mt={{ base: 0, md: 'space.48' }}
      display="flex"
      justifyContent="center"
      flexDirection={{ base: 'column', md: 'row' }}
      gap={{ base: 'space.8', md: 'space.48' }}
    >
      {links.map(itemProps => (
        <FooterLinkItem key={itemProps.text} {...itemProps} />
      ))}
      {children}
    </List>
  );
};
