import { SystemStyleObject } from '@chakra-ui/react';

import { hamburgerButtonConfig as config } from '../HamburgerButton.config';
import { HamburgerStyleProps } from './styles';

if (config.numLines > 2)
  console.warn('HamburgerButton doesn’t support 3-line icons yet, feel free to implement');

const RATIO = 0.25;

const hamburgerIconStyles: SystemStyleObject = {
  position: 'relative', // for absolute positioning of ::before & ::after

  '::before, ::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    width: `${config.lineSize}px`,
    height: `${config.lineThickness}px`,
    top: '50%',
    left: '50%',
    marginLeft: `${-config.lineSize / 2}px`,
    background: 'currentColor', // text-color is icon-color
    rounded: 'full',
    transition: [
      `margin-top ${Math.round(config.animationDuration * RATIO)}ms ${Math.round(
        config.animationDuration * (1 - RATIO),
      )}ms`,
      `transform ${Math.round(config.animationDuration * (1 - RATIO))}ms`,
    ].join(','),
  },

  '::before': {
    marginTop:
      config.numLines > 2
        ? `${-config.lineThickness * 1.5 - config.lineSpacing}px`
        : `${-config.lineThickness * 0.5 - config.lineSpacing / 2}px`,
  },

  '::after': {
    marginTop:
      config.numLines > 2
        ? `${config.lineThickness * 0.5 + config.lineSpacing}px`
        : `${config.lineSpacing / 2}px`,
  },
};

const crossIconStyles: SystemStyleObject = {
  ...hamburgerIconStyles,

  // Notice how we don’t have a space after the comma in our selector, this is
  // so our selector doesn’t overwrite the one in hamburgerIconStyles
  '::before,::after': {
    marginTop: `${-config.lineThickness / 2}px`,

    transition: [
      `margin-top ${Math.round(config.animationDuration * RATIO)}ms`,
      `transform ${Math.round(config.animationDuration * (1 - RATIO))}ms ${Math.round(
        config.animationDuration * RATIO,
      )}ms`,
    ].join(','),
  },

  '::before': {
    transform: `rotate(${config.animateClockwise ? 45 : -45}deg)`,
  },

  '::after': {
    transform: `rotate(${config.animateClockwise ? -45 : 45}deg)`,
  },
};

export const getIconStyles = ({ active }: HamburgerStyleProps) =>
  active ? crossIconStyles : hamburgerIconStyles;
