import { AuthenticationProvider } from '@/context/AuthenticationProvider';
import IMXCheckoutProvider from '@/context/IMXCheckoutProvider';
import { WebsocketContextProvider } from '@/context/WebsocketContextProvider';
import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { reservoirKitConfig, reservoirKitTheme } from '@/lib/reservoir/reservoir';
import { wagmiConfig } from '@/lib/wagmi';
import { withNextLink } from '@/utils/next-link-wrapper';
import common from '@locales/en/common.json';
import search from '@locales/en/search.json';
import { CartProvider, ReservoirKitProvider } from '@sphere/reservoir-kit-ui';
import { theme } from '@sphere/ui';
import { ThemeProvider, TranslationProvider } from '@sphere/ui/context';
import { type Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { useRouter } from 'next/router';
import { type FC, type PropsWithChildren } from 'react';
import { Toaster } from 'sonner';
import { WagmiProvider } from 'wagmi';

const Providers: FC<PropsWithChildren<{ session: Session | null }>> = ({ session, children }) => {
  const { locale } = useRouter();
  const marketplaceChain = useMarketplaceChain();

  return (
    <ThemeProvider theme={theme} linkWrapper={withNextLink}>
      <TranslationProvider locale={locale} dictionary={{ common, search }}>
        <WagmiProvider config={wagmiConfig}>
          <SessionProvider session={session} refetchInterval={0}>
            <AuthenticationProvider>
              <ReservoirKitProvider
                options={reservoirKitConfig(marketplaceChain)}
                theme={reservoirKitTheme}
              >
                <IMXCheckoutProvider>
                  <CartProvider feesOnTopUsd={/* TODO referral fees */ undefined}>
                    <WebsocketContextProvider>
                      {children}
                      <Toaster invert />
                    </WebsocketContextProvider>
                  </CartProvider>
                </IMXCheckoutProvider>
              </ReservoirKitProvider>
            </AuthenticationProvider>
          </SessionProvider>
        </WagmiProvider>
      </TranslationProvider>
    </ThemeProvider>
  );
};

export default Providers;
