import { DefaultChain } from '@/utils/chains';
import { createContext, useContext, useRef } from 'react';
import { createStore, useStore } from 'zustand';

interface DevCompanionProps {
  chainId: number;
}

interface DevCompanionState extends DevCompanionProps {
  setChainId: (id: number) => void;
}

type DevCompanionStore = ReturnType<typeof createDevCompanionStore>;

type DevCompanionProviderProps = React.PropsWithChildren<DevCompanionProps>;

const createDevCompanionStore = (initProps?: Partial<DevCompanionProps>) => {
  const DEFAULT_PROPS: DevCompanionProps = {
    chainId: DefaultChain.id,
  };
  return createStore<DevCompanionState>()(set => ({
    ...DEFAULT_PROPS,
    ...initProps,
    setChainId: (chainId: number) => set(state => ({ ...state, chainId })),
  }));
};

export const DevCompanionContext = createContext<DevCompanionStore | null>(null);

export function useDevCompanion<T>(selector: (state: DevCompanionState) => T): T {
  const store = useContext(DevCompanionContext);
  if (!store) throw new Error('Missing DevCompanionProvider in the tree');
  return useStore(store, selector);
}

export function DevCompanionProvider({ children, ...props }: DevCompanionProviderProps) {
  const storeRef = useRef<DevCompanionStore>();
  if (!storeRef.current) {
    storeRef.current = createDevCompanionStore(props);
  }
  return (
    <DevCompanionContext.Provider value={storeRef.current}>{children}</DevCompanionContext.Provider>
  );
}
