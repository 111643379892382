import { rem } from 'polished';
import { keyboardFocussed } from '../../tools/keyboard-focussed';

export const primary = {
  height: 'auto',
  backgroundColor: 'primary',
  textColor: 'darkPurple',
  fontWeight: 'medium',
  lineHeight: { base: rem(38), md: rem(46) },
  textAlign: 'center',

  border: '1px solid transparent', // makes it easier for secondary to extend us
  rounded: 'full',

  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  transitionProperty: 'color background-color',
  transitionDuration: '233ms',

  ...keyboardFocussed({
    boxShadow: 'outline',
  }),

  _loading: {
    color: 'darkPurple',
    '&, &:disabled, &:disabled:hover': {
      backgroundColor: 'primaryDark',
    },
  },

  _hover: {
    backgroundColor: 'primaryLight',
    transitionDuration: '59ms',
    '&:disabled': {
      backgroundColor: 'gray.4',
    },
  },

  _active: {
    backgroundColor: 'primaryDark',
    transitionDuration: '0s',
  },

  _disabled: {
    opacity: 1,
    backgroundColor: 'gray.4',
    textColor: 'gray.5',
    borderColor: 'border',
  },
};
