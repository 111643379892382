import { env } from '@/env.mjs';
import { ChainId, ClientConfig } from '@onbeam/sdk';

/**
 * DO NOT re-use these API keys for the next 17 frontend projects you make. If you start a new project
 * please create an API key on dashboard(.testnet).onbeam.com on the development@meritcircle.io account
 */

const isPreview = ['localhost', 'preview'].some(d => env.NEXT_PUBLIC_HOST_URL.includes(d));

const config: ClientConfig = {
  chains: [
    {
      id: ChainId.BEAM_MAINNET,
      publishableKey: 'cMssmnsMfjHidhzW1dNOmlwruIyTc58o', // mainnet key managed through https://dashboard.onbeam.com
      sponsor: true,
    },
    {
      id: ChainId.BEAM_TESTNET,
      publishableKey: isPreview
        ? 'pcpWN6pm3PQm7Hm6witdlbmngxEKpuXB' // preview key managed through https://dashboard.preview.onbeam.com
        : '17ytYZDqU3RakMQmfj33BEGveCqKt1SZ', // testnet key managed through https://dashboard.testnet.onbeam.com
      isPreview,
      sponsor: true,
    },
    {
      id: ChainId.SOPHON_TESTNET,
      publishableKey: isPreview
        ? 'pcpWN6pm3PQm7Hm6witdlbmngxEKpuXB' // preview key managed through https://dashboard.preview.onbeam.com
        : '17ytYZDqU3RakMQmfj33BEGveCqKt1SZ', // testnet key managed through https://dashboard.testnet.onbeam.com
      isPreview,
      sponsor: true,
    },
  ],
  debug: isPreview,
};

export { config };
