import { getChainCurrencies } from '@/utils/getChainCurrencies';
import { Address } from 'viem';
import chains from './chains';

export type PaymentToken = {
  chainId: number;
  address: Address;
  symbol: string;
  decimals: number;
  name?: string;
};

export const chainPaymentTokensMap = chains.reduce<Record<number, PaymentToken[]>>(
  (acc, chain) => ({
    ...acc,
    [chain.id]: getChainCurrencies({ chainId: chain.id, includeAll: true }).map(currency => ({
      chainId: chain.id,
      address: currency.contract as Address,
      symbol: currency.symbol,
      decimals: currency.decimals ?? 18,
    })),
  }),
  {} as Record<number, PaymentToken[]>,
);
