import { rem } from 'polished';

import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

import { size as inputSize } from './input';

const sizes = {
  md: defineStyle({
    py: rem(16),
    px: inputSize.md.px,
  }),
};

const variants = {
  outline: defineStyle({
    borderColor: 'gray.4',

    _placeholder: {
      color: 'gray.5',
      textOverflow: 'ellipsis',
      fontFamily: 'placeholder',
    },

    _hover: {
      borderColor: 'gray.5',
    },

    _focus: {
      borderColor: 'gray.5',
    },

    _invalid: {
      borderColor: 'red',
    },
  }),
};

export const textarea = defineStyleConfig({
  sizes,
  variants,
});
