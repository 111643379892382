import { chakra } from '@chakra-ui/react';

const SVGIcon = ({ ...props }) => (
  <svg
    width="305"
    height="305"
    viewBox="0 0 305 305"
    fill="#EBBCFF"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M18.3772 227.028C16.2428 222.98 14.188 218.89 12.214 214.761C-4.33907 177.629 -4.00848 125.484 12.8516 88.7236C15.1126 84.178 18.2414 78.1978 20.7385 73.788C27.4743 63.3508 28.5133 61.7451 35.1428 53.6397C31.8835 57.572 28.9328 61.7503 26.3172 66.1372C-1.31063 112.201 -2.20205 188.019 24.3219 235.488C22.0536 232.879 20.0615 230.048 18.3772 227.028ZM75.3685 284.887C63.1308 277.195 62.1272 276.564 54.4882 270.371C46.6367 263.854 39.3224 254.869 32.9231 243.434C37.2208 246.622 41.8786 249.278 46.8079 251.344C56.9736 269.527 69.2762 281.989 82.4054 288.713L75.3744 284.899" />
    <path d="M32.9007 243.419C3.85597 191.457 4.85364 108.467 35.0849 58.0761C41.2244 47.8455 48.096 39.746 55.3926 33.7541C59.0232 31.0267 63.8521 27.3666 67.6067 24.7928C72.8482 21.5837 78.1709 18.509 83.5695 15.5717C71.0661 21.8293 59.1885 33.335 49.1409 50.0593C16.2708 104.796 15.2318 194.881 46.7618 251.294C41.8325 249.228 37.1747 246.571 32.877 243.384M108.665 299.141L106.221 298.409C89.1659 293.078 72.9079 278.822 59.9795 255.692C66.3847 257.227 72.9079 258.207 79.4843 258.603C95.5593 287.376 116.652 302.784 138.129 304.708C124.445 302.949 119.846 302.353 108.665 299.141Z" />
    <path d="M59.9567 255.686C27.0571 196.841 28.167 102.859 62.4303 45.7611C75.3233 24.2728 91.0794 11.0905 107.461 6.11392C112.343 4.84469 118.849 3.15632 123.814 2.15275C128.501 1.45614 134.782 0.517505 139.517 0.0688477C118.737 1.73951 98.1812 15.9962 82.0059 42.9511C46.8099 101.595 45.6823 198.139 79.4675 258.602C72.891 258.203 66.3624 257.227 59.9567 255.686ZM181.514 302.435C176.301 303.249 169.335 304.324 164.081 304.837C139.664 306.785 114.953 291.342 96.6936 258.685C104.37 258.264 112.013 257.357 119.575 255.969C140.095 292.665 168.975 307.022 196.136 298.798C191.756 299.938 185.976 301.644 181.514 302.435Z" />
    <path d="M96.7153 258.683C62.9243 198.233 64.0636 101.683 99.2596 43.0326C117.696 12.2936 141.841 -1.92769 165.496 0.20934C180.343 2.12204 182.627 2.4054 194.977 5.77034L197.421 6.50826C171.092 -1.69746 142.874 11.3904 122.082 46.0433C87.8189 103.129 86.7149 197.111 119.603 255.962C112.035 257.349 104.39 258.264 96.7153 258.689V258.683ZM237.57 279.097C231.851 282.68 226.024 286.086 220.096 289.31C191.683 303.543 159.852 290.904 137.968 251.753C145.852 249.575 153.62 246.994 161.239 244.019C184.381 285.426 219.624 294.623 248.279 271.14C245.091 273.548 240.917 276.895 237.57 279.097Z" />
    <path d="M137.965 251.771C106.435 195.358 107.498 105.254 140.332 50.5298C162.588 13.4447 193.734 2.12195 221.303 16.2311L228.34 20.0447C240.572 27.7309 241.564 28.3566 249.209 34.5611C221.433 11.4906 187.011 19.3894 163.415 58.6883C133.172 109.091 132.186 192.064 161.23 244.037C153.609 247.012 145.846 249.586 137.965 251.771ZM282.864 231.097C276.11 241.546 275.071 243.152 268.371 251.351C240.885 284.074 203.038 278.802 179.212 236.162C186.774 232.545 194.162 228.574 201.35 224.261C224.078 264.917 261.683 265.903 285.355 226.457C287.346 223.127 289.151 219.69 290.763 216.161C288.508 220.695 285.503 226.752 282.882 231.091" />
    <path d="M179.21 236.174C152.674 188.711 153.566 112.888 181.206 66.8296C205.516 26.3264 242.412 22.3416 269.007 54.2672L272.431 58.5176C279.851 68.7482 280.636 69.8167 285.241 77.863C287.142 81.5172 289.669 86.3934 291.398 90.1303C290.064 87.1373 288.618 84.2269 287.065 81.4523C264.337 40.7956 226.727 39.8098 203.06 79.2503C179.405 118.697 178.626 183.622 201.36 224.285C194.172 228.598 186.784 232.569 179.222 236.186" />
    <path d="M217.849 213.508C198.569 179.003 199.212 123.895 219.313 90.4226C239.414 56.9444 271.31 57.7768 290.603 92.2821C309.889 126.782 309.24 181.896 289.139 215.38C269.044 248.858 237.136 248.014 217.849 213.508C224.585 208.753 231.087 203.674 237.331 198.289C251.735 224.117 275.644 224.731 290.668 199.694C305.698 174.658 306.194 133.429 291.754 107.596C277.314 81.7623 253.441 81.1543 238.411 106.196C223.381 131.239 222.897 172.474 237.331 198.289C232.962 202.068 228.461 205.691 223.835 209.152C221.87 210.627 219.874 212.086 217.849 213.508Z" />
    <path d="M251.167 185.291C240.883 166.896 241.22 137.533 251.934 119.687C262.649 101.841 279.669 102.283 289.929 120.684C300.195 139.079 299.876 168.443 289.161 186.283C278.441 204.123 261.433 203.686 251.167 185.291C256.615 179.714 261.72 173.814 266.457 167.622C271.097 175.946 278.801 176.147 283.636 168.071C288.476 160.001 288.654 146.712 283.99 138.389C279.332 130.065 271.64 129.87 266.799 137.94C261.958 146.004 261.799 159.298 266.451 167.622C261.714 173.814 256.609 179.714 251.161 185.291H251.167Z" />
  </svg>
);

const LogoCompact = chakra(SVGIcon);
export default LogoCompact;
