import { ThemeComponents } from '@chakra-ui/react';

import { accordion } from './accordion';
import { badge } from './badge';
import { button } from './button';
import { checkbox } from './checkbox';
import { container } from './container';
import { divider } from './divider';
import { form } from './form';
import { formError } from './form-error';
import { heading } from './heading';
import { input } from './input';
import { kbd } from './kbd';
import { link } from './link';
import { list } from './list';
import { menu } from './menu';
import { popover } from './popover';
import { radio } from './radio';
import { select } from './select';
import { skeleton } from './skeleton';
import { _switch } from './switch';
import { table } from './table';
import { tag } from './tag';
import { textarea } from './textarea';
import { tooltip } from './tooltip';

export const components: ThemeComponents = {
  Popover: popover,
  Menu: menu,
  Accordion: accordion,
  Button: button,
  Checkbox: checkbox,
  Radio: radio,
  Input: input,
  Form: form,
  FormError: formError,
  Container: container,
  Divider: divider,
  Heading: heading,
  Link: link,
  Badge: badge,
  List: list,
  Select: select,
  Skeleton: skeleton,
  Switch: _switch,
  Textarea: textarea,
  Table: table,
  Tooltip: tooltip,
  Tag: tag,
  Kbd: kbd,
};
