import { Button, ButtonProps, forwardRef } from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { rem } from 'polished';
import { ComponentPropsWithoutRef, HTMLAttributeAnchorTarget, ReactNode } from 'react';

import { LinkConnector } from '../LinkConnector';

export const Link = LinkConnector;

export type NavLinkProps = LinkConnectorProps &
  Omit<ButtonProps, 'href' | 'onClick'> & {
    useActiveClassName?: boolean;
    exact?: boolean;
    render?: (isActive: boolean) => any;
    children?: React.ReactElement;
    passHref?: boolean;
    hoverLinkColor?: string;
  };

type HrefProps = Pick<ComponentPropsWithoutRef<typeof NavLink>, 'href'>;

type EventHandlerProps =
  | {
      href?: undefined;
      onClick?: () => void;
    }
  | {
      href: undefined;
      onMouseOver?: () => void;
    };

type Props = Omit<ButtonProps, 'href' | 'onClick'> &
  (HrefProps | EventHandlerProps) & {
    children?: ReactNode;
    activeClassName?: string;
    activeLinkColor?: string;
    target?: HTMLAttributeAnchorTarget;
    hoverLinkColor?: string;
    underlineOffset?: string;
  };

export const NavLinkItem = forwardRef<Props, 'button'>(
  (
    {
      children,
      activeLinkColor,
      href,
      fontSize,
      fontWeight,
      activeClassName,
      hoverLinkColor,
      underlineOffset,
      ...rest
    },
    ref,
  ) => {
    return (
      <Button
        ref={ref}
        href={href}
        as={href ? NextLink : undefined}
        variant="link"
        {...rest}
        className={activeClassName}
        sx={{
          display: { base: 'flex', md: 'block' },
          justifyContent: 'flex-start',
          position: 'relative',
          paddingBlock: { base: 'space.8', md: 0 },
          fontSize: fontSize || 'md',
          lineHeight: 1.25,
          textDecoration: 'none',
          textAlign: 'left',
          fontWeight: fontWeight || 'normal',
          whiteSpace: 'normal',
          '&:after': {
            content: {
              md: "''",
            },
            display: 'block',
            width: '0%',
            height: rem(2),
            borderRadius: rem(2),
            position: 'absolute',
            background: hoverLinkColor || 'black',
            transition: 'ease-in-out .3s',
            opacity: 0,
            top:
              underlineOffset ||
              (fontSize === 'xs'
                ? 'space.18'
                : fontSize === 'sm'
                ? 'space.20'
                : !fontSize || fontSize === 'md'
                ? 'space.22'
                : 'space.24'),
          },
          '&.active::after': {
            background: activeLinkColor || 'white',
            opacity: 1,
            width: '100%',
          },
          '&:hover': {
            opacity: 0.6,
            textDecoration: 'none',
          },
          '&:hover::after': {
            background: hoverLinkColor || 'black',
            opacity: 0.6,
            width: '100%',
          },
          '&:active': {
            color: 'gray.5',
          },
          '&:active::after': {
            background: 'gray.4',
            opacity: 1,
            width: '100%',
          },
          ...rest.sx,
        }}
      >
        {children}
      </Button>
    );
  },
);

export const NavLink = ({
  href,
  exact,
  render,
  passHref,
  useActiveClassName = false,
  ...otherProps
}: NavLinkProps) => {
  const { asPath } = useRouter();

  const match = asPath ? otherProps.as || href : href;
  const condition = exact ? Boolean(asPath === match) : Boolean(asPath?.startsWith(String(match)));

  const className = condition && useActiveClassName ? 'active' : undefined;

  return (
    <LinkConnector href={href} passHref={passHref}>
      {render ? (
        render(condition)
      ) : (
        <NavLinkItem activeClassName={className} href={href} {...otherProps} />
      )}
    </LinkConnector>
  );
};
