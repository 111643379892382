import { ComponentStyleConfig } from '@chakra-ui/react';

export const kbd: ComponentStyleConfig = {
  baseStyle: {
    border: 'none',
    bg: 'gray.4',
    color: 'gray.5',
    py: 'space.2',
    transition: 'all 120ms ease-in-out',
  },
  variants: {
    translucent: {
      bg: 'gray.4',
      color: 'gray.5',
      opacity: 0.8,
    },
  },
};
