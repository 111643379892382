import { ChakraTheme } from '@chakra-ui/react';

export const countdownTextStyles: ChakraTheme['textStyles'] = {
  'countdown-numbers': {
    fontSize: '3xl',
    fontWeight: '300',
  },
  'countdown-letters': {
    fontSize: '2xl',
    fontWeight: '300',
  },
};
