import { Box, Text, VStack } from '@chakra-ui/react';
import NextLink, { type LinkProps } from 'next/link';
import { useEffect, useRef, type KeyboardEvent, type PropsWithChildren } from 'react';

import { useFocus } from './useFocus';

export type Props = {
  viewAllLink: LinkProps & {
    label: string;
  };
  focusIndex: number;
  title?: string;
};

export function SearchHits({ viewAllLink, focusIndex, title, children }: PropsWithChildren<Props>) {
  const { focusedIndex, setFocusedIndex, totalItems } = useFocus();
  const ref = useRef<HTMLParagraphElement & HTMLAnchorElement>(null);

  const handleKeyDown = (e: KeyboardEvent<HTMLParagraphElement & HTMLAnchorElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setFocusedIndex(prevState => (focusedIndex >= totalItems - 1 ? 0 : prevState + 1));
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (focusedIndex <= 0) return;
      setFocusedIndex(prevState => prevState - 1);
    }
  };

  useEffect(() => {
    if (focusedIndex !== focusIndex || !ref?.current) return;
    ref.current.focus();
  }, [ref, focusedIndex, focusIndex]);

  return (
    <Box data-testid="autocomplete-hits-category" p="space.4">
      {!!children && (
        <>
          {title && (
            <Text fontSize="sm" color="gray.6" mb="space.8">
              {title}
            </Text>
          )}
          <VStack align="stretch" data-testid="autocomplete-hits-category-results" mb="space.4">
            {children}
          </VStack>
          <NextLink {...viewAllLink} passHref legacyBehavior>
            <Text
              display="block"
              ref={ref}
              data-testid="autocomplete-hits-category-link"
              onKeyDown={handleKeyDown}
              as="a"
              fontWeight="medium"
              fontSize="sm"
              w="100%"
              py="space.6"
              px="space.10"
              rounded="radius.8"
              _hover={{ bg: 'gray.4' }}
              _focus={{ bg: 'gray.4', outline: 'none' }}
              _focusVisible={{ boxShadow: 'outline' }}
            >
              {viewAllLink.label}
            </Text>
          </NextLink>
        </>
      )}
    </Box>
  );
}
