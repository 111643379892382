import { useMounted } from '@/hooks/use-mounted';
import supportedChains from '@/utils/chains';
import { Box, BoxProps, Image, Tab, TabList, Tabs, Tooltip } from '@chakra-ui/react';
import { rem } from 'polished';
import { type FC } from 'react';

type Props = BoxProps & {
  searchChain: string;
  onChangeChain(chain: string): void;
};

export const SearchChainSwitcher: FC<Props> = ({ searchChain, onChangeChain, ...boxProps }) => {
  const isMounted = useMounted();

  if (!isMounted || supportedChains.length === 1) {
    return null;
  }

  const currentChainIndex = supportedChains.findIndex(c => c.routePrefix === searchChain);

  return (
    <Box {...boxProps}>
      <Tabs defaultIndex={currentChainIndex}>
        <TabList borderBottom="1px solid" borderColor="border">
          {supportedChains.map(chainOption => (
            <Tooltip
              key={chainOption.name}
              hasArrow
              arrowSize={8}
              placement="top"
              label={chainOption.name}
              aria-label={chainOption.name}
              gutter={0}
            >
              <Tab
                onClick={() => {
                  onChangeChain(chainOption.routePrefix);
                }}
                boxSize={rem(50)}
              >
                <Image src={chainOption.darkIconUrl} boxSize={rem(20)} flexShrink={0} />
              </Tab>
            </Tooltip>
          ))}
        </TabList>
      </Tabs>
    </Box>
  );
};
