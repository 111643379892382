import { type SearchResultItem } from '@/pages/api/globalSearch';
import { type FC } from 'react';
import { SearchHitCollection } from './SearchHitCollection';
import { SearchHitGame } from './SearchHitGame';

type SearchHitProps = {
  result: SearchResultItem;
  focusIndex: number;
};

const SearchHit: FC<SearchHitProps> = ({ focusIndex, result }) => {
  if (result.type == 'collection') {
    return <SearchHitCollection collection={result.data} focusIndex={focusIndex} />;
  } else if (result.type == 'game') {
    return <SearchHitGame game={result.data} focusIndex={focusIndex} />;
  } else {
    return null;
  }
};

export default SearchHit;
