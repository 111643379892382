import { ChakraTheme } from '@chakra-ui/react';

export const footerTextStyles: ChakraTheme['textStyles'] = {
  'footer-intro': {
    fontSize: 'md',
    color: 'gray.6',
    lineHeight: {
      base: 1.5,
    },
  },

  'footer-link': {
    fontWeight: 'normal',
    fontSize: {
      base: 'sm',
      md: 'md',
    },
    lineHeight: 2,
    textDecoration: 'underline',
    _hover: {
      textDecoration: 'none',
    },
  },

  'footer-link-small': {
    fontWeight: 'normal',
    fontSize: 'sm',
    lineHeight: 2,
    whiteSpace: 'nowrap',
  },
};
