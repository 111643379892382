import React from 'react';

export default () => (
  <svg
    width="auto"
    height="100%"
    viewBox="5 0 15 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2127_56739)">
      <path
        d="M11.9979 0L11.8369 0.546928V16.4161L11.9979 16.5767L19.3641 12.2225L11.9979 0Z"
        fill="#343434"
      />
      <path d="M11.9979 0L4.63159 12.2225L11.9979 16.5767V8.87428V0Z" fill="#8C8C8C" />
      <path
        d="M11.998 17.9714L11.9072 18.0821V23.7349L11.998 23.9999L19.3686 13.6195L11.998 17.9714Z"
        fill="#3C3C3B"
      />
      <path d="M11.9979 23.9999V17.9714L4.63159 13.6195L11.9979 23.9999Z" fill="#8C8C8C" />
      <path d="M11.998 16.5767L19.3642 12.2225L11.998 8.87427V16.5767Z" fill="#141414" />
      <path d="M4.63159 12.2225L11.9979 16.5767V8.87427L4.63159 12.2225Z" fill="#393939" />
    </g>
    <defs>
      <clipPath id="clip0_2127_56739">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
