import { ChakraProvider as Root } from '@chakra-ui/react';
import {
  createContext,
  FunctionComponent,
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
} from 'react';

type LinkWrapper = (link: ReactElement) => ReactElement;

interface IThemeProviderProps {
  theme?: any;
  linkWrapper?: LinkWrapper;
  children?: ReactNode;
}

interface IThemeProviderContext {
  linkWrapper: LinkWrapper;
}

const ThemeContext = createContext<IThemeProviderContext>({
  linkWrapper: (link: ReactElement) => link,
});

const useSphereTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useSphereTheme must be used within a ThemeProvider');
  }
  return context;
};

const ThemeProvider: FunctionComponent<IThemeProviderProps> = ({
  theme,
  linkWrapper,
  children,
}) => {
  const context = useMemo(
    () => ({
      linkWrapper: (link: ReactElement) => (linkWrapper ? linkWrapper(link) : link),
    }),
    [linkWrapper],
  );

  return (
    <ThemeContext.Provider value={context}>
      <Root theme={theme} cssVarsRoot="#__next" resetCSS>
        {children}
      </Root>
    </ThemeContext.Provider>
  );
};

export { ThemeProvider, useSphereTheme };
