import React from 'react';

export default () => (
  <svg
    width="auto"
    height="100%"
    viewBox="5 0 15 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9441 17.97L4.58008 13.62L11.9431 24L19.3131 13.62L11.9411 17.97H11.9441V17.97ZM12.0561 0L4.69008 12.223L12.0551 16.577L19.4201 12.227L12.0561 0Z"
      fill="black"
    />
  </svg>
);
