import { chakra } from '@chakra-ui/react';
import { useId } from 'react';

const SVGIcon = ({ ...props }) => {
  const uId = useId();

  const ids = [`${uId}1`, `${uId}2`, `${uId}3`, `${uId}4`];

  return (
    <svg viewBox="0 0 37 43" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath={`url(#${ids[0]})`}>
        <rect width="37" height="43" rx="4" fill="#DADCE0" />
        <rect
          opacity="0.15"
          x="2.94434"
          y="46.6665"
          width="34.8844"
          height="34.8844"
          rx="3"
          transform="rotate(-45 2.94434 46.6665)"
          fill={`url(#${ids[1]})`}
        />
        <rect
          opacity="0.5"
          x="-13.2222"
          y="42.2217"
          width="36.0624"
          height="36.0624"
          rx="4"
          transform="rotate(-45 -13.2222 42.2217)"
          fill={`url(#${ids[2]})`}
        />
        <circle opacity="0.7" cx="26.5" cy="10.5" r="4.5" fill={`url(#${ids[3]})`} />
      </g>
      <rect x="1" y="1" width="35" height="41" rx="3" stroke="#F5F5F7" strokeWidth="2" />
      <defs>
        <linearGradient
          id={ids[1]}
          x1="15.4669"
          y1="40.9978"
          x2="49.5075"
          y2="49.2382"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#131313" />
          <stop offset="1" stopColor="#4B4B4B" />
        </linearGradient>
        <linearGradient
          id={ids[2]}
          x1="9.40525"
          y1="37.5868"
          x2="17.537"
          y2="68.346"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#131313" />
          <stop offset="1" stopColor="#4B4B4B" />
        </linearGradient>
        <linearGradient
          id={ids[3]}
          x1="25.2308"
          y1="4.5375"
          x2="31.4053"
          y2="6.92228"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#131313" />
          <stop offset="1" stopColor="#4B4B4B" />
        </linearGradient>
        <clipPath id={ids[0]}>
          <rect width="37" height="43" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const ImageUploadSingle = chakra(SVGIcon);
export default ImageUploadSingle;
