import { api } from '@/utils/api';
import { getSupportedChainById } from '@/utils/getChain';
import { routes } from '@/utils/routes';
import {
  Button,
  Flex,
  HStack,
  Heading,
  IconButton,
  Input,
  Link,
  ListItem,
  Text,
  UnorderedList,
  useBreakpointValue,
} from '@chakra-ui/react';
import { formatAddress } from '@onbeam/utils';
import { HighlightType, HighlightedCollection } from '@prisma/client';
import Trash from '@sphere/icons/components/Trash';
import Warning from '@sphere/icons/components/Warning';
import useTranslation from 'next-translate/useTranslation';
import { rem } from 'polished';
import { FC, useCallback, useState } from 'react';

type Props = {
  type: HighlightType;
  chainId: number;
  data: HighlightedCollection[];
  onMutate: () => void;
};

const HighlightedCollectionTypeForm: FC<Props> = ({ type, chainId, data, onMutate, ...props }) => {
  const { t } = useTranslation('companion');
  const isMobile = useBreakpointValue({ base: true, sm: false });
  const removeMutation = api.collectionHighlight.removeHighlightedCollection.useMutation();
  const addMutation = api.collectionHighlight.addHighlightedCollection.useMutation();
  const [addAddress, setAddAddress] = useState<string>('');

  const handleRemove = useCallback(
    async (address: string) => {
      await removeMutation.mutateAsync({
        address,
        chainId,
        type,
      });
      onMutate();
    },
    [chainId, type, removeMutation, onMutate],
  );

  const handleAdd = useCallback(async () => {
    if (!addAddress) return;
    await addMutation.mutateAsync({
      address: addAddress,
      chainId,
      type,
    });
    setAddAddress('');
    onMutate();
  }, [chainId, addAddress, addMutation, onMutate, type]);

  return (
    <Flex direction="column" {...props}>
      <Heading fontSize="md" fontWeight="bold">
        {t(`highlighted-collections.type.${type.toLowerCase()}.title`)}
      </Heading>
      <Text fontSize="sm" color="muted" mt="space.4">
        {t(`highlighted-collections.type.${type.toLowerCase()}.description`)}
      </Text>
      <Flex gap="space.8" my="space.8">
        <Input
          value={addAddress}
          onChange={e => setAddAddress(e.target.value)}
          placeholder="Collection address"
          size="sm"
          color="gray.5"
          borderColor="gray.5"
        />
        <Button
          onClick={handleAdd}
          isLoading={addMutation.isPending}
          isDisabled={addMutation.isPending}
          variant="clear"
          fontWeight="bold"
          size="sm"
        >
          {t('add')}
        </Button>
      </Flex>
      {!!data.length ? (
        <UnorderedList>
          {data.map(highlight => (
            <ListItem listStyleType="unset" key={highlight.id}>
              <HStack>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={routes.collections.detail(
                    highlight.address,
                    getSupportedChainById(highlight.chainId)?.routePrefix ?? '',
                  )}
                  w={{ base: rem(120), sm: rem(390) }}
                >
                  {isMobile ? formatAddress(highlight.address) : highlight.address}
                </Link>
                <IconButton
                  ml="space.4"
                  icon={<Trash />}
                  boxSize={rem(20)}
                  isLoading={removeMutation.isPending}
                  isDisabled={removeMutation.isPending}
                  onClick={() => handleRemove(highlight.address)}
                  aria-label="Remove"
                />
              </HStack>
            </ListItem>
          ))}
        </UnorderedList>
      ) : (
        <Text color="gray.5" fontSize="sm">
          <Warning boxSize={rem(12)} /> {t('highlighted-collections.none-highlighted')}
        </Text>
      )}
    </Flex>
  );
};

export default HighlightedCollectionTypeForm;
