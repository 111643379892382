import {
  Box,
  BoxProps,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useRef } from 'react';

import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { useAuthentication } from '@/hooks/useAuthentication';
import { UserRole } from '@prisma/client';
import useTranslation from 'next-translate/useTranslation';
import { DevCompanion } from './DevCompanion';
import { DevCompanionProvider } from './DevCompanion.store';

type Props = BoxProps & unknown;

const DevCompanionDrawer = ({ ...boxProps }: Props) => {
  const { t } = useTranslation('companion');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef(null);
  const { user } = useAuthentication();
  const marketplaceChain = useMarketplaceChain();

  if (user?.role === UserRole.ADMIN) {
    return (
      <>
        <Box w={12} h={12} {...boxProps}>
          <Button
            ref={btnRef}
            onClick={onOpen}
            variant="clear"
            size="sm"
            borderRadius="lg"
            w={12}
            h={12}
            p={0}
            data-testid="dev-companion-toggle-btn"
          >
            <Text fontSize="lg">{'🛠'}</Text>
          </Button>
        </Box>

        <Drawer isOpen={isOpen} placement="left" size="lg" onClose={onClose} finalFocusRef={btnRef}>
          <DrawerOverlay />
          <DrawerContent bg="blackBg">
            <DrawerCloseButton />

            {user ? (
              <>
                <DrawerHeader fontSize="lg" px={4} pb={0}>
                  {t('title')}
                </DrawerHeader>
                <DrawerBody p={0}>
                  <DevCompanionProvider chainId={marketplaceChain.id}>
                    <DevCompanion />
                  </DevCompanionProvider>
                </DrawerBody>
              </>
            ) : (
              <Box textAlign="center" mx="auto" mt="80">
                <Text fontSize="2xl">{'😧'}</Text>
                <Text color="gray.4">{t('not-signed-in')}</Text>
              </Box>
            )}
          </DrawerContent>
        </Drawer>
      </>
    );
  }

  return null;
};

export default DevCompanionDrawer;
