import { create } from 'zustand';

type Store = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
};

export const useAuthenticationModal = create<Store>(set => ({
  isOpen: false,
  setOpen: isOpen => set({ isOpen }),
}));
