import { Box, Container, Heading, Text, type ContainerProps } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';

import { useFluidSize } from '@sphere/ui/utils/hooks/useFluidSize';
import { SocialMediaLinksList } from './SocialMediaLinksList';

export const SocialMediaLinks = (containerProps: ContainerProps) => {
  const { t } = useTranslation('common');
  const titleFontSize = useFluidSize('xl', '3xl', 'sm');
  const padding = useFluidSize(0, 390, 'sm');

  return (
    <Container {...containerProps}>
      <Heading as="h2" fontSize={titleFontSize} textAlign="center" px={padding}>
        {t('community.title')}
      </Heading>
      <Text
        fontSize="md"
        lineHeight={1.5}
        textAlign="center"
        mt="space.8"
        px={padding}
        color="gray.6"
      >
        {t('community.subtitle')}
      </Text>
      <Box mt={{ base: 'space.32', md: 'space.40' }}>
        <SocialMediaLinksList />
      </Box>
    </Container>
  );
};
