import { Image, type ImageProps } from '@chakra-ui/react';
import { useReservoirClient } from '@sphere/reservoir-kit-ui';
import React, { forwardRef } from 'react';
import { zeroAddress } from 'viem';

type Props = ImageProps & {
  address?: string;
  chainId?: number;
  logoSrc?: string;
  symbol?: string;
};

const CryptoCurrencyIcon = forwardRef<HTMLImageElement, Props>(
  ({ address = zeroAddress, chainId, logoSrc, symbol, ...props }, ref) => {
    const client = useReservoirClient();
    const chain = client?.chains?.find(chain =>
      chainId !== undefined ? chain.id === chainId : chain.active,
    );

    return (
      <Image
        ref={ref}
        src={logoSrc || `${chain?.baseApiUrl}/redirect/currency/${address}/icon/v1`}
        alt={symbol || chain?.name}
        {...props}
      />
    );
  },
);

export default CryptoCurrencyIcon;
