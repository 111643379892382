import { ComponentStyleConfig } from '@chakra-ui/react';

export const popover: ComponentStyleConfig = {
  parts: ['popper', 'content', 'arrow'],
  baseStyle: props => ({
    popper: {
      zIndex: 10,
      maxW: props.width ? props.width : 'xs',
      w: '100%',
    },
    content: {
      bg: 'gray.2',
      color: 'white',
    },
    arrow: {
      bg: 'gray.2',
    },
  }),
};
