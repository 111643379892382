import { ChainContext } from '@/context/ChainContextProvider';
import supportedChains, { DefaultChain } from '@/utils/chains';
import { getSupportedChainById } from '@/utils/getChain';
import { useRouter } from 'next/router';
import { useContext } from 'react';

export const useMarketplaceChain = () => {
  const { chain } = useContext(ChainContext);
  const router = useRouter();

  // Return the chain based on the route prefix
  const routePrefix = router.query.chain;

  const routeChain = supportedChains.find(c => c.routePrefix === routePrefix);
  if (routeChain) return routeChain;

  // Fallback to supported wallet chain
  const supportedChain = getSupportedChainById(chain?.id);
  return supportedChain ?? DefaultChain;
};
