import { ButtonProps, SystemStyleObject } from '@chakra-ui/react';

import { baseStyles } from './base';
import { getColorStyles } from './color';
import { getIconStyles } from './icon';
import { getOutlineStyles } from './outline';

export type HamburgerStyleProps = ButtonProps & {
  active?: boolean;
};
export const getStyles = (props: HamburgerStyleProps): SystemStyleObject => ({
  ...baseStyles,
  ...getColorStyles(props),
  ...getOutlineStyles(props),
  ...getIconStyles(props),
});
