import { BoxProps, ModalProps as ChakraModalProps, SpaceProps } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

type ModalContentProps = Pick<
  SpaceProps,
  | 'p'
  | 'padding'
  | 'pt'
  | 'paddingBlockStart'
  | 'paddingTop'
  | 'pr'
  | 'paddingInlineEnd'
  | 'paddingEnd'
  | 'pe'
  | 'paddingRight'
  | 'pb'
  | 'paddingBlockEnd'
  | 'paddingBottom'
  | 'pl'
  | 'paddingInlineStart'
  | 'paddingStart'
  | 'ps'
  | 'paddingLeft'
  | 'px'
  | 'paddingInline'
  | 'paddingX'
  | 'py'
  | 'paddingBlock'
  | 'paddingY'
> &
  Pick<BoxProps, 'top' | 'maxW' | 'maxWidth' | 'maxH' | 'maxHeight' | 'overflow'>;

export type ModalProps = ChakraModalProps & ModalContentProps;

export const processModalProps = ({
  children,
  ...props
}: ModalProps): PropsWithChildren<{
  modalProps: Omit<ChakraModalProps, 'children'>;
  ModalContentProps: Partial<ModalContentProps>;
}> => {
  const {
    overflow,
    maxH,
    maxHeight,
    maxW,
    maxWidth,
    p,
    padding,
    pt,
    paddingBlockStart,
    paddingTop,
    pr,
    paddingInlineEnd,
    paddingEnd,
    pe,
    paddingRight,
    pb,
    paddingBlockEnd,
    paddingBottom,
    pl,
    paddingInlineStart,
    paddingStart,
    ps,
    top,
    paddingLeft,
    px,
    paddingInline,
    paddingX,
    py,
    paddingBlock,
    paddingY,
    ...otherProps
  } = props;

  const ModalContentProps = {
    overflow,
    maxH,
    maxHeight,
    maxW,
    maxWidth,
    pt,
    paddingBlockStart,
    paddingTop,
    pr,
    paddingInlineEnd,
    paddingEnd,
    pe,
    paddingRight,
    pb,
    paddingBlockEnd,
    paddingBottom,
    pl,
    paddingInlineStart,
    paddingStart,
    ps,
    paddingLeft,
    px,
    paddingInline,
    paddingX,
    py,
    paddingBlock,
    paddingY,
    padding: padding ?? p ?? { base: 'space.24', md: 'space.32' },
    top: top,
  };

  const modalProps = {
    ...otherProps,
    scrollBehavior: otherProps?.scrollBehavior || 'outside',
    size: otherProps?.size || '2xl',
  };

  return {
    children,
    modalProps,
    ModalContentProps,
  };
};
