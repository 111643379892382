import { ComponentStyleConfig } from '@chakra-ui/react';

import { primary as primaryButtonStyles } from './button/primary';

export const link: ComponentStyleConfig = {
  baseStyle: {
    textDecoration: 'none',
    transition: 'opacity 160ms ease-in-out',
    _hover: {
      textDecoration: 'none',
      opacity: 0.4,
    },
    _active: {
      opacity: 0.6,
    },
  },
  variants: {
    button: {
      display: 'inline-block',
      textDecoraton: 'none',
      ...primaryButtonStyles,
    },
    secondary: {
      textDecoration: 'none',
    },
  },
};
