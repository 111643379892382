import { acceptOffer } from './acceptOffer';
import { buyToken } from './buyToken';
import { cancelOrder } from './cancelOrder';
import { listToken } from './listToken';
import { placeBid } from './placeBid';
import { transferTokens } from './transferTokens';

const actions = {
  acceptOffer,
  buyToken,
  cancelOrder,
  listToken,
  placeBid,
  transferTokens,
};

export default actions;
