import { ComponentStyleConfig } from '@chakra-ui/react';

export const container: ComponentStyleConfig = {
  baseStyle: {
    maxWidth: '100%',
    width: 'container.xl',
    px: {
      base: 'space.24',
      lg: 'space.32',
      '2xl': 'space.48',
    },
  },
  variants: {
    breakout: {
      width: 'container.3xl',
    },
  },
};
