import { forwardRef, Icon, IconProps } from '@chakra-ui/react';
export default forwardRef<IconProps, 'svg'>((props, svgRef) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    ref={svgRef}
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.161 4s-5.73 0-7.168.374a2.37 2.37 0 00-1.62 1.63C1 7.442 1 10.421 1 10.421s0 2.99.374 4.405a2.333 2.333 0 001.619 1.619c1.45.385 7.168.385 7.168.385s5.741 0 7.18-.374a2.278 2.278 0 001.607-1.619c.385-1.427.385-4.405.385-4.405s.012-2.99-.385-4.428a2.263 2.263 0 00-1.608-1.608C15.902 4 10.161 4 10.161 4zM8.338 7.669l4.767 2.752-4.767 2.74V7.669z"
    />
  </Icon>
));
