import NextLink, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import React, { cloneElement, ReactElement } from 'react';

/**
 *
 * @param component any link component
 * @returns a next/link component or a <a> component
 */

const WithNextLink = (component: ReactElement<HTMLAnchorElement & LinkProps>): ReactElement => {
  const router = useRouter();
  const {
    props: { className: classes, href },
    key,
  } = component;

  const className = router.pathname === href ? `${classes || ''} active` : classes;

  /** Exception: if the href is not provided, return the component */
  /** Exception: if the href is a fragment url for the current page: return the <a> element */
  if (!href || /^#/.exec(href)) {
    return cloneElement(component, {
      className,
    });
  }

  return (
    <NextLink href={href} key={key} passHref legacyBehavior>
      {cloneElement(component, { className })}
    </NextLink>
  );
};

/**
 * WithNextLink is capitalized since otherwise ESlint will whine about usage of hooks
 * We reassign it to a camelCased variable here
 */
const withNextLink = WithNextLink;

export { withNextLink };
