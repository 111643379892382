import { createContext, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import rosetta, { Rosetta } from 'rosetta';

export type Language = string;

type TranslationProps = {
  locale?: string;
  dictionary: unknown;
};

export const languages: Language[] = ['en'];

/**
 * Initialize rosetta and set the default locale
 */
const i18n = rosetta();
i18n.locale(languages[0]);

/**
 * Create context where all the translations provided by your app will be hosted
 */
export type TranslationContextType = {
  activeLocale: Language;
  t: Rosetta<unknown>['t'];
  locale: (l: Language, dict: unknown) => void;
};

const TranslationContext = createContext<TranslationContextType>({
  activeLocale: languages[0],
  t: i18n.t,
  locale: () => null,
});

function TranslationProvider({
  children,
  locale,
  dictionary,
}: PropsWithChildren<TranslationProps>) {
  const [, setTick] = useState(0);

  const activeLocaleRef = useRef<Language>(locale || languages[0]);
  const firstRender = useRef(true);

  const i18nWrapper: TranslationContextType = {
    activeLocale: activeLocaleRef.current,
    t: (...args) => i18n.t(...args),
    locale: (l, dict) => {
      i18n.locale(l);
      activeLocaleRef.current = l;
      if (dict) {
        i18n.set(l, dict);
      }
      /** force rerender to update view when the locale changes */
      setTick(tick => tick + 1);
    },
  };

  /** Provide a dictionary on the initial (SSR) render */
  if (locale && firstRender.current === true) {
    firstRender.current = false;
    i18nWrapper.locale(locale, dictionary);
  }

  /**
   * Effect gets triggered when either the dicitonary or passed active locale changes (ie. a user switches the language setting in your app)
   */
  useEffect(() => {
    if (!locale) return;
    i18nWrapper.locale(locale, dictionary);
  }, [dictionary, locale]); // eslint-disable-line react-hooks/exhaustive-deps

  return <TranslationContext.Provider value={i18nWrapper}>{children}</TranslationContext.Provider>;
}

/**
 * @returns Hook used for accessing the translations
 */

function useUITranslation() {
  const context = useContext(TranslationContext);
  if (context === undefined) {
    throw new Error('useUITranslation must be used within a TranslationProvider');
  }
  return context;
}

export { TranslationContext, TranslationProvider, useUITranslation };
