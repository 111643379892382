import { UseModalProps } from '@chakra-ui/react';

import { Modal } from '@sphere/ui';
import { ConnectWalletModalContent } from './ConnectWalletModalContent';

type Props = UseModalProps;

export const ConnectWalletModal = ({ isOpen, onClose }: Props) => (
  <Modal testId="connect-wallet" isOpen={isOpen} onClose={onClose} padding={0}>
    <ConnectWalletModalContent />
  </Modal>
);
