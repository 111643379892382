import { CheckApproval } from '@/components/features/DevCompanion/components/Approval/CheckApproval';
import { SetApproval } from '@/components/features/DevCompanion/components/Approval/SetApproval';
import CreateGame from '@/components/features/DevCompanion/components/Game/CreateGame';
import DeleteGame from '@/components/features/DevCompanion/components/Game/DeleteGame';
import { useAuthentication } from '@/hooks/useAuthentication';
import { Box, Divider, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { formatAddress } from '@onbeam/utils';
import useTranslation from 'next-translate/useTranslation';
import HighlightedCollections from './components/HighlightedCollections/HighlightedCollections';
import RevalidateCollection from './components/RevalidateCollection';
import { SetChainId } from './components/SetChainId';
import SetCollectionCreator from './components/SetCollectionCreator';
import SetCollectionSpamStatus from './components/SetCollectionSpamStatus';

export const DevCompanion = () => {
  const { t } = useTranslation('companion');
  const { userAddress } = useAuthentication();

  if (!userAddress) return null;

  return (
    <Box>
      <Text fontSize="xs" color="gray.5" px={4} pb={2}>
        {t('connected-with', {
          address: formatAddress(userAddress),
        })}
      </Text>

      <SetChainId px="space.16" />

      <Tabs mt="space.4">
        <TabList>
          <Tab>{'Collections'}</Tab>
          <Tab>{'Collection'}</Tab>
          <Tab>{'Game'}</Tab>
          <Tab>{'Contracts'}</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <HighlightedCollections mt="space.10" />
          </TabPanel>
          <TabPanel>
            <SetCollectionCreator mt="space.10" />
            <Divider />
            <SetCollectionSpamStatus mt="space.20" />
            <Divider />
            <RevalidateCollection mt="space.10" />
          </TabPanel>
          <TabPanel>
            <CreateGame mt="space.10" />
            <Divider mt="space.10" />
            <DeleteGame mt="space.20" />
          </TabPanel>
          <TabPanel>
            <SetApproval mt="space.10" />
            <Divider />
            <CheckApproval mt="space.20" />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
