import { SystemStyleObject } from '@chakra-ui/react';
import { colors } from './colors';

export const globalStyle: SystemStyleObject = {
  html: {
    backgroundColor: colors.blackBg,
    color: 'white',
  },
  body: {
    backgroundColor: colors.blackBg,
    color: 'white',
    minWidth: '320px',
  },
  '*': {
    boxSizing: 'border-box',
  },
  '.flatpickr-calendar': {
    backgroundColor: 'blackBg',
    pointerEvents: 'all',
  },
  '.flatpickr-day.selected': {
    backgroundColor: 'primary',
    borderColor: 'primary',
    color: 'darkPurple',
  },
  '.flatpickr-day.selected:hover': {
    backgroundColor: 'primary',
    borderColor: 'primary',
    color: 'darkPurple',
  },
  '.flatpickr-current-month .flatpickr-monthDropdown-months': {
    backgroundColor: 'transparent',
  },
  'flatpickr-monthDropdown-months': {
    backgroundColor: 'transparent',
  },
  '.flatpickr-months .flatpickr-month': {
    backgroundColor: 'transparent',
  },
  'span.flatpickr-weekday': {
    backgroundColor: 'transparent',
  },
  '.flatpickr-months .flatpickr-prev-month:hover svg': {
    fill: 'primary',
  },
  '.flatpickr-months .flatpickr-next-month:hover svg': {
    fill: 'primary',
  },
  '.flatpickr-day:hover, .flatpickr-day:focus': {
    borderColor: '#26292b',
    backgroundColor: '#26292b',
  },
  '.flatpickr-day.nextMonthDay:hover, .flatpickr-day.nextMonthDay:focus': {
    borderColor: '#26292b',
    backgroundColor: '#26292b',
  },
  '.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover': {
    backgroundColor: '#26292b',
  },
  '.flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus': {
    backgroundColor: '#26292b',
  },
};
// #26292b
