import { passport } from '@imtbl/sdk';
import { config } from './config';

const passportClient = new passport.Passport(config);

const passportProvider = passportClient.connectEvm();

export type ZkEvmProvider = ReturnType<typeof passportClient.connectEvm>;

export { passportClient, passportProvider };
