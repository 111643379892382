import { create } from 'zustand';

type Store = {
  focusedIndex: number;
  totalItems: number;
  setFocusedIndex: (newState: (currentIndex: number) => number) => void;
  setTotalItems: (totalItems: number) => void;
};

export const useFocus = create<Store>(set => ({
  focusedIndex: 0,
  totalItems: 0,
  setFocusedIndex: newState => set(state => ({ focusedIndex: newState(state.focusedIndex) })),
  setTotalItems: totalItems => set({ totalItems }),
}));
