import { chakra } from '@chakra-ui/react';
import { MotionBox, paddedScrollStyles } from '@sphere/ui';
import { type MotionProps } from 'framer-motion';
import { type PropsWithChildren } from 'react';
import { useWindowSize } from 'react-use';

export const SearchResultsWrapper = ({
  children,
  ...motionProps
}: PropsWithChildren<MotionProps>) => (
  <MotionBox
    p="space.16"
    pb="space.10"
    background="blackBg"
    borderRadius="radius.12"
    zIndex="autocomplete"
    boxShadow="autocomplete"
    {...motionProps}
  >
    {children}
  </MotionBox>
);

export const SearchResultsScroller = ({ children }: PropsWithChildren) => {
  const { height } = useWindowSize();
  const resultsHeight = height - 128;

  return (
    <chakra.div
      position="relative"
      maxHeight={resultsHeight}
      css={paddedScrollStyles}
      sx={{
        overflowY: 'overlay',
        overflowX: 'hidden',
      }}
    >
      {children}
    </chakra.div>
  );
};
