import { useRouteChange } from '@/utils/useRouteChange';
import { Button, ButtonProps } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useCallback } from 'react';
import { MenuScreen, useMenu } from '../../useHeaderSettings/useMenu';
import { getStyles } from './styles/styles';

export const HamburgerButton = (props: ButtonProps) => {
  const { menu, setMenu } = useMenu(state => ({ menu: state.menu, setMenu: state.setMenu }));
  const { t } = useTranslation('common');

  // This function has to be memoized in order to not recreate the function on every render
  // which would unsubscribe and resubscribe the `useRouteChange` listeners unnecessarily a bunch of times
  const resetMenu = useCallback(() => {
    setMenu(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * When anything happens in the router, we want to close the
   */
  useRouteChange('hashChangeComplete', resetMenu);

  useRouteChange(resetMenu);

  return (
    <Button
      variant="icon"
      sx={getStyles({ ...props, active: !!menu })}
      active={!!menu ? 1 : undefined}
      aria-label={menu ? t('header.hide-navigation') : t('header.show-navigation')}
      onClick={() => setMenu(menu ? undefined : MenuScreen.Main)}
      {...props}
    />
  );
};
