import { CryptoCurrencyIcon, FormatCrypto } from '@/components/primitives';
import { UsdPrice } from '@/components/shared/UsdPrice/UsdPrice';
import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { useAuthentication } from '@/hooks/useAuthentication';
import { useCoinConversion } from '@/utils/useCoinConversion';
import { Button, Divider, Flex, FlexProps, HStack, Tooltip, useDisclosure } from '@chakra-ui/react';
import { formatTokenAmount } from '@onbeam/utils';
import { Currency } from '@sphere/reservoir-kit-ui';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useMemo } from 'react';
import { Address, erc20Abi, formatUnits } from 'viem';
import { useBalance, useBlockNumber, useReadContracts } from 'wagmi';

const MAX_ITEMS = 4;
const MAX_FRACTION_DIGITS = 2;

type EnhancedCurrency = Currency & {
  usdPrice: number;
  balance: bigint;
};

const WalletBalance = (props: FlexProps) => {
  const { t } = useTranslation('common');
  const marketplaceChain = useMarketplaceChain();
  const { userAddress } = useAuthentication();
  const { isOpen, onToggle } = useDisclosure();

  const currencies = useMemo(
    () => [
      ...(marketplaceChain.defaultCurrencies ?? []),
      ...(marketplaceChain.customCurrencies ?? []),
    ],
    [marketplaceChain],
  );

  // Native currencies are fetched differently from non-native currencies
  const nativeCurrency = currencies.find(c => c.symbol === marketplaceChain.nativeCurrency.symbol);
  const nonNativeCurrencies = currencies.filter(c => c.symbol !== nativeCurrency?.symbol);

  const { data: blockNumber } = useBlockNumber({ watch: !!isOpen });
  const { data: nonNativeBalances, refetch: refetchBalances } = useReadContracts({
    contracts: nonNativeCurrencies.map(currency => ({
      abi: erc20Abi,
      address: currency.contract as Address,
      chainId: marketplaceChain.id,
      functionName: 'balanceOf',
      args: [userAddress as Address],
    })),
    query: {
      enabled: userAddress ? true : false,
    },
    allowFailure: false,
  });

  useEffect(() => {
    refetchBalances();
  }, [blockNumber, refetchBalances]);

  const { data: nativeTokenBalance } = useBalance({
    address: userAddress as Address,
    chainId: marketplaceChain.id,
  });

  const usdConversions = useCoinConversion(
    'USD',
    currencies.map(c => c.symbol).join(','),
    currencies.map(c => c.coinGeckoId).join(','),
  );

  const enhancedCurrencies = useMemo<EnhancedCurrency[]>(
    () =>
      currencies
        .map(currency => {
          let balance = 0n;
          if (currency.symbol === nativeCurrency?.symbol) {
            balance = nativeTokenBalance?.value || 0n;
          } else {
            const index = nonNativeCurrencies.findIndex(c => c.symbol === currency.symbol);
            balance = (nonNativeBalances as bigint[])?.[index] || 0n;
          }

          const usdConversion = usdConversions.find(c => c.symbol === currency.symbol)?.price || 0;
          const usdPrice =
            Number(formatUnits(BigInt(balance), currency?.decimals || 18)) * usdConversion;

          return {
            ...currency,
            usdPrice,
            balance,
          };
        })
        /* Filter out currencies with zero balance to prevent users having a bloated list with unknown currencies. */
        .filter(c => c.balance > 0n),
    [
      currencies,
      nativeCurrency,
      nonNativeBalances,
      nativeTokenBalance,
      usdConversions,
      nonNativeCurrencies,
    ],
  );

  if (!enhancedCurrencies.length) return null;

  return (
    <>
      <Divider />
      <Flex flexDir="column" gap="space.12" {...props}>
        <Flex flexDir="column" gap="space.12">
          {enhancedCurrencies.map((currency, i) => {
            if (!isOpen && i >= MAX_ITEMS) return null;

            return (
              <HStack key={currency.symbol} gap="space.8" alignItems="center">
                <CryptoCurrencyIcon
                  css={{ height: 20 }}
                  address={currency.contract}
                  symbol={currency.symbol}
                />
                <Flex gap="space.8" align="center">
                  <Tooltip
                    label={formatTokenAmount(currency.balance, {
                      symbol: currency.symbol,
                      notation: 'standard',
                      maximumFractionDigits: 4,
                      decimals: currency.decimals,
                    })}
                    placement="top"
                    hasArrow
                    arrowSize={8}
                  >
                    <FormatCrypto
                      amount={currency.balance}
                      maximumFractionDigits={MAX_FRACTION_DIGITS}
                      decimals={currency.decimals}
                      fontSize="sm"
                      flexDir="row-reverse"
                      justify="start"
                    >
                      {currency.symbol}
                    </FormatCrypto>
                  </Tooltip>
                  <UsdPrice fontSize="xs" color="gray.5" usdValue={currency.usdPrice} />
                </Flex>
              </HStack>
            );
          })}
        </Flex>
        {enhancedCurrencies.length > MAX_ITEMS && (
          <Button variant="link" fontSize="sm" justifyContent="start" onClick={onToggle}>
            {isOpen ? t('header.nav.balances.show-less') : t('header.nav.balances.show-more')}
          </Button>
        )}
      </Flex>
    </>
  );
};

export default WalletBalance;
