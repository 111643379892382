import { checkboxAnatomy as parts } from '@chakra-ui/anatomy';
import type { MultiStyleConfig, PartsStyleObject, SystemStyleObject } from '@chakra-ui/react';
import { Checkbox } from '@chakra-ui/react';
import { CheckIcon } from '@sphere/icons';
import { rem } from 'polished';
import React from 'react';

const control: SystemStyleObject = {
  borderWidth: '1px',
  borderRadius: 4,
  borderColor: 'border',
  color: 'transparent',
  bg: 'gray.2',
  transition: 'all .2s ease',
  _hover: {
    borderColor: 'gray.5',
  },
  _groupHover: {
    borderColor: 'gray.5',
  },
  _checked: {
    bg: 'white',
    p: '3px',
    borderColor: 'primary',
    color: 'darkPurple',
  },
};

const checkboxSize = {
  control: { w: rem(24), h: rem(24) },
  icon: { fontSize: '2xs' },
};

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  sm: {
    ...checkboxSize,
    label: { fontSize: 'sm' },
  },
  md: {
    ...checkboxSize,
    label: { fontSize: 'md' },
  },
};

export const checkbox: Omit<MultiStyleConfig, 'parts'> = {
  baseStyle: {
    control,
  },
  variants: {
    fullWidth: {
      label: {
        w: 'full',
      },
    },
  },
  sizes,
};

Checkbox.defaultProps = {
  icon: <CheckboxIcon as={CheckIcon} />,
};

/**
 * destructure props we don't want to pass to prevent React warnings about unrecognized props
 * https://github.com/chakra-ui/chakra-ui/issues/7044
 */
function CheckboxIcon({
  isChecked: _isChecked,
  isIndeterminate: _isIndeterminate,
  as: Element,
  ...rest
}: {
  isChecked?: boolean;
  isIndeterminate?: boolean;
  as: React.ElementType;
}) {
  return <Element boxSize={rem(14)} {...rest} />;
}
