import { Box, HStack, type BoxProps } from '@chakra-ui/react';
import NextLink from 'next/link';
import { rem } from 'polished';
import { useEffect, useRef, type KeyboardEvent, type ReactNode } from 'react';
import { useFocus } from '../useFocus';

type HitProps = BoxProps & {
  href: string;
  focusIndex: number;
  leftRender?: ReactNode;
  rightRender?: ReactNode;
};

export const SearchHitBase = ({
  href,
  focusIndex,
  leftRender,
  rightRender,
  ...props
}: HitProps) => {
  const { focusedIndex, setFocusedIndex } = useFocus();
  const ref = useRef<HTMLDivElement & HTMLAnchorElement>(null);

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement & HTMLAnchorElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setFocusedIndex(prevState => prevState + 1);
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      setFocusedIndex(prevState => prevState - 1);
    }
  };

  useEffect(() => {
    if (focusedIndex !== focusIndex || !ref?.current) return;
    ref.current.focus();
  }, [ref, focusedIndex, focusIndex]);

  return (
    <NextLink href={href} passHref legacyBehavior>
      <Box
        as="a"
        ref={ref}
        onKeyDown={handleKeyDown}
        data-testid="autocomplete-hit"
        p="space.8"
        rounded="radius.12"
        _hover={{ bg: 'gray.3' }}
        _focus={{ outline: 'none', bg: 'gray.3' }}
        _focusVisible={{ boxShadow: 'outline' }}
        {...props}
      >
        <article>
          <HStack spacing="space.16">
            <Box width={rem(56)} height={rem(56)}>
              {leftRender}
            </Box>
            <Box flex={1}>{rightRender}</Box>
          </HStack>
        </article>
      </Box>
    </NextLink>
  );
};
