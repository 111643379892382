import { Divider, Flex } from '@chakra-ui/react';
import { checkout } from '@imtbl/sdk';
import { BridgeIcon, FolderIcon, PlusIcon, SwapIcon } from '@sphere/icons';
import useTranslation from 'next-translate/useTranslation';
import { rem } from 'polished';
import { IMXCheckoutAction } from './IMXCheckoutAction';

export const IMXCheckoutActions = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <Divider />
      <Flex w="full" justify="space-between" gap="space.8">
        <IMXCheckoutAction
          widgetType={checkout.WidgetType.WALLET}
          label={t('imx-actions.wallet.label')}
          icon={<FolderIcon boxSize={rem(18)} />}
        />
        <IMXCheckoutAction
          widgetType={checkout.WidgetType.SWAP}
          label={t('imx-actions.swap.label')}
          icon={<SwapIcon boxSize={rem(18)} />}
        />
        <IMXCheckoutAction
          widgetType={checkout.WidgetType.BRIDGE}
          label={t('imx-actions.bridge.label')}
          icon={<BridgeIcon boxSize={rem(18)} />}
        />
        <IMXCheckoutAction
          widgetType={checkout.WidgetType.ONRAMP}
          label={t('imx-actions.onramp.label')}
          icon={<PlusIcon boxSize={rem(18)} />}
        />
      </Flex>
    </>
  );
};
