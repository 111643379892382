import { api } from '@/utils/api';
import supportedChains from '@/utils/chains';
import { Box, BoxProps, Divider, Flex, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { HighlightType } from '@prisma/client';
import { Fragment } from 'react';
import { useDevCompanion } from '../../DevCompanion.store';
import HighlightedCollectionTypeForm from './HighlightedCollectionTypeForm';

type Props = BoxProps;

const HighlightedCollections = ({ ...props }: Props) => {
  const { data, refetch } = api.collectionHighlight.getAll.useQuery();
  const chainId = useDevCompanion(state => state.chainId);
  const currentChainIndex = supportedChains.findIndex(c => c.id === chainId);

  return (
    <Box {...props}>
      <Tabs index={currentChainIndex} mt={0}>
        <TabPanels>
          {supportedChains.map(chain => (
            <TabPanel p={0} key={chain.id}>
              <Flex direction="column" gap="space.8">
                {data &&
                  Object.values(HighlightType).map((type, i) => (
                    <Fragment key={type}>
                      <HighlightedCollectionTypeForm
                        type={type}
                        chainId={chain.id}
                        onMutate={refetch}
                        data={data.filter(
                          highlight =>
                            highlight.chainId === chain.id && highlight.types.includes(type),
                        )}
                      />
                      {i !== Object.values(HighlightType).length - 1 && <Divider />}
                    </Fragment>
                  ))}
              </Flex>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default HighlightedCollections;
