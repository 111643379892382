import { ComponentStyleConfig } from '@chakra-ui/react';
import { rem } from 'polished';

const variants = {
  boxed: {
    root: {
      _expanded: {
        bg: 'gray.1',
      },
    },
    container: {
      border: 0,
    },
    button: {
      borderRadius: 'lg',
      borderWidth: '1px',
      borderColor: 'gray.2',
      px: rem(24),
      py: rem(16),

      _expanded: {
        borderColor: 'gray.1',
        borderBottomRadius: 0,
        bg: 'gray.1',
        // Apply the same bg to the panel, which does not have an `_expanded` modifier
        '~ *': {
          bg: 'gray.1',
        },
      },
    },
    panel: {
      px: rem(24),
      // Added so padding matches the button, which initially has a border
      border: '1px solid transparent',
      borderBottomRadius: 'lg',
    },
    icon: {
      fontSize: rem(24),
    },
  },
};

export const accordion: ComponentStyleConfig = {
  variants,
};
