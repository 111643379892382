import { ComponentStyleConfig } from '@chakra-ui/react';

export const list: ComponentStyleConfig = {
  baseStyle: {
    item: {
      listStyleType: 'none',
    },
  },
  variants: {
    bulleted: {
      item: {
        paddingLeft: 1,
        listStyleType: 'square',
        listStylePosition: 'inside',
      },
    },
  },
};
