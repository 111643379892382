import { Box, BoxProps } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { rem } from 'polished';

type Props = BoxProps;

export const TestnetLabel = ({ ...boxProps }: Props) => {
  const { t } = useTranslation('common');

  return (
    <Box
      as="span"
      bg="blackBg"
      display="inline-block"
      color="white"
      fontSize="2xs"
      fontWeight="medium"
      px={rem(4)}
      py={rem(2)}
      minH={0}
      h={rem(17)}
      rounded="radius.2"
      position="absolute"
      zIndex="header.behind"
      {...boxProps}
    >
      {t('testnets')}
    </Box>
  );
};
