import {
  GetUsersUserTokensV7Params,
  GetUsersUserTokensV7Response,
  getGetUsersUserTokensV7InfiniteQueryOptions,
  useGetUsersUserTokensV7Infinite,
} from '@/lib/reservoir/reservoir.generated';
import { ReservoirChain } from '@/utils/chains';
import { isDefined } from '@onbeam/utils';
import { useMarketplaceChain } from './use-marketplace-chain';

export type UserToken = NonNullable<GetUsersUserTokensV7Response['tokens']>[number];

const params: GetUsersUserTokensV7Params = {
  limit: 20,
  sortBy: 'acquiredAt',
  includeTopBid: true,
  includeRawData: true,
  includeAttributes: true,
  excludeSpam: true,
};

const getUserTokens = (
  chain: ReservoirChain,
  address: string,
  override?: GetUsersUserTokensV7Params,
) => {
  return getGetUsersUserTokensV7InfiniteQueryOptions(
    address,
    { ...params, ...override },
    {
      query: {
        enabled: !!address,
        queryKey: [`/users/${address}/tokens/v7`, chain.id, address, params, override],
        getNextPageParam: (lastPage: GetUsersUserTokensV7Response) => lastPage.continuation,
      },
      request: {
        baseURL: `${process.env.NEXT_PUBLIC_HOST_URL}${chain.proxyApi}`,
      },
    },
  );
};

const useUserTokens = (address: string, override?: GetUsersUserTokensV7Params) => {
  const chain = useMarketplaceChain();

  const { data, ...request } = useGetUsersUserTokensV7Infinite(
    address,
    { ...params, ...override },
    {
      query: {
        enabled: !!address,
        queryKey: [`/users/${address}/tokens/v7`, chain.id, address, params, override],
        getNextPageParam: (lastPage: GetUsersUserTokensV7Response) =>
          lastPage.continuation || undefined,
      },
      request: {
        baseURL: `${process.env.NEXT_PUBLIC_HOST_URL}${chain.proxyApi}`,
      },
    },
  );

  return {
    data: (data?.pages || []).flatMap(page => page.tokens).filter(isDefined),
    ...request,
  };
};

export { getUserTokens, useUserTokens };
