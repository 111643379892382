import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  forwardRef,
  type InputProps,
} from '@chakra-ui/react';
import { CloseNormalIcon, SearchIcon } from '@sphere/icons';
import useTranslation from 'next-translate/useTranslation';
import { rem } from 'polished';
import { MutableRefObject, useState, type ChangeEvent } from 'react';
import SearchShortcut from './SearchShortcut';

export type SearchBarProps = Omit<InputProps, 'onChange' | 'onReset'> & {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onReset?: () => void;
  disabled?: boolean;
  testId?: string;
  withShortcut?: boolean;
};

const SearchBar = forwardRef<SearchBarProps, 'div'>(
  (
    {
      testId = 'search-bar',
      value,
      placeholder,
      onChange,
      onReset,
      withShortcut,
      variant,
      ...rest
    },
    ref,
  ) => {
    const { t } = useTranslation('common');
    const [isFocussed, setFocussed] = useState<boolean>(false);

    return (
      <InputGroup data-testid={`${testId}-input-group`} minWidth={rem(140)}>
        <InputLeftElement
          height={rem(52)}
          width={rem(56)}
          pointerEvents="none"
          color={isFocussed ? undefined : 'gray.5'}
          transition="all 120ms ease-in-out"
        >
          <SearchIcon boxSize={rem(20)} />
        </InputLeftElement>
        <Input
          data-testid={`${testId}-input`}
          ref={ref}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          placeholder={placeholder}
          spellCheck={false}
          maxLength={512}
          size="lg"
          name="autocomplete"
          type="search"
          value={value}
          onFocus={() => setFocussed(true)}
          onBlur={() => setFocussed(false)}
          onChange={onChange}
          transition="all 120ms ease-in-out"
          role="group"
          paddingLeft={rem(52)}
          paddingRight={value && onReset ? 'space.48' : 'space.24'}
          sx={{
            '::-webkit-search-cancel-button': {
              display: 'none',
            },
          }}
          variant={variant}
          {...rest}
        />
        <InputRightElement
          height={rem(52)}
          width={rem(56)}
          pointerEvents={withShortcut && !value ? 'none' : undefined}
          justifyContent="flex-end"
        >
          {value
            ? onReset && (
                <IconButton
                  variant="clear"
                  data-testid={`${testId}-clear-button`}
                  background="none"
                  type="button"
                  width="100%"
                  height="100%"
                  onClick={onReset}
                  aria-label={t('search-bar.input.clear-aria-label')}
                  icon={<CloseNormalIcon boxSize={rem(12)} />}
                  isRound
                />
              )
            : withShortcut && (
                <SearchShortcut
                  variant={variant}
                  onTrigger={() => (ref as MutableRefObject<HTMLInputElement>)?.current?.focus()}
                />
              )}
        </InputRightElement>
      </InputGroup>
    );
  },
);

export default SearchBar;
