import { create } from 'zustand';
import { WidgetType } from './utils';

type Store = {
  orchestratedWidgetType?: WidgetType;
  setOrchestratedWidgetType(type?: WidgetType): void;
};

export const useOrchestratedWidget = create<Store>(set => ({
  orchestratedWidgetType: undefined,
  setOrchestratedWidgetType: type => set({ orchestratedWidgetType: type }),
}));
