import { Box, BoxProps, forwardRef } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

type Props = BoxProps & {
  variant?: 'translucent' | 'opaque';
};

export const ContextMenu = forwardRef(
  ({ variant, children, ...boxProps }: PropsWithChildren<Props>, ref) => (
    <Box
      ref={ref}
      px="space.32"
      py="space.24"
      backdropFilter={variant === 'translucent' ? 'blur(12px)' : undefined}
      borderRadius="radius.8"
      boxShadow={variant !== 'translucent' ? 'header' : undefined}
      transformOrigin="100% 10%"
      {...boxProps}
    >
      {children}
    </Box>
  ),
);
