import { chakra } from '@chakra-ui/react';

const SVGIcon = ({ ...props }) => (
  <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H26C28.2091 0 30 1.79086 30 4V7.5H0V4Z"
      fill="url(#paint0_linear_1621_3727)"
    ></path>
    <path
      d="M0 22.5H30V26C30 28.2091 28.2091 30 26 30H4C1.79086 30 0 28.2091 0 26V22.5Z"
      fill="url(#paint1_linear_1621_3727)"
    ></path>
    <path d="M0 7.5H30V15H0V7.5Z" fill="url(#paint2_linear_1621_3727)"></path>
    <path d="M0 7.5H30V15H0V7.5Z" fill="url(#paint3_linear_1621_3727)"></path>
    <path d="M0 15H30V22.5H0V15Z" fill="url(#paint4_linear_1621_3727)"></path>
    <defs>
      <linearGradient
        id="paint0_linear_1621_3727"
        x1="15.1077"
        y1="3.9375"
        x2="15.1065"
        y2="12.3047"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BBDBFF"></stop>
        <stop offset="0.328125" stop-color="#139EDD"></stop>
        <stop offset="0.598958" stop-color="#B9F7EA"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear_1621_3727"
        x1="13.7101"
        y1="26.4375"
        x2="13.709"
        y2="34.8047"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BBFFCA"></stop>
        <stop offset="0.328125" stop-color="#48DD13"></stop>
        <stop offset="0.598958" stop-color="#008805"></stop>
      </linearGradient>
      <linearGradient
        id="paint2_linear_1621_3727"
        x1="13.6837"
        y1="8.42591"
        x2="13.6828"
        y2="15.301"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF6A9A"></stop>
        <stop offset="0.529819" stop-color="#FF5544"></stop>
        <stop offset="0.955545" stop-color="#E63E33"></stop>
      </linearGradient>
      <linearGradient
        id="paint3_linear_1621_3727"
        x1="13.3728"
        y1="11.4375"
        x2="13.3718"
        y2="19.8047"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF6B6B"></stop>
        <stop offset="0.328125" stop-color="#FE1414"></stop>
        <stop offset="0.598958" stop-color="#8E0900"></stop>
      </linearGradient>
      <linearGradient
        id="paint4_linear_1621_3727"
        x1="13.8986"
        y1="18.9375"
        x2="13.8975"
        y2="27.3047"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F1E869"></stop>
        <stop offset="0.328125" stop-color="#FEA514"></stop>
        <stop offset="0.598958" stop-color="#FF4539"></stop>
      </linearGradient>
    </defs>
  </svg>
);

const Beam = chakra(SVGIcon);
export default Beam;
