import { ComponentStyleConfig } from '@chakra-ui/react';

import { resetClickFocusStyles } from '../../tools/click-focus';

import { clear } from './clear';
import { ctaSecondary } from './ctaSecondary';
import { facet } from './facet';
import { icon, iconPrimary, iconSecondary } from './icon';
import { light } from './light';
import { link } from './link';
import { outline } from './outline';
import { primary } from './primary';
import { secondary } from './secondary';
import { secondaryGray } from './secondaryGray';
import { sizes } from './sizes';

export const button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 'none',
    fontWeight: 'regular',
    ...resetClickFocusStyles,

    _loading: {
      '&, &:disabled, &:disabled:hover': {
        opacity: 1,
        cursor: 'progress',
      },
    },
  },
  defaultProps: {
    display: 'block',
    variant: 'primary',
    size: 'md',
  },
  sizes,
  variants: {
    primary,
    secondary,
    light,
    'cta-secondary': ctaSecondary,
    icon,
    'icon-secondary': iconSecondary,
    'icon-primary': iconPrimary,
    clear,
    link,
    'secondary-gray': secondaryGray,
    facet,
    outline,
  },
};
