import { ProfileIcon } from '@/components/features/layout/Header/Actions/UserSessionButton/ProfileIcon';
import { Flex, Text } from '@chakra-ui/react';
import { NavLink, type NavLinkProps } from '@sphere/ui';
import { rem } from 'polished';
import { useMeasure } from 'react-use';

type Props = NavLinkProps & {
  displayName: string;
};

export const UserIconLink = ({ displayName, ...props }: Props) => {
  const [element, { width }] = useMeasure<HTMLDivElement>();

  return (
    <NavLink
      {...props}
      useActiveClassName={false}
      sx={{
        '&:after': {
          display: 'none',
        },
        '& figcaption': {
          '&:after': {
            content: {
              md: "''",
            },
            display: 'block',
            width: '0%',
            height: rem(2),
            borderRadius: rem(2),
            position: 'absolute',
            background: 'gray.5',
            transition: 'ease-in-out .3s',
            opacity: 0,
            top: 'space.28',
          },
        },
        '&:hover figcaption::after': {
          background: 'gray.5',
          opacity: 1,
          width: rem(width),
        },
        '&:active figcaption': {
          color: 'gray.4',
        },
        '&:active figcaption::after': {
          background: 'gray.4',
          opacity: 1,
          width: rem(width),
        },
        '&:hover img': {
          opacity: 0.4,
        },
      }}
    >
      <Flex as="figure" align="center">
        <ProfileIcon />
        <Text
          ref={element}
          as="figcaption"
          noOfLines={1}
          maxWidth={{ base: '70vw', md: rem(200) }}
          fontWeight="medium"
          fontSize="md"
          ml="space.8"
        >
          {displayName}
        </Text>
      </Flex>
    </NavLink>
  );
};
