import { ERC721_ABI } from '@/constants/abis/ERC721';
import { useAuthentication } from '@/hooks/useAuthentication';
import {
  Button,
  Flex,
  FlexProps,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { CheckIcon, CloseNormalIcon } from '@sphere/icons';
import useTranslation from 'next-translate/useTranslation';
import { rem } from 'polished';
import { useState } from 'react';
import { Address } from 'viem';
import { useReadContract } from 'wagmi';
import { useDevCompanion } from '../../DevCompanion.store';
import { getSeaportAddress } from './seaportAddresses';

type Props = FlexProps;

export const CheckApproval = ({ ...props }: Props) => {
  const { t } = useTranslation('companion');
  const [contractAddress, setContractAddress] = useState<string>();
  const { userAddress } = useAuthentication();
  const chainId = useDevCompanion(state => state.chainId);

  const { data, refetch, isLoading } = useReadContract({
    address: contractAddress as Address,
    abi: ERC721_ABI,
    functionName: 'isApprovedForAll',
    args: [userAddress, getSeaportAddress(chainId)],
    chainId,
  });

  return (
    <Flex direction="column" align="flex-start" {...props}>
      <Heading fontSize="md" fontWeight="bold">
        {t('check-approval.title')}
      </Heading>
      <Text fontSize="sm" color="muted" mt="space.4">
        {'Check if the current connected wallet is approved for all on a contract'}
      </Text>
      <Flex gap="space.8" my="space.8" w="full">
        <InputGroup>
          <Input
            value={contractAddress}
            onChange={e => setContractAddress(e.target.value)}
            placeholder={t('check-approval.contract-address-placeholder')}
            size="sm"
          />
          <InputRightElement pb="space.8">
            {data === undefined ? null : data ? (
              <CheckIcon boxSize={rem(14)} color="green" />
            ) : (
              <CloseNormalIcon boxSize={rem(14)} color="red" />
            )}
          </InputRightElement>
        </InputGroup>
        <Button
          onClick={() => refetch()}
          variant="clear"
          fontWeight="bold"
          size="sm"
          isLoading={isLoading}
        >
          {t('check-approval.refetch')}
        </Button>
      </Flex>
    </Flex>
  );
};
