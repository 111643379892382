import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { useAuthentication } from '@/hooks/useAuthentication';
import { routes } from '@/utils/routes';
import { Box, Container, Flex, Link, VisuallyHidden, useBreakpointValue } from '@chakra-ui/react';
import { LogoBetaIcon, LogoCompactIcon } from '@sphere/icons';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import NextLink from 'next/link';
import { rem } from 'polished';
import { CartButton } from './Actions/CartButton/CartButton';
import { HamburgerButton } from './Actions/HamburgerButton';
import SwitchChainButton from './Actions/SwitchChainButton/SwitchChainButton';
import { TestnetLabel } from './Components/TestnetLabel';
import GlobalSearch from './GlobalSearch/GlobalSearch';
import { MainNavigation } from './Menu/MainNavigation';
import { MobileMenu } from './Menu/MobileMenu';

const UserButton = dynamic(
  () => import('./Actions/UserSessionButton/UserButton').then(mod => mod.UserButton),
  {
    ssr: false,
  },
);

const HEIGHT = 88; // px

type Props = {
  hasBackground?: boolean;
};

export const Header = ({ hasBackground }: Props) => {
  const { t } = useTranslation('common');
  const { userAddress, isAuthenticated } = useAuthentication();
  const marketplaceChain = useMarketplaceChain();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false });

  return (
    <Box
      as="header"
      height={rem(HEIGHT)}
      zIndex="sticky-layout"
      bg={hasBackground ? 'blackBg' : 'transparent'}
      transition="all 160ms ease-in-out"
    >
      <Container
        h={rem(HEIGHT - 8)}
        variant="breakout"
        display="flex"
        position="relative"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        px={{ base: 'space.24', md: 'space.12', lg: 'space.32', '2xl': 'space.48' }}
      >
        <Flex
          maxWidth={rem(600)}
          marginRight="auto"
          paddingRight={{ base: 'space.4', md: 'space.16', lg: 'space.24' }}
          transition={{ md: 'padding-right 333ms' }}
          height="inherit"
          alignItems="inherit"
          flex="1"
          justifyContent="space-between"
        >
          <NextLink href={routes.home(marketplaceChain.routePrefix)} passHref legacyBehavior>
            <Link data-testid="nav-home-button" position="relative">
              <VisuallyHidden>{t('header.home')}</VisuallyHidden>
              {isMobile ?? isTablet ? (
                <>
                  <LogoCompactIcon w={rem(34.7)} h="auto" aria-hidden="true" />
                  {marketplaceChain.testnet && (
                    <TestnetLabel left="50%" transform="translateX(-50%)" bottom="-space.8" />
                  )}
                </>
              ) : marketplaceChain.testnet ? (
                <>
                  <LogoBetaIcon w={rem(115)} h="auto" aria-hidden="true" />
                  <TestnetLabel bottom="-space.6" right="-space.4" />
                </>
              ) : (
                <LogoBetaIcon w={rem(115)} h="auto" aria-hidden="true" />
              )}
            </Link>
          </NextLink>
          <Box ml={{ md: 'space.12', lg: 'space.32' }} flex={{ md: '1' }}>
            <GlobalSearch
              zIndex="header.behind"
              variant={hasBackground ? 'opaque' : 'translucent'}
            />
          </Box>
        </Flex>
        {!isMobile && (
          <MainNavigation
            display="flex"
            zIndex="header.inFront"
            linkProps={{
              zIndex: 'header.inFront',
              activeLinkColor: 'white',
            }}
            mr={userAddress ? 'space.8' : 'space.16'}
          />
        )}
        <Flex mr="space.8" zIndex="cart">
          <SwitchChainButton />
          {isAuthenticated && <CartButton />}
        </Flex>

        <UserButton />

        {isMobile && (
          <>
            <HamburgerButton color="white" ml="space.8" zIndex="header.inFront" />
            <MobileMenu zIndex="header.compactMenu" />
          </>
        )}
      </Container>
      <Box opacity={hasBackground ? 1 : 0} transition="opacity 160ms ease-in-out">
        <Box
          h={rem(2)}
          w="100%"
          bg="linear-gradient(180deg, #BBDBFF 52%, #139EDD 89%, #B9F7EA 120%)"
        />
        <Box
          h={rem(2)}
          w="100%"
          bg="linear-gradient(180deg, #FF6B6B 52%, #FE1414 89%, #8E0900 120%)"
        />
        <Box
          h={rem(2)}
          w="100%"
          bg="linear-gradient(180deg, #F1E869 52%, #FEA514 89%, #FF4539 120%)"
        />
        <Box
          h={rem(2)}
          w="100%"
          bg="linear-gradient(180deg, #BBFFCA 52%, #48DD13 89%, #008805 120%)"
        />
      </Box>
    </Box>
  );
};

Header.height = HEIGHT;
