import { primary } from './primary';

export const outline = {
  ...primary,

  border: '1px solid',
  borderColor: 'gray.2',
  backgroundColor: 'transparent',
  textColor: 'white',

  _hover: {
    ...primary._hover,
    opacity: 0.8,
    backgroundColor: 'transparent',
  },

  _active: {
    ...primary._active,
    opacity: 0.8,
    backgroundColor: 'transparent',
  },
};
