import { ComponentStyleConfig } from '@chakra-ui/react';

export const form: ComponentStyleConfig = {
  baseStyle: {
    requiredIndicator: {
      // Required indicator should have the same color as the label
      color: 'inherit',
    },
  },
};
