import { primary } from './primary';

export const secondary = {
  ...primary,

  backgroundColor: 'gray.2',
  textColor: 'gray.1',
  borderColor: 'border',

  _hover: {
    ...primary._hover,
    backgroundColor: 'gray.4',
  },

  _active: {
    ...primary._active,
    backgroundColor: 'gray.2',
  },

  _loading: {
    '&, &:disabled, &:disabled:hover': {
      color: 'white',
      backgroundColor: 'gray.4',
    },
  },
};
