import { formatSelectedFilters, useActivityFilters } from '@/components/features/Activity/utils';
import { useListings } from '@/hooks/use-listings';
import { useTokenActivity } from '@/hooks/use-token-activity';
import { useUserListings } from '@/hooks/use-user-listings';
import { useUserTokens } from '@/hooks/use-user-tokens';
import { useBids, useDynamicTokens } from '@sphere/reservoir-kit-ui';
import { useEffect } from 'react';
import { create } from 'zustand';

type Store = {
  isRefreshing: boolean;
  refresh: () => void;
  onRefresh?: () => Promise<unknown>;
};

export const useNFTRefreshStore = create<Store>((set, get) => ({
  isRefreshing: false,
  refresh: async () => {
    const { isRefreshing, onRefresh } = get();
    if (isRefreshing) return;
    set({ isRefreshing: true });
    await onRefresh?.();
    set({ isRefreshing: false });
  },
  onRefresh: undefined,
}));

type Props = {
  contractAddress: string;
  tokenId: string;
  userAddress?: string;
  is1155?: boolean;
};

export const useNFTRefresh = ({ contractAddress, tokenId, userAddress, is1155 }: Props) => {
  const { selectedFilters } = useActivityFilters();

  const { mutate: refetchDynamicTokens } = useDynamicTokens({
    tokens: [`${contractAddress}:${tokenId}`],
    includeAttributes: true,
    includeTopBid: true,
    includeQuantity: true,
  });

  const { refetch: refetchUserTokens } = useUserTokens(is1155 && userAddress ? userAddress : '', {
    tokens: [`${contractAddress}:${tokenId}`],
  });

  const { refetch: refetchTokenActivity } = useTokenActivity(
    tokenId,
    contractAddress,
    ...(selectedFilters.length ? [{ types: formatSelectedFilters(selectedFilters) }] : []),
  );

  const { refetch: refetchListings } = useListings({
    token: `${contractAddress}:${tokenId}`,
    includeRawData: true,
    sortBy: 'price',
    limit: 20,
  });

  const { mutate: refetchBids } = useBids({
    token: `${contractAddress}:${tokenId}`,
    includeRawData: true,
    sortBy: 'price',
    limit: 20,
  });

  const { refetch: refetchUserListings } = useUserListings(userAddress ?? '', {
    token: `${contractAddress}:${tokenId}`,
    limit: 1,
  });

  useEffect(() => {
    useNFTRefreshStore.setState({
      onRefresh: () =>
        Promise.all([
          refetchDynamicTokens(),
          refetchTokenActivity(),
          refetchListings(),
          refetchBids(),
          refetchUserListings(),
          ...(is1155 ? [refetchUserTokens()] : []),
        ]),
    });

    return () => useNFTRefreshStore.setState({ onRefresh: undefined });
  }, [
    is1155,
    refetchBids,
    refetchDynamicTokens,
    refetchListings,
    refetchTokenActivity,
    refetchUserListings,
    refetchUserTokens,
  ]);
};
