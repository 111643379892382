import { ImageFallback } from '@/components/shared/Image/ImageFallback';
import { Image as ChakraImage, ImageProps as ChakraImageProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

type ImageProps = {
  fallback?: string | ReactElement;
  fill?: boolean;
  priority?: boolean;
} & Omit<ChakraImageProps, 'fallback' | 'fill'>;

// TODO: revert this to the original Chakra Next Image component when the image loading issue has been solved
export const Image = ({ fallback, fill, priority: _priority, sx, ...props }: ImageProps) => (
  <ChakraImage
    {...(fill && { boxSize: '100%' })}
    {...props}
    {...(props.objectFit && { sx: { ...sx, objectFit: `${props.objectFit} !important` } })}
    {...(typeof fallback === 'string'
      ? { fallbackSrc: fallback }
      : {
          fallback: fallback || <ImageFallback {...(fill && { boxSize: '100%' })} {...props} />,
        })}
  />
);
