import { forwardRef, Icon, IconProps } from '@chakra-ui/react';
export default forwardRef<IconProps, 'svg'>((props, svgRef) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={19}
    fill="none"
    viewBox="0 0 20 19"
    ref={svgRef}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10 2.21a7.29 7.29 0 100 14.58 7.29 7.29 0 000-14.58zM.623 9.5a9.377 9.377 0 1118.754 0 9.377 9.377 0 01-18.754 0z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10 8.456c.577 0 1.044.468 1.044 1.044v3.334a1.044 1.044 0 01-2.088 0V9.5c0-.576.468-1.044 1.044-1.044zM8.956 6.167c0-.577.468-1.044 1.044-1.044h.008a1.044 1.044 0 010 2.088H10a1.044 1.044 0 01-1.044-1.044z"
      clipRule="evenodd"
    />
  </Icon>
));
