import { ComponentStyleConfig } from '@chakra-ui/react';

import { errorTextStyles } from '../textStyles/error';

export const formError: ComponentStyleConfig = {
  defaultProps: {
    role: 'alert',
  },
  baseStyle: {
    text: {
      ...errorTextStyles?.error,
    },
  },
};
